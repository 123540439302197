import { Component, Input } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { SPKBatteryRentRestService } from "../spk-battery-rent-rest.service";
import Swal from "sweetalert2";
import { SPKBatteryRentListComponent } from "../list/spk-battery-rent-list.component";
import { CommonService } from "../../../libraries/libraries.module";
import * as moment from 'moment';

@Component({
  selector: 'app-detail-modal',
  templateUrl: 'detail-modal.component.html',
  styleUrls: ['../list/spk-battery-rent-list.component.scss']
})
export class DetailModalComponent {
  @Input() spk: any;
  @Input() showDate: boolean = true;

  isEditingBattery = false;
  formEditBattery: FormGroup;
  loadingEditBattery = false;

  constructor(
    private _SPKBatteryRentRest: SPKBatteryRentRestService,
    public _spkBatteryRentListComponent: SPKBatteryRentListComponent,
    private fb: FormBuilder
  ) {
    this.formEditBattery = this.fb.group({
      batteries: this.fb.array([])
    });
   }

  isLoading: boolean = false;
  formUpdateDetail: FormGroup;
  isDetailModalOpen: boolean = false;
  formEditShippingAddress: FormGroup;
  isOpen: boolean = false;
  isEditingShipingAddress: boolean = false;
  loadingEditShippingAddress: boolean = false;

  initFormEditShippingAddress() {
    this.formEditShippingAddress = new FormGroup({
      shippingAddress: new FormControl('', [Validators.required]),
    }); 
  }

  get batteries(): FormArray {
    return this.formEditBattery.get('batteries') as FormArray;
  }

  populateForm(batteries) {
    this.batteries.clear();
    batteries.forEach(battery => {
      this.batteries.push(
        this.fb.group({
          _id: [battery._id, Validators.required],
          voltageType: [battery.voltageType, Validators.required],
          total: [battery.total, Validators.required],
          description: [battery.description],
          // batteryCode: [battery.batteryCode] // Convert array to string for easier input
        })
      );
    });
  }

  // getBatteryCodes(batteryCode): string {
  //   return batteryCode ? batteryCode?.join(',') || '' : '';
  // }

  toggleRotate() {
    const svgIcon = document.querySelector('.accordion-icon');
    svgIcon?.classList.toggle('rotate');
  }

  onEditBattery() {
    this.isEditingBattery = true;
  }

  onCancelEditBattery() {
    this.isEditingBattery = false;
    this.populateForm(this.spk?.spkBatteryRent); // Reset to original state
  }

  onSaveBattery() {
    if (!this.formEditBattery.valid) {
      CommonService.markAsDirty(this.formEditBattery);
    } else {
      this.loadingEditBattery = true;
      this._SPKBatteryRentRest
        .updateBatteriesRent(this.formEditBattery.value)
        .toPromise()
        .then((response: any) => {
          this._spkBatteryRentListComponent.callHookDirectly('init');
          Swal.fire(
            'Batteries Updated Successfully!',
            '',
            'success'
          )
        })
        .catch(error => {
          const errorData = { ...error };
          Swal.fire(
            'Gagal',
            errorData?.response?.data?.message,
            'error'
          )
        })
        .finally (() => {
          this.loadingEditBattery = false;
          this.onCancelEditBattery();
        })
    }
  }

  openDetailModal() {
    this.isDetailModalOpen = true;
  }

  toDate(date) {
    return moment(date).format('DD MMM YYYY');
  }

  toDateInput(date) {
    return moment(date).format('YYYY-MM-DD')
  }

  closeDetailModal() {
    this.isDetailModalOpen = false;
    // this.formUpdateDetail.reset();
  }

  onUpdateShippingAddress() {
    this.formEditShippingAddress.patchValue({ shippingAddress: this.spk?.shippingAddress });
    this.isEditingShipingAddress = true;
  }

  onCloseUpdateShippingAddress() {
    this.formEditShippingAddress.reset();
    this.isEditingShipingAddress = false;
  }

  editShippingAddress() {
    const payload = {
      spkId: this.spk?._id,
      shippingAddress: this.formEditShippingAddress.value.shippingAddress
    };

    if (!this.formEditShippingAddress.valid) {
      CommonService.markAsDirty(this.formEditShippingAddress);
    } else {
      this.loadingEditShippingAddress = true;
      this._SPKBatteryRentRest
        .updateShippingAddress(payload)
        .toPromise()
        .then((response: any) => {
          this._spkBatteryRentListComponent.callHookDirectly('init');
          Swal.fire(
            'Update Shipping Address Successfully!',
            '',
            'success'
          )
        })
        .catch(error => {
          const errorData = { ...error };
          Swal.fire(
            'Gagal',
            errorData?.response?.data?.message,
            'error'
          )
        })
        .finally (() => {
          this.loadingEditShippingAddress = false;
          this.onCloseUpdateShippingAddress();
        })
    }
  }

  ngOnInit(): void {
    this.initFormEditShippingAddress();
    if (this.spk?.spkBatteryRent) {
      this.populateForm(this.spk.spkBatteryRent);
    }
  }
}