import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { AutoComplete } from 'primeng/autocomplete';

@Injectable()
export class AutocompleteService {
  onLocalACDropdown(ac: AutoComplete, options: any, prependNull: boolean = false, sortQualifier: any = false, filterQualifier: any = false) {
    let suggestions = _.castArray(options);

    if (filterQualifier) {
      suggestions = _.filter(suggestions, filterQualifier);
    }

    if (sortQualifier) {
      suggestions = _.sortBy(suggestions, sortQualifier);
    }

    if (prependNull) {
      suggestions = this.prependNull(suggestions);
    }

    ac.suggestions = suggestions;
  }

  onLocalACSearch(ac: AutoComplete, options: any, searchKeys: string | string[] = [], query: any = '', prependNull: boolean = false, sortQualifier: any = false, filterQualifier: any = false) {
    options = _.castArray(options);
    searchKeys = _.castArray(searchKeys);

    let suggestions = options.filter(value => {
      const regEx = new RegExp(query, 'ig');
      return _.filter(searchKeys, key => {
        const valueString = _.toString(_.get(value, key, ''));
        return regEx.test(valueString);
      }).length;
    });

    if (filterQualifier) {
      suggestions = _.filter(suggestions, filterQualifier);
    }

    if (sortQualifier) {
      suggestions = _.sortBy(suggestions, sortQualifier);
    }

    if (prependNull) {
      suggestions = this.prependNull(suggestions);
    }

    ac.suggestions = suggestions;
  }

  clearValue(ac: AutoComplete) {
    // qucikfix track
    const $el = $(ac.inputEL.nativeElement);
    $el.val('');
    const keydownEvent = $.Event('keydown');
    keydownEvent.which = 8;
    (keydownEvent as any).target = ac.inputEL.nativeElement;
    ac.inputKeyDown = true;
    ac.onInput(keydownEvent as any);
  }

  silentlyClearValue(ac: AutoComplete) {
    const $el = $(ac.inputEL.nativeElement);
    const lastInputValue = $el.val();

    const fakeKeydownEvent = $.Event('keydown');
    fakeKeydownEvent.which = 8;
    (fakeKeydownEvent as any).target = {
      ...ac.inputEL.nativeElement,
      value: null,
    };
    ac.onClear.emit(fakeKeydownEvent);
    ac.filled = null;
    ac.value = null;
    ac.onModelChange(null);

    $el.val(lastInputValue);
  }

  private prependNull(suggestions) {
    return [null].concat(suggestions || []);
  }

  // Function for dropdown but have multiple filter.
  onLocalACDropdownMultiple(ac: AutoComplete, options: any, prependNull: boolean = false, sortQualifier: any = false, filterQualifier: any = false) {
    let suggestions = _.castArray(options);

    if (filterQualifier) {
      // suggestions = _.filter(suggestions, filterQualifier);
      for (let i = 0; i < filterQualifier.length; i++) {
        suggestions = _.filter(suggestions, (value) => {
          return value.accountTypeId === filterQualifier[0] || value.accountTypeId === filterQualifier[1];
        });
      }
    }

    if (sortQualifier) {
      suggestions = _.sortBy(suggestions, sortQualifier);
    }

    if (prependNull) {
      suggestions = this.prependNull(suggestions);
    }

    ac.suggestions = suggestions;
  }

  onLocalACSearchMultiple(ac: AutoComplete, options: any, searchKeys: string | string[] = [], query: any = '', prependNull: boolean = false, sortQualifier: any = false, filterQualifier: any = false) {
    options = _.castArray(options);
    searchKeys = _.castArray(searchKeys);

    let suggestions = options.filter(value => {
      const regEx = new RegExp(query, 'ig');
      return _.filter(searchKeys, key => {
        const valueString = _.toString(_.get(value, key, ''));
        return regEx.test(valueString);
      }).length;
    });

    if (filterQualifier) {
      // suggestions = _.filter(suggestions, filterQualifier);
      for (let i = 0; i < filterQualifier.length; i++) {
        suggestions = _.filter(suggestions, (value) => {
          return value.accountTypeId === filterQualifier[0] || value.accountTypeId === filterQualifier[1];
        });
      }
    }

    if (sortQualifier) {
      suggestions = _.sortBy(suggestions, sortQualifier);
    }

    if (prependNull) {
      suggestions = this.prependNull(suggestions);
    }

    ac.suggestions = suggestions;
  }
}
