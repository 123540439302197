import { AfterContentInit, ChangeDetectorRef, Component, ContentChildren, EventEmitter, Input, Output, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormgenFormComponent } from '../form/formgen-form.component';
import { FormgenTemplateDirective } from '../formgen-template.directive';
import { MFormgenSchema, MFormgenSchemaField } from '../formgen.model';

@Component({
  selector: '[appFormgenFormArray]',
  templateUrl: './formgen-form-array.component.html',
})
export class FormgenFormArrayComponent implements AfterContentInit {
  @Input() name: string;
  @Input('formArray') formArrayFromInput: FormArray;
  @Input('parentFormgenFormComponent') parentFormgenFormComponentInput: FormgenFormComponent;
  @Input() bindModel: any;
  @Output() bindModelChange: EventEmitter<any> = new EventEmitter;
  @ViewChildren(FormgenTemplateDirective) vTemplates: QueryList<FormgenTemplateDirective>;
  @ContentChildren(FormgenTemplateDirective) contentFormgenTemplates: QueryList<FormgenTemplateDirective>;

  lastFormRecognized: FormArray;
  lastFormRecognizedSubscriptionValueChanges: Subscription;

  parentFormgenFormComponentStore: FormgenFormComponent; // to be injected from parent app-formgen-form

  formGroupTemplate: TemplateRef<any>;
  formGroupHeaderTemplate: TemplateRef<any>;
  formGroupFooterTemplate: TemplateRef<any>;
  formArrayHeaderTemplate: TemplateRef<any>;
  formArrayFooterTemplate: TemplateRef<any>;

  get schema(): MFormgenSchema {
    return (this.parentFormgenFormComponentInput && this.parentFormgenFormComponentInput.schema) ||
    (this.parentFormgenFormComponentStore && this.parentFormgenFormComponentStore.schema);
  }

  get targetFormgenSchemaField(): MFormgenSchemaField {
    return this.schema && this.name && this.schema.getFieldByName(this.name);
  }

  get parentFormgenFormComponent(): FormgenFormComponent {
    return this.parentFormgenFormComponentInput || this.parentFormgenFormComponentStore;
  }

  get formArray(): FormArray {
    let targetFormArray: FormArray;
    if (this.formArrayFromInput) {
      targetFormArray = this.formArrayFromInput;
    } else {
      const formgenSchemaField = this.targetFormgenSchemaField;
      if (formgenSchemaField && this.parentFormgenFormComponent) {
        targetFormArray = this.parentFormgenFormComponent.form.get(formgenSchemaField.name) as FormArray;
      }
    }

    if (targetFormArray && this.lastFormRecognized !== targetFormArray) {
      this.lastFormRecognized = targetFormArray;

      if (this.lastFormRecognizedSubscriptionValueChanges) {
        this.lastFormRecognizedSubscriptionValueChanges.unsubscribe();
      }

      this.lastFormRecognizedSubscriptionValueChanges = this.lastFormRecognized.valueChanges.subscribe((newValue) => {
        this.bindModel = newValue;
        this.bindModelChange.emit(newValue);
      });
    }

    return targetFormArray;
  }

  get length() {
    return this.formArray && this.formArray.length || 0;
  }

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngAfterContentInit() {
    this.contentFormgenTemplates.toArray().forEach(contentFormgenTemplate => {
      switch (contentFormgenTemplate.name) {
        case 'formGroupHeader':
          this.formGroupHeaderTemplate = contentFormgenTemplate.template;
          break;
        case 'formGroup':
          this.formGroupTemplate = contentFormgenTemplate.template;
          break;
        case 'formGroupFooter':
          this.formGroupFooterTemplate = contentFormgenTemplate.template;
          break;
        case 'formArrayHeader':
          this.formArrayHeaderTemplate = contentFormgenTemplate.template;
          break;
        case 'formArrayFooter':
          this.formArrayFooterTemplate = contentFormgenTemplate.template;
          break;
      }
    });
  }

  detectChanges() {
    this._changeDetectorRef.detectChanges();
  }

  push(formValue?: any) {
    if (this.name && this.formArray) {
      const newFormGroup = this.schema.mform.formArrayPush(this.name, formValue, this.formArray);
      if (newFormGroup) {
        this._changeDetectorRef.detectChanges();

        return newFormGroup;
      }
    }
  }

  remove(index: number) {
    if (this.name && this.formArray) {
      this.schema.mform.formArrayRemove(this.name, index, this.formArray);

      this._changeDetectorRef.detectChanges();
    }
  }

  reset(index: number) {
    if (this.name && this.formArray) {
      this.schema.mform.formArrayReset(this.name, index, this.formArray);

      this._changeDetectorRef.detectChanges();
    }
  }

  clear() {
    if (this.name && this.formArray) {
      this.schema.mform.formArrayClear(this.name, this.formArray);

      this._changeDetectorRef.detectChanges();
    }
  }
}
