import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-volta-chip',
  templateUrl: 'chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent {
  @Input() type: string = '';
}
