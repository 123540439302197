import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { IdleGuardComponent } from './idle-guard.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  declarations: [
    IdleGuardComponent
  ],
  exports: [
    IdleGuardComponent
  ]
})
export class IdleGuardModule { }

export * from './idle-guard.component';
