import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-fieldset-collapsible-title',
  templateUrl: './fieldset-collapsible-title.component.html',
  styleUrls: ['./fieldset-collapsible.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FieldsetCollapsibleTitleComponent {
  @Input() i18nTitle: string;
  @Input() title: string;
}

@Component({
  selector: 'app-fieldset-collapsible-content',
  templateUrl: './fieldset-collapsible-content.component.html',
})
export class FieldsetCollapsibleContentComponent { }
