import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appAutosize]'
})

export class TextareaAutosizeDirective {
  @HostListener('input', ['$event.target'])
  onInput(textArea: HTMLTextAreaElement): void {
    this.adjust();
  }

  constructor(public _element: ElementRef) { }

  ngAfterContentChecked(): void {
    this.adjust();
  }

  adjust(): void {
    this._element.nativeElement.style.overflow = 'hidden';
    this._element.nativeElement.style.height = 'auto';
    this._element.nativeElement.style.height = this._element.nativeElement.scrollHeight + 'px';
  }
}
