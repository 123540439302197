import * as _ from 'lodash';
import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

import { ImgUploadDisplayComponent } from '../display/img-upload-display.component';

@Component({
  selector: 'app-img-upload-gallery',
  templateUrl: './img-upload-gallery.component.html',
  styleUrls: ['../img-upload.component.scss'],
})
export class ImgUploadGalleryComponent implements OnChanges {
  @Input() pictures: IImgUploadItemServer[] = [];

  @ViewChild('imageDisplay', { static: false }) elImageDisplay: ImgUploadDisplayComponent;

  ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'pictures') && this.pictures && this.pictures.length) {
      this.pictures = _.sortBy(this.pictures, 'sortOrder');
    }
  }

  setMainImage(picture: IImgUploadItemServer) {
    this.elImageDisplay.elImage.nativeElement.src = picture.fileUrl;
  }
}
