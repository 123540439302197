import { Injectable } from '@angular/core';
import { APP_CONST } from '../../app.const';
import { ApiQueryOption } from '../../libraries/http/api-query-option.model';
import { RequestService } from '../../libraries/http/request.service';

@Injectable()
export class NewsRestService {
  baseURL = `${APP_CONST.API_MAIN}/admin/news`;
  NewsCategoryBaseUrl = `${APP_CONST.API_MAIN}/admin/news-categories`;
  baseFileUrl = `${APP_CONST.API_FILE}`;

  request = this._request.new(this.baseURL);
  requestCategories = this._request.new(this.NewsCategoryBaseUrl);
  requestFile = this._request.new(this.baseFileUrl);

  constructor(private _request: RequestService) {}

  create(payload: any) {
    return this.request.post<any>(`/`, payload);
  }

  findAll(queryParams: any = {}) {
    return this.request.get<any>(`/`, { params: queryParams });
  }

  load(userId: string) {
    return this.request.get<any>(`${userId}`);
  }

  delete(newsId: string) {
    return this.request.delete<any>(`${newsId}`);
  }

  update(newsId: string, payload: any) {
    return this.request.put<any>(`${newsId}`, payload);
  }

  findAllCategory() {
    return this.requestCategories.get<any>('/');
  }

  deleteImageById(id) {
    return this.requestFile.delete(id);
  }
}
