
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, of as observableOf } from 'rxjs';
import { filter, finalize, take } from 'rxjs/operators';
import { CentralHookService } from './central-hook.service';
import { MCompHookHookItem } from './comp-hook.model';
import { AppEvent } from './event.service';
import { HookBService } from './hook.bservice';
import { MHookEventFailed, MHookEventSuccess, MHookItem } from './hook.model';

@Injectable({
  providedIn: 'root',
})
export class CompHookService extends HookBService<MCompHookHookItem> {
  constructor(
    private _centralHookService: CentralHookService,
  ) {
    super();
  }

  registerHook(targetHook: MCompHookHookItem) {
    super.registerHook(targetHook);

    if (targetHook.runCentralized) {
      const originalHandle = targetHook.handle;

      targetHook.handle = (event: AppEvent) => this._centralHookService.callHookSafe(targetHook.hookId, event.data);

      this._centralHookService.registerHook({
        ...targetHook,
        handle: originalHandle,
      } as any);
    }
  }

  callHookSafe(hookId: string, hookData?: any): Observable<any> {
    const hook = this.getHook(hookId);
    if (!hook) {
      return observableOf(null);
    }

    let obs = this.callHookHandle(hookId, hookData);

    if (hook.wrapErrorMessage) {
      let wrapErrorMessageConfig = {};
      if (_.isObject(hook.wrapErrorMessage)) {
        wrapErrorMessageConfig = hook.wrapErrorMessage;
      }

      obs = hook.comp().component.observableWrapErrorMessage(obs, wrapErrorMessageConfig);
    }

    if (hook.wrapLoadingComponentIds) {
      obs = hook.comp().component.page.observableWrapLoading(obs, hookId, hook.wrapLoadingComponentIds);
    }

    if (hook.wrapRetryableTask) {
      let wrapRetryableTaskConfig = {};
      if (_.isObject(hook.wrapRetryableTask)) {
        wrapRetryableTaskConfig = hook.wrapRetryableTask;
      }

      obs = hook.comp().createRetriableTask(obs, wrapRetryableTaskConfig);
    }

    if (hook.wrapChangeDetectorRef) {
      obs = obs.pipe(finalize(() => {
        hook.comp()._changeDetectorRef.detectChanges();
      }));
    }

    return obs;
  }

  onHookSuccessByComponentId(componentId: string): Observable<MHookEventSuccess<MHookItem>> {
    return this.hookSuccessEventStream$.pipe(filter(value => {
      return value && value.hook && value.hook.comp().component.componentId === componentId;
    }),take(1),);
  }

  onHookFailedByComponentId(componentId: string): Observable<MHookEventFailed<MHookItem>> {
    return this.hookFailedEventStream$.pipe(filter(value => {
      return value && value.hook && value.hook.comp().component.componentId === componentId;
    }),take(1),);
  }
}
