import { Component } from '@angular/core';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../../libraries/base/list/base-list.bcomponent';
import { ProductRestService } from '../../product-rest.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../../../../libraries/common/common.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-product-variant-list',
  templateUrl: 'product-variant-list.component.html'
})
export class ProductVariantListComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'ui.product.entity.no',
      field: 'no'
    },
    {
      i18nLabel: 'ui.product.entity.nameProduct',
      field: 'name'
    },
    {
      i18nLabel: 'ui.product.entity.variantId',
      field: '_id'
    },
    {
      i18nLabel: 'ui.product.entity.color',
      field: 'nameColor'
    },
    {
      i18nLabel: 'ui.product.entity.colorId',
      field: 'colorCode'
    },
    {
      i18nLabel: 'ui.product.entity.stock',
      field: 'stock'
    },
    {
      i18nLabel: 'ui.product.entity.stockOrdered',
      field: 'stockOrdered'
    },
    {
      i18nLabel: 'ui.product.entity.sequenceColor',
      field: 'sequenceColor'
    }
  ];
  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(this.tableColumns);
  modalCreate: boolean = false;
  modalUpdateAll: boolean = false;
  modalBulkUploadPrice: boolean = false;
  formModalCreate: FormGroup;
  formModalBatchUpdatePrice: FormGroup;
  isFormModalCreateSubmitting: boolean = false;
  breadcrumbs: { label: string; url: string }[];
  prodId: any;
  variantId: any;
  dataUpdate: any;
  nameProduct: any;

  constructor(private _ProductRest: ProductRestService, private route: ActivatedRoute) {
    super();
    this.createFormProduct();
    this.componentId = 'ProductVariantListComponent';
    this.headerTitle = 'ui.product.list.titleVariant';
    this.titlePage = '';
    this.deleteRecordI18nLabel = 'confirm.any.delete.label';
    this.deleteRecordI18nDescription = 'confirm.any.delete.description';
    this.route.params.subscribe(res => {
      this.prodId = res.id;
    });

    this.headerButtons = [
      {
        type: 'custom',
        label: 'ui.product.list.bulkUploadPrice',
        color: 'primary-outline',
        imgIconOnBtn: '../../../../../assets/img/icons/icon-uploaded.svg',
        onClick: () => this.openDialogBulkUploadPrice()
      },
      {
        type: 'custom',
        label: 'ui.product.list.button.updateAllPrice',
        color: 'primary-outline',
        routerLink: `/motorcycle/${this.prodId}/list-variant-and-update-price`
      },
      {
        type: 'custom',
        label: 'ui.product.list.button.createVariant',
        color: 'primary',
        onClick: () => this.openDialogCreate()
      }
    ];
    this.breadcrumbs = [
      {
        label: 'ui.product.list.title',
        url: '/motorcycle'
      }
      // {
      //   label: 'ui.product.list.titleVariant',
      //   url: `/product/${this.prodId}/list-variant`
      // }
    ];
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qOption = event.data.qOption;
        qOption['limit'] = qOption['take'];
        delete qOption['take'];
        delete qOption['skip'];
        const qParams = Object.assign({}, event.data.qParams, qOption, this.qParams);
        return this._ProductRest.findAllVariant(this.comp.routeParams.id, qParams).pipe(
          switchMap(doc => {
            const dataFormatted = doc?.data?.map(d => d).sort((a, b) => a.nameColor.localeCompare(b.nameColor)); // Sort by nameColor property
            doc.data = dataFormatted;
            return of(doc);
          })
        );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'loadDataProduct',
      handle: () => {
        return this._ProductRest.loadProduct(this.comp.routeParams.id).pipe(
          switchMap(doc => {
            return of(doc);
          })
        );
      },
      success: res => {
        this.nameProduct = res.name;
        // Check if titlePage is empty before assigning a new value
        if (!this.titlePage) {
          this.titlePage = this.nameProduct;
        }
        this.formModalCreate.patchValue({
          name: res.name
        });

        const productName = {
          label: res.name,
          url: `/product/${this.prodId}/list-variant`
        };
        // Check if productName already exists in the breadcrumbs array
        const existingProduct = this.breadcrumbs.find(breadcrumb => breadcrumb.label === productName.label);
        if (!existingProduct) {
          this.breadcrumbs.push(productName);
        }
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService?.callMultipleHooks([
          'ProductVariantListComponent:workflowLoadData',
          'ProductVariantListComponent:loadDataProduct'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  addNumber(number) {
    return number + 1;
  }

  openDialogCreate() {
    this.modalCreate = true;
  }

  openDialogUpdate(variantId) {
    this.getVariantData(variantId);
    this.modalCreate = true;
    // this.variantId = variantId;
  }

  openDialogBulkUploadPrice() {
    this.modalBulkUploadPrice = true;
  }

  actionDeleteVariant(value) {
    Swal.fire({
      title: this.comp._translate.instant(this.deleteRecordI18nLabel),
      text: this.comp._translate.instant(this.deleteRecordI18nDescription),
      icon: 'question',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        this.deleteVariantData(value);
      } else {
        return;
      }
    });
  }

  async createVariantData(value) {
    const result = await this._ProductRest
      .createProductVariant(this.comp.routeParams.id, value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant('success.product.createVariant'),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
        }
      })
      .catch(error => {
        this.comp._systemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  async getVariantData(variantId) {
    const result = await this._ProductRest
      .getProductVariant(this.comp.routeParams.id, variantId)
      .toPromise()
      .then(res => {
        if (res) {
          this.variantId = variantId;
          this.formModalCreate.patchValue({
            name: this.nameProduct,
            color: res.color,
            nameColor: res.nameColor,
            colorCode: res.colorCode,
            stock: res.stock,
            sequenceColor: res.sequenceColor
          });
        }
      })
      .catch(error => {
        this.comp._systemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  async updateVariantData(value, variantId) {
    const result = await this._ProductRest
      .updateProductVariant(this.comp.routeParams.id, value, variantId)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant('success.product.updateVariant'),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
        }
      })
      .catch(error => {
        const errorMessage = error.response?.data?.message;
        this.comp._systemMessage.log({
          message: errorMessage,
          type: 'error',
          scroll: true
        });
        Swal.fire('Oops..', errorMessage || 'Something went wrong!', 'error');
      });
  }

  async deleteVariantData(value) {
    const result = await this._ProductRest
      .deleteProductVariant(this.comp.routeParams.id, value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant('success.product.deleteVariant'),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
          this.callHookDirectly('init');
        }
      })
      .catch(error => {
        this.comp._systemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  async uploadBulkPrice() {
    const formData = new FormData();
    formData.append('excel_file', this.formModalBatchUpdatePrice.value.file);
    try {
      const response = await this._ProductRest.bulkUploadPrice(this.comp.routeParams.id, formData).toPromise();
      if (!response) {
        throw new Error('Something went wrong');
      }
      this.comp._globalSystemMessage.log({
        message: this.comp._translate.instant('success.save', {
          name: this.comp._translate.instant('ui.product.list.bulkUploadPrice')
        }),
        type: 'success',
        showAs: 'growl',
        showSnackBar: false,
        scroll: true
      });

      this.isFormModalCreateSubmitting = false;
      this.modalBulkUploadPrice = false;
      this.formModalBatchUpdatePrice.reset();
      this.callHookDirectly('init');
    } catch (error) {
      this.isFormModalCreateSubmitting = false;
      this.comp._globalSystemMessage.log({
        message: this.comp._translate.instant('error.save', {
          name: this.comp._translate.instant('ui.product.list.bulkUploadPrice')
        }),
        type: 'error',
        showAs: 'growl',
        showSnackBar: false,
        scroll: true
      });
    }
  }

  createFormProduct() {
    this.formModalCreate = new FormGroup({
      name: new FormControl('', [Validators.required]),
      color: new FormControl('', [Validators.required]),
      nameColor: new FormControl('', [Validators.required]),
      colorCode: new FormControl('', [Validators.required]),
      stock: new FormControl('', [Validators.required]),
      sequenceColor: new FormControl('', [Validators.required])
    });
    this.formModalBatchUpdatePrice = new FormGroup({
      file: new FormControl('', [Validators.required]),
      fileName: new FormControl('')
    });
  }

  saveModalCreate() {
    const formValue = this.formModalCreate.value;
    if (!this.formModalCreate.valid) {
      CommonService.markAsDirty(this.formModalCreate);
      return;
    }
    if (this.variantId) {
      this.updateVariantData(formValue, this.variantId);
      this.callHookDirectly('init');
    } else {
      this.createVariantData(formValue);
    }
    this.modalCreate = false;
    this.formModalCreate.reset();
    this.callHookDirectly('init');
  }

  saveModalBulkUploadPrice() {
    this.formModalBatchUpdatePrice.markAsDirty();
    if (!this.formModalBatchUpdatePrice.valid) {
      CommonService.markAsDirty(this.formModalBatchUpdatePrice);
      return;
    }
    this.isFormModalCreateSubmitting = true;
    this.uploadBulkPrice();
  }

  closeModalCreate() {
    this.modalCreate = false;
    this.variantId = null;
    this.formModalCreate.reset();
    this.callHookDirectly('loadDataProduct');
  }

  closeModalBulkUploadPrice() {
    this.modalBulkUploadPrice = false;
  }

  onFileChange(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      // const reader = new FileReader();
      const file = target.files[0];
      // const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);

      // reader.onload = () => {
      // this.invoiceImageBase64 = reader.result as string;
      // };

      // reader.readAsDataURL(file);

      this.formModalBatchUpdatePrice.patchValue({
        file: file,
        fileName: file.name
      });

      // this.selectedFileName = file.name;
      // this.fileSize = fileSizeMB + 'MB';
    }
  }
}
