import { Input, OnInit } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { from, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import swal from 'sweetalert2';
import { CommonService } from '../../../libraries/common/common.service';
import { InjectorService } from '../../common/injector.service';
import { MBaseEntryAutoprintButton, MBaseEntryAutoprintCheckbox } from '../base.model';
import { BaseFormBComponent } from '../form/base-form.bcomponent';

export class BaseEntryBComponent extends BaseFormBComponent implements OnInit {
  @Input() autoprintActions: MBaseEntryAutoprintButton[] = [];
  @Input() actionCheckboxes: MBaseEntryAutoprintCheckbox[] = [];
  @Input() saveButtons: string[] = ['save', 'save_and_new', 'save_and_view'];
  public storage = InjectorService.get<LocalStorageService>(LocalStorageService);

  doc: any;

  saveAndRedirectEnabled: boolean = true;
  cancelEnabled: boolean = true;
  cancelURL: string;
  listURL: string;
  saveAndNewEnabled: boolean = false;
  saveAndNewURL: string;
  saveAndViewEnabled: boolean = false;
  saveAndViewURL: string;

  saveAndRedirectType: string;

  entrySuccessI18n: string;
  entrySuccessScrollToTop: boolean = true;
  entryInvalidScrollToTop: boolean = true;

  formValid(): boolean {
    if (!this.form.valid) {
      CommonService.markAsDirty(this.form);

      this.comp._systemMessage.log({
        message: this.comp._translate.instant('error.form'),
        type: 'error',
        scroll: this.entryInvalidScrollToTop,
      });

      return false;
    }
    return true;
  }

  async onSubmit({ saveAndRedirect, saveConfirm, ...customParams }: any = {}) {
    let obs = of(false);

    if (this.formValid()) {
      if (saveConfirm) {
        await swal.fire({
          title: this.comp._translate.instant(`confirm.save.label`),
          text: this.comp._translate.instant(`confirm.save.description`),
          icon: 'warning',
          showCancelButton: true,
        });
      }

      if (this.hasHook('beforeSave')) {
        await this.callHook('beforeSave', { saveAndRedirect, saveConfirm, ...customParams }).toPromise();
      }

      obs = (this.hookSave({ saveAndRedirect, saveConfirm, ...customParams }).pipe(
        switchMap(result => {
          let obsChild = of(result);

          if (this.hasHook('afterSaveBeforeRedirect')) {
            obsChild = obsChild.pipe(switchMap(() => this.callHook('afterSaveBeforeRedirect', result).pipe(catchError(err => of(undefined)))));
          }

          if (saveAndRedirect) {
            this.saveAndRedirectType = saveAndRedirect;
            if (this.saveAndRedirectEnabled) {
              obsChild = obsChild.pipe(switchMap(() => {
                return this.redirectAfterSave(result.id).pipe(switchMap(() => of(result)));
              }));
            }
          }

          return obsChild;
        }))
      ).pipe(tap((result) => {
        if (this.hasHook('afterSaveAndRedirect')) {
          this.callHook('afterSaveAndRedirect', result).subscribe();
        }
      }));
    }

    return await obs.toPromise();
  }

  redirectAfterSave(resultId?: string) {
    if (this.saveAndNewEnabled && this.saveAndRedirectType === 'new') {
      return from(
        this.comp._router.navigateByUrl('/empty', { skipLocationChange: true }).then(() => {
          this.comp._router.navigateByUrl(this.getSaveAndNewURL());
        })
      );
    } else if (this.saveAndViewEnabled && this.saveAndRedirectType === 'view') {
      return from(
        this.comp._router.navigate([this.saveAndViewURL || this.routeURL, resultId])
      );
    } else if (this.saveAndRedirectType === 'list') {
      return from(
        this.comp._router.navigateByUrl(this.listURL || this.routeURL)
      );
    }

    return of(null);
  }

  hookSave(...args) {
    const obs = this.callHook('save', ...args).pipe(
      tap(result => {
        this.doc = result;

        this.comp._globalSystemMessage.log({
          message: this.comp._translate.instant(this.entrySuccessI18n),
          type: 'success',
          showAs: 'growl',
          showSnackBar: false,
          scroll: this.entrySuccessScrollToTop,
        });
      }));

    return obs;
  }

  getSaveAndNewURL() {
    let url: string = '';

    if (this.saveAndNewURL) {
      url = this.saveAndNewURL;
    } else if (this.routeURL) {
      url = [this.routeURL, 'create'].join('/');
    }

    return url;
  }

  getCancelURL() {
    let url: string = '';

    if (this.cancelURL) {
      url = this.cancelURL;
    } else if (this.listURL) {
      url = this.listURL;
    } else if (this.routeURL) {
      url = this.routeURL;
    }

    return url;
  }
}

