import { Injectable } from '@angular/core';
import { APP_CONST } from '../../app.const';
import { RequestService } from '../../libraries/http/request.service';

@Injectable()
export class BatteryRentRestService {
  constructor(private _request: RequestService) {}
  baseURL = `${APP_CONST.API_MASTER}`;
  request = this._request.new(this.baseURL);

  findAll(queryParams: any = {}) {
    return this.request.get<any>(`/admin/battery-rent`, { params: queryParams });
  }

  create(payload: any) {
    return this.request.post<any>(`/admin/battery-rent/create`, payload);
  }

  getCustomer(queryParams: any = {}) {
    return this.request.get<any>(`/admin/battery-rent/customer`, { params: queryParams });
  }

  stopRent(payload: any) {
    return this.request.patch<any>(`/admin/battery-rent/unpair-battery`, payload);
  }

  replace(payload: any) {
    return this.request.patch<any>(`/admin/battery-rent/replace-battery`, payload);
  }

  getMembershipHistory(batteryCode: any = {}) {
    return this.request.get<any>(`/admin/battery-rent/membership-battery?batteryCode=${batteryCode}`);
  }
}
