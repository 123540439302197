import { AfterContentInit, Component, ContentChildren, forwardRef, Input, QueryList, ViewChildren, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormgenFormArrayComponent } from '../form-array/formgen-form-array.component';
import { FormgenTemplateDirective } from '../formgen-template.directive';
import { MFormgenSchema, MFormgenSchemaField } from '../formgen.model';
import { Subscription } from 'rxjs';

@Component({
  selector: '[appFormgenForm]',
  templateUrl: './formgen-form.component.html',
})
export class FormgenFormComponent implements AfterContentInit {
  @Input() name: string;
  @Input('schema') schemaFromInput: MFormgenSchema;
  @Input('form') formGroupFromInput: FormGroup;
  @Input('parentFormgenFormComponent') parentFormgenFormComponentInput: FormgenFormComponent;
  @Input() bindModel: any;
  @Output() bindModelChange: EventEmitter<any> = new EventEmitter;
  @ViewChildren(FormgenTemplateDirective) vTemplates: QueryList<FormgenTemplateDirective>;
  @ViewChildren(FormgenFormComponent) viewChildrenFormgenFormComponents: QueryList<FormgenFormComponent>;
  @ContentChildren(forwardRef(() => FormgenFormComponent)) contentChildrenFormgenFormComponents: QueryList<FormgenFormComponent>;
  @ContentChildren(forwardRef(() => FormgenFormArrayComponent)) contentChildrenFormgenFormArrayComponents: QueryList<FormgenFormArrayComponent>;

  lastFormRecognized: FormGroup;
  lastFormRecognizedSubscriptionValueChanges: Subscription;

  parentFormgenFormComponentStore: FormgenFormComponent;  // to be injected from parent app-formgen-form

  get targetFormgenSchemaField(): MFormgenSchemaField {
    return this.schema && this.name && this.schema.getFieldByName(this.name);
  }

  get targetFormgenSchemaFields(): MFormgenSchemaField[] {
    return (this.targetFormgenSchemaField && this.targetFormgenSchemaField.children) || (this.schema && this.schema.fields) || [];
  }

  get schema(): MFormgenSchema {
    return (this.parentFormgenFormComponent && this.parentFormgenFormComponent.schema) || this.schemaFromInput;
  }

  get parentFormgenFormComponent(): FormgenFormComponent {
    return this.parentFormgenFormComponentInput || this.parentFormgenFormComponentStore;
  }

  get form(): FormGroup {
    let targetFormGroup: FormGroup = this.schema && this.schema.form;
    if (this.formGroupFromInput) {
      targetFormGroup = this.formGroupFromInput;
    } else {
      const formgenSchemaField = this.targetFormgenSchemaField;
      if (formgenSchemaField && this.parentFormgenFormComponent) {
        targetFormGroup = this.parentFormgenFormComponent.form.get(formgenSchemaField.name) as FormGroup;
      }
    }

    if (targetFormGroup && this.lastFormRecognized !== targetFormGroup) {
      this.lastFormRecognized = targetFormGroup;

      if (this.lastFormRecognizedSubscriptionValueChanges) {
        this.lastFormRecognizedSubscriptionValueChanges.unsubscribe();
      }

      this.lastFormRecognizedSubscriptionValueChanges = this.lastFormRecognized.valueChanges.subscribe((newValue) => {
        this.bindModel = newValue;
        this.bindModelChange.emit(newValue);
      });
    }

    return targetFormGroup;
  }

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngAfterContentInit() {
    this.assignParentFormgenFormToChildrenFormgenForms();

    this.contentChildrenFormgenFormComponents.changes.subscribe(() => {
      this.assignParentFormgenFormToChildrenFormgenForms();
    });
  }

  assignParentFormgenFormToChildrenFormgenForms() {
    this.contentChildrenFormgenFormComponents.toArray().slice(1).forEach(contentChildrenFormgenFormComponent => {
      contentChildrenFormgenFormComponent.parentFormgenFormComponentStore = this;
    });
    this.contentChildrenFormgenFormArrayComponents.forEach(contentChildrenFormgenFormArrayComponent => {
      contentChildrenFormgenFormArrayComponent.parentFormgenFormComponentStore = this;
    });
  }

  detectChanges() {
    this._changeDetectorRef.detectChanges();
  }

  patchValue(formValue: any = {}) {
    this.form.patchValue(formValue || {});
  }
}
