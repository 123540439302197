import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { SPKBatteryRentRestService } from '../spk-battery-rent-rest.service';
import Swal from 'sweetalert2';
import { SPKBatteryRentListComponent } from '../list/spk-battery-rent-list.component';
import { CommonService } from '../../../libraries/libraries.module';
import * as moment from 'moment';

@Component({
  selector: 'app-delivery-date-modal',
  templateUrl: 'delivery-date-modal.component.html',
  styleUrls: ['../list/spk-battery-rent-list.component.scss']
})
export class DeliveryDateModalComponent {
  @Input() spk: any;
  @Input() disabled: boolean = false;

  constructor(
    private _SPKBatteryRentRest: SPKBatteryRentRestService,
    public _spkBatteryRentListComponent: SPKBatteryRentListComponent,
    private fb: FormBuilder
  ) {
    this.initFormDeliveryDate();
  }

  isLoading: boolean = false;
  formUpdateDeliveryDate: FormGroup;
  isDeliveryDateModalOpen: boolean = false;
  disableForm: boolean = false;

  ngOnInit() {
  }

  initFormDeliveryDate() {
    this.formUpdateDeliveryDate = this.fb.group({
      deliveryDate: ['', [Validators.required]],
      shippingCost: ['', [Validators.required]],
      spkBatteryRent: this.fb.array([]) // Initialize as FormArray
    });
  }

  isSpkBatteryRentSubmit(): boolean {
    const spkBatteryRent = this.formUpdateDeliveryDate.get('spkBatteryRent')?.value;
    // Assuming 'spkBatteryRent' is an array and you check the first item's status
    return spkBatteryRent && spkBatteryRent.length > 0 && spkBatteryRent[0].status === 'submit';
  }

  get spkBatteryRent(): FormArray {
    return this.formUpdateDeliveryDate.get('spkBatteryRent') as FormArray;
  }

  createBatteryGroup(battery): FormGroup {
    return this.fb.group({
      _id: [battery._id],
      voltageType: [battery.voltageType],
      total: [battery.total],
      status: [battery.status],
      batteryCode: this.fb.array(battery.batteryCode.map(code => new FormControl(code)))
    });
  }

  openDeliveryDateModal() {
    this.formUpdateDeliveryDate.patchValue({
      deliveryDate: this.spk?.deliveryDate ? this.toDateInput(this.spk?.deliveryDate) : '',
      shippingCost: this.spk?.shippingCost
    });

    this.spkBatteryRent.clear(); // Clear any existing entries
    this.spk?.spkBatteryRent?.forEach(battery => {
      this.spkBatteryRent.push(this.createBatteryGroup(battery));
    });

    this.isDeliveryDateModalOpen = true;
    console.log(this.formUpdateDeliveryDate);
  }
  
  addBatteryInput(index: number) {
    const batteryGroup = this.spkBatteryRent.at(index);
    (batteryGroup.get('batteryCode') as FormArray).push(new FormControl(''));
  }

  closeDeliveryDateModal() {
    this.isDeliveryDateModalOpen = false;
    this.formUpdateDeliveryDate.reset();
    this.spkBatteryRent.clear();
  }

  toDate(date) {
    return moment(date).format('DD MMM YYYY');
  }
  
  toDateInput(date) {
    return moment(date).format('YYYY-MM-DD');
  }

  async updateDeliveryDate(type) {
    if (type === 'submit') {
      // Perform additional validation for spkBatteryRent
      const spkBatteryRentInvalid = this.formUpdateDeliveryDate.value.spkBatteryRent.some(battery => {
        const { batteryCode, total } = battery;
        // Check if the length of batteryCode matches total and all entries are filled
        return batteryCode.length !== total || batteryCode.some(code => !code?.trim());
      });
  
      if (spkBatteryRentInvalid) {
        Swal.fire('Validation Error', 'Please ensure all battery codes are filled and match the total count.', 'error');
        return; // Stop execution if validation fails
      }
    }

    if (!this.formUpdateDeliveryDate.valid && type === 'submit') {
      CommonService.markAsDirty(this.formUpdateDeliveryDate);
    } else {
      const payload = {
        ...this.formUpdateDeliveryDate.value,
        spkId: this.spk._id,
        type: type,
        ...(type === 'submit' && { status: 'sent' })
      };
      this.isLoading = true;
      
      await this._SPKBatteryRentRest
        .updateShippingInfo(payload)
        .toPromise()
        .then(response => {
          if (response) {
            Swal.fire('Update Delivery Date successfully!', '', 'success');
            
            this._spkBatteryRentListComponent.callHookDirectly('init');
            this.closeDeliveryDateModal();
          }
        })
        .catch(error => {
          console.log(error);
          Swal.fire('Oops..', 'Something went wrong!', 'error');
        });
        this.isLoading = false;
    }
  }
}
