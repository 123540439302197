import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizationService } from '../../libraries/auth/authorization.service';
import { BasePageComponent } from '../../libraries/base/page/base-page.component';
import { BluebirdRegulerListComponent } from './list/bluebird-reguler-list.component';

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: BasePageComponent,
        children: [
          {
            path: '',
            component: BluebirdRegulerListComponent,
            canActivate: [AuthorizationService],
            data: {
              // TODO: Update the access
              name: 'partnershipGojekSwadaya.read'
              // name: 'partnershipBluebirdReguler.read'
            }
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BluebirdRegulerRoutingModule {}
