import { FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { CommonService } from '../common/common.service';
import { MFieldDefinition, MForm, MFormDefinition } from '../common/form.model';
import { MFormgenFieldConfig } from './formgen-field.model';

export class MFormgenSchema {
  _form: FormGroup | MForm = new MForm({});
  set form(newForm) {
    this._form = newForm;

    this.buildFormByMapExistingControls();
  }
  get form(): FormGroup {
    return this._form as FormGroup;
  }
  get mform(): MForm {
    return this._form as MForm;
  }

  private _fields: MFormgenSchemaField[] = [];
  get fields() {
    return this._fields;
  }
  set fields(newFields) {
    this._fields = newFields;
    this.mform.formDefinition = this.buildFormDefinitionByFormgenFieldConfigs(newFields);
    this.buildForm();
  }

  customLayout: boolean = true;
  buildControls: boolean = true; // @depcrecated, you should always generate controls & use form.setControl() to override existing control

  constructor(fields?: MFormgenSchemaField[]) {
    if (fields) {
      this.fields = fields;
    }
  }

  onFormValuesPopulated(formValues: any) {}

  getFieldByName(fieldName: string, fields: MFormgenSchemaField[] = this.fields): MFormgenSchemaField {
    for (const field of fields) {
      if (field.treePath === fieldName) {
        return field;
      } else if (field.children && field.children.length) {
        const fieldSearch = this.getFieldByName(fieldName, field.children);
        if (fieldSearch) {
          return fieldSearch;
        }
      }
    }
  }

  buildForm() {
    if (this.buildControls) {
      this.buildSchemaControls();
    } else {
      this.buildFormByMapExistingControls();
    }
  }

  /**
   *  @depcrecated
   *  See {@link[MFormgenSchema.buildControls]}
   */
  buildSchemaControls() {
    _.merge(this.fields, this.mform.formDefinition); // merge changes from formDefinition to this.fields
  }

  buildFormByMapExistingControls() {
    CommonService.assignObjectTreePath(this.fields, ['children'], 'name', 'treePath', 'treeParentPath', true);
  }

  buildFormDefinitionByFormgenFieldConfigs(formgenFields: MFormgenFieldConfig[], formDefinition: MFormDefinition = []): MFormDefinition {
    _.forEach(formgenFields, field => {
      const fieldDefinition: MFieldDefinition = {
        name: field.name,
        disabled: field.disabled,
        isFormArray: field.isFormArray,
        defaultValue: field.defaultValue,
        controlEvents: field.controlEvents,
        syncValidators: this.buildSyncValidators(field),
        asyncValidators: field.asyncValidators,
        children: field.children,
      };

      if (fieldDefinition.isFormArray) {
        fieldDefinition.children = [];

        this.buildFormDefinitionByFormgenFieldConfigs(field.children, fieldDefinition.children);
      }

      formDefinition.push(fieldDefinition);
    });

    return formDefinition;
  }

  buildSyncValidators(field: MFormgenSchemaField) {
    const fieldSyncValidators = _.castArray(field.syncValidators).filter(_.identity);
    const validators = [];

    if (_.get(field, 'validations.required')) {
      validators.push(Validators.required);
    }
    if (_.get(field, 'validations.maxLength')) {
      const maxLength = _.get(field, 'validations.maxLength');
      validators.push(Validators.maxLength(maxLength));
    }

    return fieldSyncValidators.concat(validators);
  }

  merge(schemaToMerge: MFormgenSchema) {
    this.fields = this.fields.concat(schemaToMerge.fields);

    _.forEach(this.form.controls, (control, controlName) => {
      schemaToMerge.form.setControl(controlName, control);
    });

    this.buildFormByMapExistingControls();
    schemaToMerge.form = this.form;
    schemaToMerge.buildFormByMapExistingControls();
  }

  populateFromValues(formValues: any = {}) {
    this.mform.populateFromValues(formValues);

    this.onFormValuesPopulated(formValues);
  }
}

export class MFormgenSchemaField extends MFormgenFieldConfig {
  field?: 'date' | 'text' | 'textarea' | 'textarea_html' | 'chips' | 'autocomplete' | 'checkbox' | 'select' | 'multiselect';
  ignore?: boolean;
  hidden?: boolean;
  preprocess?: Function;
  children?: MFormgenSchemaField[];
}
