import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TableModule } from 'primeng/table';
import { GridTableColumnComponent } from './grid-table-column.component';

@NgModule({
  imports: [
    CommonModule,
    TableModule,
    RouterModule,
    TranslateModule,
  ],
  declarations: [
    GridTableColumnComponent,
  ],
  exports: [
    GridTableColumnComponent,
  ],
})
export class GridTableColumnModule { }
