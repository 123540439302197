import { Injectable } from '@angular/core';
import { APP_CONST } from '../../app.const';
import { RequestService } from '../../libraries/http/request.service';

@Injectable()
export class FaqRestService {
  baseURL = `${APP_CONST.API_MAIN}/admin/faqs`;
  request = this._request.new(this.baseURL);

  constructor(private _request: RequestService) {}

  create(payload: any) {
    return this.request.post<any>(``, payload);
  }

  findAll(queryParams: any = {}) {
    return this.request.get<any>(``, { params: queryParams });
  }

  load(faqId: string) {
    return this.request.get<any>(`${faqId}`);
  }

  delete(faqId: string) {
    return this.request.delete<any>(`${faqId}`);
  }

  update(faqId: string, payload: any) {
    return this.request.put<any>(`${faqId}`, payload);
  }
}
