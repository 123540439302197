import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import * as _ from 'lodash';
import { environment } from '../../../environments/environment';
import { UrlService } from '../common/url.service';
import { ApiBootstrapService } from '../http/api-bootstrap.service';
import { AclService } from './acl.service';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthorizationService {
  url403 = '/error/403';
  url404 = '/error/404';

  publicRoutePaths: string[] = ['/', '/login'];

  constructor(
    private _apiBoostrap: ApiBootstrapService,
    private _authentication: AuthenticationService,
    private _acl: AclService,
    private _router: Router,
    private _url: UrlService
  ) {
    this.aclGuard();
  }

  aclGuard() {
    if (!this._authentication.user) {
      this._acl.flushRoles();
      this._acl.attachRole('guest');
    }
  }

  /**
   * This is the logic form canActivate route guard.
   * If user logged in and user is unauthorized for target route then show 403 page.
   * If user is not logged in and user is unauthorized for target route then show 404 page.
   */
  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): boolean {
    const { urlPath } = this._url.extractHashParts(
      document.location.origin + _state.url
    );
    const routeName = _.get(_route, 'data.name', null);
    const routeNameList = _.get(_route, 'data.name');

    if (
      _.isArray(routeNameList) &&
      this._acl.can.apply(this._acl, _.castArray(routeNameList))
    ) {
      return true;
    }

    if (routeName) {
      if (!this._acl.can(routeName)) {
        if (this._authentication.user) {
          if (!environment.production) {
            console.error(
              `403: Cannot access to ${_state.url}`,
              this._authentication.user
            );
          }

          /* if user has logged in and access publicRoute, we should make user logout first of all */
          if (urlPath !== '/' && this.publicRoutePaths.includes(urlPath)) {
            this._authentication.logout();
            return true;
          }

          this._router.navigateByUrl(this.url403);
        } else {
          if (!environment.production) {
            console.error(`404: Cannot access to ${_state.url}`);
          }

          this._router.navigate(['/login'], {
            queryParams: {
              destination: _state.url
            }
          });
        }
        return false;
      }
    } else {
      this._authentication.logout();
      // this._router.navigate(['/login']);
    }
    return true;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(route, state);
  }
}
