import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FieldsetAccordionComponent } from './fieldset-accordion.component';
import { FieldsetAccordionContentComponent } from './content/fieldset-accordion-content.component';
import { FieldsetAccordionDirective } from './fieldset-accordion.directive';
import { FieldsetAccordionItemComponent } from './item/fieldset-accordion-item.component';
import { FieldsetAccordionHeaderComponent } from './header/fieldset-accordion-header.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    FieldsetAccordionComponent,
    FieldsetAccordionContentComponent,
    FieldsetAccordionDirective,
    FieldsetAccordionHeaderComponent,
    FieldsetAccordionItemComponent,
  ],
})
export class FieldsetAccordionModule { }
