import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CheckboxModule } from 'primeng/checkbox';
import { ListboxModule } from 'primeng/listbox';
import { LibrariesModule } from '../../libraries/libraries.module';
import { RetryDialogModule } from '../../libraries/retry-dialog/retry-dialog.module';
import { SpinnerModule } from '../../libraries/spinner/spinner.module';
import { SystemMessageDisplayModule } from '../../libraries/system-message-display/system-message-display.module';
import { ViewUtilityModule } from '../../libraries/view-utility/view-utility.module';
import { BaseActionSaveComponent } from './action/save/base-action-save.component';
import { BaseCompComponent } from './comp/base-comp.component';
import { BaseEntryComponent } from './entry/base-entry.component';
import { BaseEntryFooterComponent } from './entry/footer/base-entry-footer.component';
import { BasePageComponent } from './page/base-page.component';
import { BaseActionHeaderComponent } from './action/header/base-action-header.component';

@NgModule({
  imports: [
    BsDropdownModule,
    CheckboxModule,
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    LibrariesModule,
    ListboxModule,
    ModalModule,
    PaginationModule,
    RetryDialogModule,
    RouterModule,
    SpinnerModule,
    SystemMessageDisplayModule,
    TranslateModule,
    ViewUtilityModule,
  ],
  declarations: [
    BaseActionHeaderComponent,
    BaseActionSaveComponent,
    BaseEntryComponent,
    BaseEntryFooterComponent,
    BasePageComponent,
    BaseCompComponent,
  ],
  exports: [
    BaseActionHeaderComponent,
    BaseActionSaveComponent,
    BaseEntryComponent,
    BaseEntryFooterComponent,
    BasePageComponent,
    BaseCompComponent,
  ],
})
export class BaseModule { }
