import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import Axios from 'axios';
import * as _ from 'lodash';
import { AutoComplete } from 'primeng/autocomplete';
import { from } from 'rxjs';
import { AutocompleteService } from '../../common/autocomplete.service';
import { CommonService } from '../../common/common.service';
import { FormgenFieldControlBComponent } from '../field-control/formgen-field-control.bcomponent';

@Component({
  selector: 'app-formgen-field-autocomplete',
  templateUrl: './formgen-field-autocomplete.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class FormgenFieldAutocompleteComponent extends FormgenFieldControlBComponent implements AfterViewInit, OnInit {
  @ViewChild('fAC', { static: false }) fAC: AutoComplete;

  acRemoteHandler: any;
  isACLocal: boolean;

  public _ = _;

  isQuickCreateVisible: boolean = false;

  constructor(
    private _autocomplete: AutocompleteService,
  ) {
    super();
  }

  ngOnInit() {
    this.isACLocal = _.get(this.field, 'templateConfig.autocomplete.localFilter');
    this.acRemoteHandler = CommonService.remoteACItemsHandler({
      remoteParams: this.buildACRemoteParams.bind(this),
      remoteRequest: this.buildACRemoteRequest.bind(this),
      remoteRequestMap: this.field.templateConfig.autocomplete.remoteResponseTransform ?
        this.field.templateConfig.autocomplete.remoteResponseTransform :
        (response) => (!response) ? [] : response,
      element: () => this.fAC,
    });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    if (this.controlTag && this.controlTag.control.value && this.field.templateConfig && this.field.templateConfig.autocomplete && this.field.templateConfig.autocomplete.remoteLoadFirst) {
      this.loadRemoteData();
    }
  }

  onComplete($event) {
    if (!_.get(this.field, 'interceptEvents.onComplete')) {
      if (this.isACLocal) {
        return this._autocomplete.onLocalACSearch(
          this.fAC,
          _.get(this.field, 'templateConfig.options', []),
          _.get(this.field, 'templateConfig.autocomplete.localFilterBy', []),
          $event.query,
          false,
        );
      } else if (!this.isACLocal) {
        return this.acRemoteHandler.search($event);
      }
    }

    this.callEvent('onComplete', $event);
  }

  onDropdownClick($event) {
    if (!_.get(this.field, 'interceptEvents.onDropdown')) {
      if (this.isACLocal) {
        this._autocomplete.onLocalACDropdown(
          this.fAC,
          _.get(this.field, 'templateConfig.options', []),
          false,
        );
      } else if (!this.isACLocal) {
        return this.acRemoteHandler.dropdown($event);
      }
    }

    this.callEvent('onDropdown', $event);
  }

  onSelect($event) {
    this.callEvent('onSelect', $event);
  }

  onClear($event) {
    if (!_.get(this.field, 'interceptEvents.onClear')) {
      this.controlTag.control.reset();
    }

    this.callEvent('onClear', $event);
  }

  buildACRemoteParams(event: any, type: string) {
    if (this.field.templateConfig.autocomplete.remoteParams) {
      return this.field.templateConfig.autocomplete.remoteParams(event, type, this.field, this.schema);
    } else {
      const queryParam: any = {};
      if (type === 'search') {
        queryParam[this.field.templateConfig.autocomplete.remoteFilterParamKey] = event.query;
      }

      return [null, queryParam];
    }
  }

  buildACRemoteRequest(payload: any = null, queryParams: any = {}) {
    if (this.field.templateConfig.autocomplete.remoteRequest) {
      return this.field.templateConfig.autocomplete.remoteRequest(payload, queryParams);
    } else {
      return from(
        Axios.post(this.field.templateConfig.autocomplete.remoteUrl, payload, {
          params: queryParams,
        })
      );
    }
  }

  loadRemoteData() {
    from(
      Axios.post(this.field.templateConfig.autocomplete.remoteUrl, null, {
        params: {
          RefID: this.controlTag.control.value,
        },
      })
    ).subscribe(response => {
      const remoteValue = _.head(response.data.data);
      if (remoteValue) {
        this.controlTag.control.setValue(remoteValue);
      }
    });
  }
}

