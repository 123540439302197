

import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

import { ImportExcelService } from './excel/import-excel.service';
import { MImportDataMap, MImportDataMapField, MImportDataMapFieldRules } from './import-data.model';

export const enum EImportDataTypes {
  OBJECT = 1,
  EXCEL = 2,
}

@Injectable()
export class ImportDataService {
  constructor(
    private _importExcel: ImportExcelService,
  ) { }

  getArrayBuffer(selector: string): Observable<ArrayBuffer> {
    return Observable.create(observer => {
      const file: any = _.first($(selector).prop('files'));
      if (file) {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          observer.next((<any>e.target).result);
          observer.complete();
        };
        fileReader.readAsArrayBuffer(file);
      }
    });
  }

  prepare(params: {
    mapOptions: MImportDataMap,
    from: EImportDataTypes,
    contentToProcess?: any,
    fileInputSelector?: string,
  }) {
    let fileContent: any;
    let parsedContent: any;
    let records: any = [];
    let header: any;
    let parsedHeader: any;
    let fieldRules: MImportDataMapFieldRules;

    const returnSet = {
      content: async (selector?: string) => {
        fileContent = await this.getArrayBuffer(selector || params.fileInputSelector).toPromise();
        return fileContent;
      },
      parse: async (srcFileContent?: any) => {
        switch (params.from) {
          case EImportDataTypes.EXCEL:
            parsedContent = this._importExcel.parse(srcFileContent || fileContent || await returnSet.content());
            parsedContent = this.normalizeRecords(parsedContent);

            parsedHeader = parsedContent[0] || [];
            header = this.parseHeader(parsedHeader);
            parsedHeader = header.parsedHeader;
            fieldRules = header.fieldRules;

            parsedContent.shift();
            break;
          case EImportDataTypes.OBJECT:
            parsedContent = srcFileContent || params.contentToProcess;

            parsedHeader = Object.keys(parsedContent);
            header = this.parseHeader(parsedHeader);
            parsedHeader = header.parsedHeader;
            fieldRules = header.fieldRules;
            break;
        }
        return parsedContent;
      },
      header: async () => {
        if (!parsedHeader) {
          await returnSet.parse();
        }

        return parsedHeader;
      },
      transform: async () => {
        records = this.transformRecords({
          mapOptions: params.mapOptions,
          records: await returnSet.parse(),
        });

        return records;
      },
      process: async () => {
        await returnSet.transform();

        return await this.processTransformedRecords({
          mapOptions: params.mapOptions,
          records,
        });
      },
      setMap(newMapOptions: MImportDataMap) {
        params.mapOptions = newMapOptions;
      },
      mapByHeader(headerData: string[], targetMapOptions: MImportDataMap = params.mapOptions) {
        const matchedMap = _.clone(targetMapOptions);
        for (const hIdx in headerData) {
          let hFound = false;
          const h = headerData[hIdx];
          for (const mapKey of Object.keys(matchedMap)) {
            if (hFound) {
              continue;
            }
            const map = targetMapOptions[mapKey];
            const match = _.filter(_.map(map.criteria || [], m => m.test(h)), _.identity).length;
            if (match) {
              map.identifier = parseInt(hIdx, 0);
              hFound = true;
              delete matchedMap[mapKey];
            } else {
              map.identifier = -1;
            }
          }
        }
        returnSet.setMap(targetMapOptions);
      },
    };

    return returnSet;
  }

  transformRecords(params: {
    mapOptions: MImportDataMap,
    records: any,
  }) {
    const transformedRecords = [];
    _.forEach(params.records, record => {
      const transformedRecord = {};

      _.forEach(params.mapOptions, (mapOption, field) => {
        _.set(transformedRecord, field, _.get(record, mapOption.identifier || field) || null);
      });

      transformedRecords.push(transformedRecord);
    });

    return transformedRecords;
  }

  normalizeRecords(xlsxJSON: any = []) {
    const cleanXLSXWithoutComments = [];
    _.forEach(xlsxJSON, item => {
      if (item.length) {
        if (!/^\/\/\//.test(item[0])) {
          cleanXLSXWithoutComments.push(item);
        }
      }
    });

    return cleanXLSXWithoutComments;
  }

  parseHeader(headers: any = []): {
    fieldRules: MImportDataMapFieldRules;
    parsedHeader: any;
  } {
    const parsedHeader: any = [];
    _.forEach(headers, rawHeader => {
      if (/^\*/.test(rawHeader)) {
        const cleanHeader = rawHeader.replace(/^\*/, '');
        parsedHeader.push(cleanHeader);
      } else {
        parsedHeader.push(rawHeader);
      }
    });

    return {
      fieldRules: null,
      parsedHeader,
    };
  }

  async processTransformedRecords(params: { mapOptions: MImportDataMap, records: any } = { mapOptions: {}, records: [] }) {
    const processedRecords = params.records.slice();

    const mapWithProcessors: any = _.pickBy(params.mapOptions, o => Boolean(o.processor));
    if (_.size(mapWithProcessors)) {
      for (const recordIdx in params.records) {
        const record = params.records[recordIdx];
        for (const field in mapWithProcessors) {
          const mapOption = mapWithProcessors[field];
          _.set(processedRecords, `[${recordIdx}].${field}`, await mapOption.processor(_.get(record, field), record));
        }
      }
    }

    const mapWithProcessorProvided: any = _.pickBy(params.mapOptions, o => Boolean(o.processorProvider));
    if (_.size(mapWithProcessorProvided)) {
      for (const recordIdx in params.records) {
        const record = params.records[recordIdx];
        for (const field in mapWithProcessorProvided) {
          const mapOption = mapWithProcessorProvided[field];
          _.set(processedRecords, `[${recordIdx}].${field}`, this.processProcessorProvider(_.get(record, field), mapOption));
        }
      }
    }

    return processedRecords;
  }

  processProcessorProvider(fieldValue: any, mapOption: MImportDataMapField) {
    let fieldValueToProcess = fieldValue;

    switch (mapOption.processorProvider.preConvertCaseTo) {
      case 'snake':
        fieldValueToProcess = _.snakeCase(fieldValueToProcess);
        break;
    }

    switch (mapOption.processorProvider.convertTo) {
      case 'string':
        fieldValueToProcess = _.toString(fieldValueToProcess);
        break;
      case 'number':
        fieldValueToProcess = _.toNumber(`${fieldValueToProcess}`.replace(/,|\./g, ''));
        break;
      case 'boolean':
        if (/^\s*y\s*$/i.test(fieldValueToProcess)) {
          fieldValueToProcess = true;
        }
        if (/^\s*n\s*$/i.test(fieldValueToProcess)) {
          fieldValueToProcess = false;
        }
        fieldValueToProcess = Boolean(fieldValueToProcess);
        break;
      case 'lowercase':
        fieldValueToProcess = _.toLower(fieldValueToProcess);
        break;
    }

    if (mapOption.processorProvider.allowedValues) {
      if (!mapOption.processorProvider.allowedValues.includes(fieldValueToProcess)) {
        fieldValueToProcess = mapOption.processorProvider.defaultValue || null;
      }
    }

    return fieldValueToProcess;
  }

  buildFormFromMap(importMap: MImportDataMap) {
    const form = new FormGroup({});

    _.forEach(importMap, (map, field) => {
      const syncValidations = [];
      if (_.get(map, 'validations.required', false)) {
        syncValidations.push(Validators.required);
      }
      if (_.get(map, 'validations.criteria', false)) {
        syncValidations.push(Validators.pattern(map.validations.criteria));
      }
      form.addControl(field, new FormControl(_.get(map, 'form.defaultValue', null), syncValidations));
    });

    return form;
  }

  buildRecordsFormFromMap(records: any, importMap: MImportDataMap) {
    const form = new FormArray([]);
    _.forEach(records, (record, recordIndex) => {
      const formGroup = this.buildFormFromMap(importMap);
      formGroup.patchValue(record);

      formGroup.addControl('rowNumber', new FormControl(recordIndex));

      form.push(formGroup);
    });

    return form;
  }
}
