import { Component } from '@angular/core';
import { TranslatorService } from '../../libraries/common/translator.service';

@Component({
  selector: 'app-basic-layout',
  styleUrls: ['./basic-layout.component.scss'],
  templateUrl: './basic-layout.component.html',
})
export class BasicLayoutComponent {
  constructor(
    // Important
    public _translator: TranslatorService
  ) {}
}
