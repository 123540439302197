import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { SystemMessageService } from '../system-message/system-message.service';
import { ESystemMessageShowAs, ESystemMessageType, MSystemMessage } from '../system-message/system-message.model';


@Component({
  selector: 'app-system-message-display',
  templateUrl: 'system-message-display.html'
})
export class SystemMessageDisplayComponent implements OnDestroy, OnInit {
  @Input() scope: string;
  @Input() service: SystemMessageService;

  _ = _;
  groupedMessages: {
    [key: string]: {
      [key: string]: MSystemMessage[];
    };
  } = {};
  groupedMessagesBlockKeys: any = [];
  groupedMessagesGrowlKeys: any = [];
  messageTypes = _.keys(ESystemMessageType);
  messsageShowTypes = _.keys(ESystemMessageShowAs);
  subscriptions: Subscription[] = [];

  constructor(
    @Inject('GlobalSystemMessage') private _globalSystemMessage: SystemMessageService
  ) { }

  ngOnInit() {
    if (this.scope === 'global') {
      this.service = this._globalSystemMessage;
    }

    this.messageTypes.forEach(type => {
      this.messsageShowTypes.forEach(showAs => {
        this.subscriptions.push(
          this.service.getMessages(<any>ESystemMessageType[type], <any>ESystemMessageShowAs[showAs])
            .subscribe(messages => {
              _.set(this.groupedMessages, `${showAs}.${type}`, messages);

              this.groupedMessagesBlockKeys = _.keys(_.get(this.groupedMessages, 'Block'));
              this.groupedMessagesGrowlKeys = _.keys(_.get(this.groupedMessages, 'Growl'));
            })
        );
      });
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
