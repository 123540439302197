import { Directive } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[controlTag]',
  exportAs: 'controlTag'
})
export class ControlTagDirective {
  constructor(public ngControl: NgControl) { }

  get control() {
    return this.ngControl && this.ngControl.control;
  }
}
