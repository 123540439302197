import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { UrlService } from '../../libraries/common/url.service';

@Injectable({
  providedIn: 'root'
})
export class AdminLayoutService {
  sidebar: any = {
    over: false,
    showheader: true,
    showtoolbar: true,
    visible: false,
    offcanvas: true,
    offcanvasVisible: true
  };

  menuItems: any[] = [
    {
      i18nName: 'ui.navbarMenu.dashboard',
      imgpath: '../../../assets/img/icons/sidebar/sidebar-dashboard.svg',
      link: '/dashboard',
      permissions: ['dashboard']
    },
    {
      i18nName: 'ui.navbarMenu.product',
      imgpath: '../../../assets/img/icons/sidebar/sidebar-product.svg',
      permissions: ['product.read', 'productTypeVariant.read', 'battery.read'],
      subItems: [
        {
          i18nName: 'ui.navbarMenu.productMotorcycle',
          link: '/motorcycle',
          permissions: ['product.read']
        },
        {
          i18nName: 'ui.navbarMenu.productSparepart',
          link: '/sparepart',
          permissions: ['product.read']
        },
        {
          i18nName: 'ui.navbarMenu.dealerRegion',
          link: '/region-dealer',
          permissions: ['product.read']
        },
        {
          i18nName: 'ui.navbarMenu.productTypeVariant',
          link: '/product-type-variant',
          permissions: ['productTypeVariant.read']
        },
        {
          i18nName: 'ui.navbarMenu.battery',
          link: '/battery',
          permissions: ['battery.read']
        }
      ]
    },
    {
      i18nName: 'ui.navbarMenu.spk',
      imgpath: '../../../assets/img/icons/sidebar/sidebar-spk.svg',
      permissions: ['spk.read'],
      subItems: [
        {
          i18nName: 'ui.navbarMenu.spkUnit',
          link: '/spk',
          permissions: ['spk.read']
        },
        {
          i18nName: 'ui.navbarMenu.spkCash',
          link: '/spk-cash',
          permissions: ['spk.read']
        },
        {
          i18nName: 'ui.navbarMenu.spkIndent',
          link: '/spk-indent',
          permissions: ['spk.read']
        },
        {
          i18nName: 'ui.navbarMenu.spkLiteToReguler',
          link: '/spk-lite-to-reguler',
          permissions: ['spk.read']
        },
        {
          i18nName: 'ui.navbarMenu.spkSummary',
          link: '/spk/spk-summary',
          permissions: ['spk.read']
        }
      ]
    },
    {
      i18nName: 'ui.navbarMenu.spkBattery',
      imgpath: '../../../assets/img/icons/sidebar/sidebar-spk.svg',
      permissions: ['spk.read', 'custBatteryRent.read'],
      subItems: [
        {
          i18nName: 'ui.navbarMenu.spkBattery',
          link: '/spk-battery',
          permissions: ['spk.read']
        },
        {
          i18nName: 'ui.navbarMenu.spkBatteryRent',
          link: '/spk-battery-rent',
          permissions: ['spkBatteryRent.read']
        },
        {
          i18nName: 'ui.navbarMenu.batteryRent',
          link: '/battery-rent',
          permissions: ['custBatteryRent.read']
        }
      ]
    },
    {
      i18nName: 'ui.navbarMenu.buybackTradeIn',
      imgpath: '../../../assets/img/icons/sidebar/sidebar-buyback.svg',
      permissions: ['buybackTradein.read'],
      subItems: [
        {
          i18nName: 'ui.navbarMenu.buyback',
          link: '/buyback',
          permissions: ['buybackTradein.read']
        },
        {
          i18nName: 'ui.navbarMenu.tradeIn',
          link: '/tradein',
          permissions: ['buybackTradein.read']
        }
      ]
    },
    {
      i18nName: 'ui.navbarMenu.po',
      imgpath: '../../../assets/img/icons/sidebar/sidebar-order.svg',
      permissions: ['order.read'],
      subItems: [
        {
          i18nName: 'ui.navbarMenu.orderData',
          link: '/po',
          permissions: ['order.read']
        },
        {
          i18nName: 'ui.navbarMenu.orderSummary',
          link: '/po/po-summary',
          permissions: ['order.read']
        }
      ]
    },
    {
      i18nName: 'ui.navbarMenu.location',
      imgpath: '../../../assets/img/icons/sidebar/sidebar-location.svg',
      link: '/location',
      permissions: ['location.read', 'dealer.read', 'dealerBranch.read'],
      subItems: [
        {
          i18nName: 'ui.navbarMenu.locationSGB',
          imgpath: '../../../assets/img/icons/sidebar/sidebar-location.svg',
          link: '/location',
          permissions: ['location.read']
        },
        {
          i18nName: 'ui.navbarMenu.dealerManagement',
          imgpath: '../../../assets/img/icons/sidebar/sidebar-customer.svg',
          link: '/dealer',
          permissions: ['dealer.read', 'dealerBranch.read']
        }
      ]
    },
    {
      i18nName: 'ui.navbarMenu.userManage',
      imgpath: '../../../assets/img/icons/sidebar/sidebar-customer.svg',
      permissions: ['user.read', 'role.read'],
      subItems: [
        {
          i18nName: 'ui.navbarMenu.user',
          link: '/users',
          permissions: ['user.read']
        },
        {
          i18nName: 'ui.navbarMenu.role',
          link: '/roles',
          permissions: ['role.read']
        }
      ]
    },
    {
      i18nName: 'ui.navbarMenu.subsidi',
      imgpath: '../../../assets/img/icons/sidebar/sidebar-subsidy.svg',
      permissions: ['subsidy.read'],
      subItems: [
        {
          i18nName: 'ui.navbarMenu.subsidyUser',
          link: '/subsidi',
          permissions: ['subsidy.read']
        },
        {
          i18nName: 'ui.navbarMenu.subsidyTransaction',
          link: '/subsidi/subsidi-transaction',
          permissions: ['subsidy.read']
        },
        {
          i18nName: 'ui.navbarMenu.subsidySummary',
          link: '/subsidi/subsidi-summary',
          permissions: ['subsidy.read']
        }
      ]
    },
    {
      i18nName: 'ui.navbarMenu.contentManagement',
      imgpath: '../../../assets/img/icons/sidebar/sidebar-banner.svg',
      permissions: ['banner.read', 'promo.read'],
      subItems: [
        {
          i18nName: 'ui.navbarMenu.banner',
          link: '/banner',
          permissions: ['banner.read']
        },
        {
          i18nName: 'ui.navbarMenu.promo',
          link: '/promo',
          permissions: ['promo.read']
        },
        {
          i18nName: 'ui.navbarMenu.faq',
          link: '/faq',
          permissions: ['faq.read']
        },
      ]
    },
    {
      i18nName: 'ui.navbarMenu.newsManage',
      imgpath: '../../../assets/img/icons/sidebar/sidebar-news.svg',
      permissions: ['news.read', 'newsCategory.read'],
      subItems: [
        {
          i18nName: 'ui.navbarMenu.news',
          link: '/news',
          permissions: ['news.read']
        },
        {
          i18nName: 'ui.navbarMenu.categories',
          link: '/news-categories',
          permissions: ['newsCategory.read']
        }
      ]
    },
    {
      i18nName: 'ui.navbarMenu.partnerships',
      imgpath: '../../../assets/img/icons/sidebar/sidebar-sgb.svg',
      permissions: [
        'partnershipBusiness.read',
        'partnership.read',
        'partnershipGentari.read'
      ],
      subItems: [
        {
          i18nName: 'ui.navbarMenu.partnershipBusiness',
          link: '/partnership-business',
          permissions: ['partnershipBusiness.read']
        },
        {
          i18nName: 'ui.navbarMenu.partnership',
          link: '/partnership',
          permissions: ['partnership.read']
        },
        {
          i18nName: 'ui.navbarMenu.partnershipGentari',
          link: '/partnership-gentari',
          permissions: ['partnershipGentari.read']
        },
        {
          i18nName: 'ui.navbarMenu.gojekSwadaya',
          link: '/gojek-swadaya',
          permissions: ['partnershipGojekSwadaya.read']
        },
        {
          i18nName: 'ui.navbarMenu.bluebirdReguler',
          link: '/bluebird-reguler',
          // TODO: create new permission
          permissions: ['partnershipGojekSwadaya.read']
        },
        {
          i18nName: 'ui.navbarMenu.bluebirdCreditBri',
          link: '/bluebird-credit-bri',
          // TODO: create new permission
          permissions: ['partnershipGojekSwadaya.read']
        }
      ]
    },
    {
      i18nName: 'ui.navbarMenu.affiliator',
      imgpath: '../../../assets/img/icons/sidebar/sidebar-contact.svg',
      permissions: [
        'affiliator.read',
        'affiliatorClaim.read',
        'partnershipBFI.read'
      ],
      subItems: [
        {
          i18nName: 'ui.navbarMenu.affiliator',
          link: '/affiliator',
          permissions: ['affiliator.read']
        },
        {
          i18nName: 'ui.navbarMenu.affiliatorClaim',
          link: '/affiliator-claim',
          permissions: ['affiliatorClaim.read']
        },
        {
          i18nName: 'ui.navbarMenu.agentBfi',
          link: '/agent-bfi',
          permissions: ['partnershipBFI.read']
        },
        {
          i18nName: 'ui.navbarMenu.agentBfiClaim',
          link: '/agent-bfi-claim',
          permissions: ['partnershipBFI.read']
        },
      ]
    },
    {
      i18nName: 'ui.navbarMenu.contact',
      imgpath: '../../../assets/img/icons/sidebar/sidebar-customer.svg',
      permissions: [
        'clientUser.read',
        'formContact.read'
      ],
      subItems: [
        {
          i18nName: 'ui.navbarMenu.customer',
          link: '/customer',
          permissions: ['clientUser.read']
        },
        {
          i18nName: 'ui.navbarMenu.formContact',
          link: '/contact',
          permissions: ['formContact.read']
        },
      ]
    },
    {
      i18nName: 'ui.navbarMenu.marketingSupport',
      imgpath: '../../../assets/img/icons/sidebar/sidebar-banner.svg',
      permissions: [
        'generalDesign.read',
        'marketingSupportCustomDesignForm.read',
        'marketingSupportCustomDesignReport.read'
      ],
      subItems: [
        {
          i18nName: 'ui.navbarMenu.marketingSupportGeneralDesign',
          link: '/marketing-support/general-design',
          permissions: ['generalDesign.read']
        },
        {
          i18nName: 'ui.navbarMenu.marketingSupportCustomDesignForm',
          link: '/marketing-support/custom-design-form',
          permissions: ['marketingSupportCustomDesignForm.read']
        },
        {
          i18nName: 'ui.navbarMenu.marketingSupportCustomDesignReport',
          link: '/marketing-support/custom-design-report',
          permissions: ['marketingSupportCustomDesignReport.read']
        }
      ]
    }
  ];

  constructor(private _router: Router, private _url: UrlService) {
    this._router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        const url = this._url.extractHashParts();
        if ((e as any).url !== url.urlPath) {
        }
      } else if (e instanceof NavigationEnd) {
        $('main.main-container').animate(
          {
            scrollTop: 0
          },
          0
        );
      }
    });
  }
}
