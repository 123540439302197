import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { ContextMenuModule } from 'primeng//contextmenu';
import { TooltipModule as TooltipPrimengModule } from 'primeng//tooltip';
import { FileSecurityModule } from '../file/file-security.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { ImgUploadDisplayComponent } from './display/img-upload-display.component';
import { ImgUploadGalleryComponent } from './gallery/img-upload-gallery.component';
import { ImgUploadComponent } from './img-upload.component';
import { ImgUploadItemComponent } from './item/img-upload-item.component';

@NgModule({
  imports: [
    CommonModule,
    ContextMenuModule,
    FileSecurityModule,
    FlexLayoutModule,
    SpinnerModule,
    TooltipPrimengModule,
    TranslateModule
  ],
  declarations: [
    ImgUploadComponent,
    ImgUploadDisplayComponent,
    ImgUploadGalleryComponent,
    ImgUploadItemComponent,
  ],
  exports: [
    ImgUploadComponent,
    ImgUploadDisplayComponent,
    ImgUploadGalleryComponent,
  ]
})
export class ImgUploadModule { }
