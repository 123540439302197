import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorage } from 'ngx-webstorage';

@Injectable()
export class TranslatorService {
  @LocalStorage()
  currentLang: string;

  defaultLanguage: string = 'id';

  constructor(
    private _translate: TranslateService,
  ) {
    if (!this.currentLang) {
      this.currentLang = this.defaultLanguage;
    }

    // this language will be used as a fallback when a translation isn't found in the current language
    this._translate.setDefaultLang(this.defaultLanguage);

    this.useLanguage(this.currentLang);
  }

  useLanguage(lang: string) {
    this.currentLang = lang;
    this._translate.use(lang);
  }
}
