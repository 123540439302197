import { Injectable } from '@angular/core';

@Injectable()
export class ExportDataService {
  fileTypes = {
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.ms-excel',
    pdf: 'application/pdf',
  };

  download(blobContent: any, fileName: string, extension: string) {
    const mimeType = this.fileTypes[extension];
    const blob = new Blob([blobContent], {
      type: mimeType,
    });

    if (window.navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, `${fileName}.${extension}`);
    } else {
      const link = document.createElement('a');
      link.style.display = 'none';

      document.body.appendChild(link);

      if (link.download !== undefined) {
        link.setAttribute('href', URL.createObjectURL(blob));
        link.setAttribute('download', `${fileName}.${extension}`);
        link.click();
      } else {
        blobContent = `data:${mimeType},${blobContent}`;
        window.open(encodeURI(blobContent));
      }
      document.body.removeChild(link);
    }
  }
}
