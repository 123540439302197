import * as _ from 'lodash';
import * as XLSX from 'xlsx';
import { Injectable } from '@angular/core';

@Injectable()
export class ExportExcelService {
  defaultWorksheetName = 'Data';

  createWorkBook(): XLSX.WorkBook {
    return {
      SheetNames: [],
      Sheets: {},
    };
  }

  async getBlob(params: {
    mapOptions: IExportDataTableMap,
    records: any,
  }) {
    const rows = [];

    const header = [];
    _.forEach(params.mapOptions, (mapOption, field) => {
      const headerLabel = _.get(mapOption, 'header', field);
      header.push(headerLabel);
    });
    rows.push(header);

    params.records.forEach(record => {
      const targetRecord = {};
      _.forEach(params.mapOptions, (mapOption, field) => {
        let fieldContent = _.get(record, field, mapOption.emptyPlaceholder || '-');
        if (_.get(mapOption, 'formatter')) {
          fieldContent = mapOption.formatter(fieldContent, record);
        }
        targetRecord[field] = fieldContent;
      });
      rows.push(targetRecord);
    });

    const worksheet = this.transformRecordsToWorksheet(rows);

    const workbook = this.createWorkBook();
    workbook.SheetNames.push(this.defaultWorksheetName);
    workbook.Sheets[this.defaultWorksheetName] = worksheet;

    const workbookOut = XLSX.write(workbook, { bookType: 'xlsx', bookSST: true, type: 'binary' });
    return this.workbookToBlob(workbookOut);
  }

  transformRecordsToWorksheet(records: any[][]) {
    const ws = {};
    const range = { s: { c: 10000000, r: 10000000 }, e: { c: 0, r: 0 } };
    for (let R = 0; R !== records.length; ++R) {
      let colIndex = 0;
      _.forEach(records[R], (colValue, colField) => {
        const C = colIndex;

        colIndex++;

        if (range.s.r > R) {
          range.s.r = R;
        }
        if (range.s.c > C) {
          range.s.c = C;
        }
        if (range.e.r < R) {
          range.e.r = R;
        }
        if (range.e.c < C) {
          range.e.c = C;
        }

        if (!colValue) {
          return;
        }

        const cell: any = { v: colValue };
        const cell_ref = XLSX.utils.encode_cell({ c: C, r: R });

        if (typeof cell.v === 'number') {
          cell.t = 'n';
        } else if (typeof cell.v === 'boolean') {
          cell.t = 'b';
        } else if (cell.v instanceof Date) {
          cell.t = 'n';
          cell.z = (<any>XLSX).SSF._table[15];
          cell.v = this.excelDatenum(cell.v);
        } else {
          cell.t = 's';
        }

        ws[cell_ref] = cell;
      });
    }
    if (range.s.c < 10000000) {
      ws['!ref'] = XLSX.utils.encode_range(range);
    }
    return ws;
  }

  excelDatenum(v, date1904: boolean = false) {
    if (date1904) {
      v += 1462;
    }
    const epoch = Date.parse(v);
    return (epoch - <any>new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
  }

  workbookToBlob(wokrbook) {
    let buf: any;
    if (typeof ArrayBuffer !== 'undefined') {
      buf = new ArrayBuffer(wokrbook.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== wokrbook.length; ++i) {
        view[i] = wokrbook.charCodeAt(i) & 0xFF;
      }
      return buf;
    } else {
      buf = new Array(wokrbook.length);
      for (let i = 0; i !== wokrbook.length; ++i) {
        buf[i] = wokrbook.charCodeAt(i) & 0xFF;
      }
      return buf;
    }
  }
}
