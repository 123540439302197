import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ListboxModule } from 'primeng/listbox';
import { TableModule } from 'primeng/table';
import { FormgenModule } from '../../libraries/formgen/formgen.module';
import { LibrariesModule } from '../../libraries/libraries.module';
import { SharedModule } from '../../libraries/shared.module';
import { SPKBatteryRentListComponent } from './list/spk-battery-rent-list.component';
import { SPKBatteryRentRestService } from './spk-battery-rent-rest.service';
import { SPKBatteryRentRoutingModule } from './spk-battery-rent-routing.module';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { ChipComponent } from './components/chip/chip.component';
import { DeliveryDateModalComponent } from './delivery-date-modal/delivery-date-modal.component';
import { ModalComponent } from './components/modal/modal.component';
import { InvoiceModalComponent } from './invoice-modal/invoice-modal.component';
import { SubmissionModalComponent } from './submission-modal/submission-modal.component';
import { DetailModalComponent } from './detail-modal/detail-modal.component';
import { StatusInfoModal } from './status-info-modal/status-info-modal.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { ChangeStatusModalComponent } from './change-status-modal/change-status-modal.component';

export const PROVIDERS = [SPKBatteryRentRestService];

@NgModule({
  imports: [
    SPKBatteryRentRoutingModule,
    BsDropdownModule,
    CommonModule,
    FlexLayoutModule,
    FormgenModule,
    FormsModule,
    ListboxModule,
    LibrariesModule,
    PaginationModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    TableModule,
    TranslateModule,
    DialogModule,
    ButtonModule,
  ],
  declarations: [
    SPKBatteryRentListComponent,
    ChipComponent,
    ModalComponent,
    DeliveryDateModalComponent,
    InvoiceModalComponent,
    SubmissionModalComponent,
    DetailModalComponent,
    StatusInfoModal,
    AccordionComponent,
    ChangeStatusModalComponent
  ]
})
export class SPKBatteryRentModule {}
