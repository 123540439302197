import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import Module from 'module';

@Component({
  selector: 'app-img-upload-display',
  exportAs: 'app-img-upload-display',
  templateUrl: './img-upload-display.component.html',
})
export class ImgUploadDisplayComponent {
  @Input() imageSrc: string;
  @ViewChild('image', { static: false }) elImage: ElementRef;

  imagePlaceholder = (require('../img-upload-placeholder.png') as any).default;
}
