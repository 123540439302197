import { ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import * as _ from 'lodash';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Table } from 'primeng/table';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, switchMap, tap, zip } from 'rxjs/operators';
import { InjectorService } from '../../../libraries/common/injector.service';
import { ExportDataTableService } from '../../../libraries/export/export-data-table.service';
import { GridTableDataSource } from '../../../libraries/grid-table/grid-table-config.model';
import { GridTableFilterService } from '../../../libraries/grid-table/grid-table-filter.service';
import { GridTableToggleService } from '../../../libraries/grid-table/grid-table-toggle.service';
import { GridTableService } from '../../../libraries/grid-table/grid-table.service';
import { ApiQueryOption } from '../../../libraries/http/api-query-option.model';
import { BaseCompBComponent } from '../comp/base-comp.bcomponent';

export class BaseListBComponent<T> extends BaseCompBComponent {
  @ViewChild(Table, { static: false }) public gridTable: Table;
  @ViewChild('dataContainer', { read: ElementRef, static: false }) public elDataContainer: ElementRef;
  @ViewChild('popMoreFilter', { static: false }) elPopMoreFilter: PopoverDirective;
  @ViewChildren('dataCollection') elDataCollection: QueryList<ElementRef>;

  public _gridTable = InjectorService.get<GridTableService>(GridTableService);
  public _dataTableExport = InjectorService.get<ExportDataTableService>(ExportDataTableService);
  public _gridTableFilter = InjectorService.get<GridTableFilterService>(GridTableFilterService);
  public _gridTableToggle = InjectorService.get<GridTableToggleService>(GridTableToggleService);

  private loadDataTimeoutId: number;
  public gridFirstLoad: boolean = true;

  tableColumns: IGridTableColumn[] = [];
  tableColumnsToggle: any;

  gridDataSource: GridTableDataSource<T> = new GridTableDataSource<T>();
  moreFilterValues: IGridTableFilterParsed;
  rawMoreFilterValues: any = {};
  qParams: any = {
    keyword: null,
  };
  selectedRecord: T;
  selectedRecordIndex: number;
  selectedRecords: T[] = [];
  selectedAll: any = [];
  _selectedRecordIds: string[] = [];
  recordsExport: any[] = [];

  pdfOptions = {
    pageOrientation: 'potrait'
  };

  useGridTableLoading: boolean = true;

  get selectedRecordIds() {
    return this._selectedRecordIds;
  }
  set selectedRecordIds(selectedRecordIds: string[]) {
    this._selectedRecordIds = selectedRecordIds;
    this.selectedRecords = this.gridDataSource.data.filter(o => this._selectedRecordIds.includes((o as any).id));
    this.selectedAll = this.selectedRecords.length < this.gridDataSource.data.length ? [] : false;
  }

  exportFileName: string = 'export-data';
  exportDocumentTitle: string = 'Export Data';
  totalRecord: number;
  totalSkipLimitExport: number = 0;
  defaultTotalLimitExport: number = this._dataTableExport.exportLimit;
  defaultTotalSkipLimitExport: number = this._dataTableExport.skipLimit;
  exportStatus: boolean;
  relatedData: any = {};

  moreFilterShown: boolean = false;

  scrollToTopAfterLoaded: boolean = false;

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'workflowLoadData',
      handle: () => {
        return this.hookLoadDataObservable();
      }
    });
  }

  hookLoadDataObservable() {
    let obs: Observable<any>;

    if (this.hasHook('loadRelatedData') && this.hasHook('loadData')) {
      obs = this.loadDataTableWithRelatedDataObservable();
    } else if (this.hasHook('loadData')) {
      obs = this.loadDataTableObservable();
    }

    let loadingContainer;
    if (this.useGridTableLoading) {
      if (this.gridTable) {
        loadingContainer = this.spinnerService.show({
          element: this.gridTable.el.nativeElement,
          // blockElementDisplay: true,
        });
      } else if (this.elDataContainer) {
        loadingContainer = this.spinnerService.show({
          element: this.elDataContainer.nativeElement,
          blockElementDisplay: true,
        });
      } else {
        loadingContainer = this.spinnerService.showDefault();
      }


    }

    return obs.pipe(
      finalize(() => {
        if (this.useGridTableLoading) {
          loadingContainer.dispose();
        }

        this.onDataLoaded();

        if (this.scrollToTopAfterLoaded) {
          $('main.main').animate({
            scrollTop: $('main.main').offset().top,
          }, 500);
        }

        this.scrollToTopAfterLoaded = false;
      })
    );
  }

  onDataLoaded() {
    this.selectedAll = [];
    this._selectedRecordIds = [];
    this.selectedRecords = [];
    this.selectedRecordIds = [];
  }

  loadDataTableWithRelatedDataObservable() {
    return of(
      zip(
      this.loadRelatedDataObservable(),
      this.loadDataTableObservable(),
    ));
  }

  loadDataTableObservable() {
    return this.loadDataObservable()
      .pipe(
        tap(response => {
          this.gridDataSource.updateFromApiPaginationResult(response);
        })
      );
  }

  loadRelatedDataObservable() {
    return this.callHook('loadRelatedData')
      .pipe(
        tap(relatedData => {
          this.relatedData = relatedData;
        })
      );
  }

  loadDataObservable(qOption: ApiQueryOption = this.getDefaultQueryOption(), qParams: any = {}) {
    return this.callHook('loadData', {
      qOption,
    });
  }

  loadSingleDataObservable(loadParameters: any = {}) {
    return this.callHook('loadSingleData', loadParameters);
  }

  getDefaultQueryOption() {
    return this._gridTable.generateApiQueryOptionFromGridInfo(
      this.gridDataSource.pager,
      true,
      this.gridTable,
    );
  }

  gridLoadDataWrapper() {
    // Grid always load at the first time. we dont want that. we already call on ngOnInit
    if (this.gridFirstLoad) {
      this.gridFirstLoad = false;
      return;
    }

    if (this.loadDataTimeoutId) {
      window.clearTimeout(this.loadDataTimeoutId);
      this.loadDataTimeoutId = null;
    }

    this.loadDataTimeoutId = window.setTimeout(() => {
      this.callHookDirectly('workflowLoadData');
    }, 300);
  }

  public onGridChange() {
    this.gridLoadDataWrapper();
  }

  public onGridPageChange() {
    this.scrollToTopAfterLoaded = true;
    this.gridLoadDataWrapper();
  }

  public onMoreFilter(filters: IGridTableFilterParsed) {
    if (this.elPopMoreFilter) {
      this.elPopMoreFilter.hide();
    }

    this.moreFilterValues = filters;
    this.callHookDirectly('workflowLoadData');
  }

  public onTableColumnsChange($event) {
    const disabledColumns = _.differenceBy(this.tableColumns, $event.value, 'field');
    disabledColumns.forEach(disabledColumn => {
      const tableColumn = _.find(this.tableColumns, { field: disabledColumn.field });
      if (tableColumn && !tableColumn.hidden) {
        tableColumn.hidden = true;
      }
    });

    const enabledColumns = $event.value;
    enabledColumns.forEach(enabledColumn => {
      const tableColumn: any = _.find(this.tableColumns, { field: enabledColumn.field });
      if (tableColumn && tableColumn.hidden) {
        tableColumn.hidden = false;
      }
    });
  }

  get exportRecords(): Observable<T[]> {
    const qOption = {
      ...this._gridTable.generateApiQueryOptionFromGridInfo(
        this.gridDataSource.pager,
        false,
        this.gridTable,
      ),
      skip: this._dataTableExport.skipLimit,
      take: this._dataTableExport.exportLimit,
    };

    return this.loadDataObservable(qOption).pipe(switchMap(response => of(response.data)));
  }

  exportPDF() {
    this.exportRecords.subscribe(records => {
      this._dataTableExport.export({
        records,
        mapOptions: this._dataTableExport.mapFromTableColumns(this.tableColumns),
        fileName: this.exportFileName,
        extension: 'pdf',
        pdfOptions: this.pdfOptions,
        templateData: {
          title: this.comp._translate.instant(this.exportDocumentTitle),
        },
      });
    });
  }

  exportExcel() {
    this.exportRecords.subscribe(exportedRecords => {
      this._dataTableExport.export({
        records: exportedRecords,
        mapOptions: this._dataTableExport.mapFromTableColumns(this.tableColumns),
        fileName: this.exportFileName,
        extension: 'xls'
      });
    });
  }

  processLoopExport(type) {
    this.comp.compLoadingService.setLoadingState(true);
    this.comp.compLoadingService.configStretchToMain = true;
    this.comp._changeDetectorRef.detectChanges();
    this.comp.elCompLoading.setTitle(this.comp._translate.instant('ui.spinner.export'));
    this.comp.elCompLoading.setProgress({
      percentage: 0,
    });
    this._dataTableExport.exportLimit = this.totalRecord;
    this.exportStatus = true;
    this.gettingRecordsLoopExport(type);
  }

  gettingRecordsLoopExport(type: string) {

    return this.exportRecords.pipe(catchError((error) => {
      this.resetExportAttribute();
      return throwError(error);
    })).subscribe(result => {
      this.recordsExport = this.recordsExport.concat(result);
      this.totalSkipLimitExport += result.length;
      if (this.totalSkipLimitExport < this.totalRecord) {
        this._dataTableExport.skipLimit = this.totalSkipLimitExport;
        this.comp.elCompLoading.setProgress({
          percentage: ((this.totalSkipLimitExport / this.totalRecord) * 100),
        });
        this.gettingRecordsLoopExport(type);
      } else {
        this.totalSkipLimitExport = this.totalRecord;
        this.comp.elCompLoading.setProgress({
          percentage: ((this.totalSkipLimitExport / this.totalRecord) * 100),
        });
        this.comp._changeDetectorRef.detectChanges();
        this.comp.elCompLoading.setTitle(this.comp._translate.instant('ui.process.export.compile'));
        setTimeout(() => {
          const self = this;

          this._dataTableExport.export({
            records: this.recordsExport,
            pdfOptions: this.pdfOptions,
            mapOptions: this._dataTableExport.mapFromTableColumns(this.tableColumns),
            fileName: this.exportFileName,
            extension: type,
            templateData: {
              title: this.comp._translate.instant(this.exportDocumentTitle),
            },
          }).then(() => {
            this.comp._globalSystemMessage.log({
              message: this.comp._translate.instant('ui.alert.success.title') + ': ' + this.comp._translate.instant('ui.alert.success.description.export'),
              type: 'success',
              showAs: 'growl',
              showSnackBar: false,
            });
            this.resetExportAttribute();
          });
        }, 2000);

      }
    });
  }

  resetExportAttribute() {
    this.comp.compLoadingService.setLoadingState(false);
    this.comp._changeDetectorRef.detectChanges();
    this.totalSkipLimitExport = 0;
    this._dataTableExport.exportLimit = this.defaultTotalLimitExport;
    this._dataTableExport.skipLimit = this.defaultTotalSkipLimitExport;
    this.exportStatus = false;
    this.recordsExport = [];
  }

  actionDeleteRecord(record: T) {
    return this.deleteRecordObservable(record).subscribe();
  }

  deleteRecordObservable(record: T) {
    return super.showDeleteRecordDialog()
      .pipe(
        switchMap(() => this.deleteRecord(record)),
        tap(() => {
          this.onGridChange();
        })
      )
  }

  actionToggleInactiveRecord(record: T, inactiveState: boolean) {
    this.toggleInactiveRecordObservable(record, inactiveState).subscribe();
  }

  toggleInactiveRecordObservable(record: T, inactiveState: boolean) {
    return super.showToggleInactiveRecordDialog(inactiveState)
      .pipe(
        switchMap(() => this.toggleInactiveRecord(record, inactiveState)),
        tap(() => {
          this.onGridChange();
        })
      );
  }

  showContainerLoading() {
    let loadingContainer;
    if (this.gridTable) {
      loadingContainer = this.spinnerService.show({
        element: this.gridTable.el.nativeElement,
        blockElementDisplay: true,
      });
    } else if (this.elDataContainer) {
      loadingContainer = this.spinnerService.show({
        element: this.elDataContainer.nativeElement,
        blockElementDisplay: true,
      });
    } else {
      loadingContainer = this.spinnerService.showDefault();
    }

    return loadingContainer;
  }

  showDataLoading(dataIndex: number) {
    let loading;

    const arrElDataCollection = this.elDataCollection.toArray();
    if (arrElDataCollection[dataIndex]) {
      loading = this.spinnerService.show({
        element: arrElDataCollection[dataIndex].nativeElement,
        stretchToMain: false,
      });
    } else {
      loading = this.showContainerLoading();
    }

    return loading;
  }

  replaceDataByIndex(dataIndex: number, newData: T = null) {
    if (newData) {
      this.ngZone.run(() => {
        this.gridDataSource.data[dataIndex] = newData;
      });
    }
  }

  removeDataByIndex(dataIndex: number) {
    this.ngZone.run(() => {
      this.gridDataSource.data.splice(dataIndex, 1);
    });
  }

  refreshDataByIndex(dataIndex: number, loadParameters: any = {}) {
    const obs = this.loadSingleDataObservable(loadParameters);
    return obs.pipe(tap(loadedRecord => {
      this.replaceDataByIndex(dataIndex, loadedRecord);
    }));
  }

  refreshDataByIndexWithSpinner(dataIndex: number, loadParameters: any = {}) {
    const loading = this.showDataLoading(dataIndex);

    return this.refreshDataByIndex(dataIndex, loadParameters).pipe(finalize(() => {
      loading.dispose();
    })).subscribe();
  }

  refreshSelectedRecord() {
    this.refreshDataByIndexWithSpinner(this.selectedRecordIndex, { id: (this.selectedRecord as any).id });
  }

  onSelectAll() {
    if (this.selectedRecordIds.length < this.gridDataSource.data.length && this.selectedAll.length > 0) {
      this.selectedRecordIds = _.map(this.gridDataSource.data, 'id');
      this.selectedRecords = this.gridDataSource.data.filter(o => this._selectedRecordIds.includes((o as any).id));
    } else {
      this.selectedRecordIds = [];
      this.selectedRecords = [];
    }
  }
}

