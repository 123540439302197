import { Component } from '@angular/core';

@Component({
  selector: 'app-status-info-modal',
  templateUrl: 'status-info-modal.component.html',
  styleUrls: ['../buyback.component.scss']
  // styleUrls: ['status-info-modal.component.scss']
})
export class StatusInfoModal {
  statusList = [
    {
      title: "Draft",
      description: "Dokumen masih belum lengkap dan belum disubmit."
    },
    {
      title: "On Progress",
      description: ""
    },
    {
      title: "Completed",
      description: ""
    },
    {
      title: "Expired",
      description: "Dokumen sudah disubmit dan disetujui, namun belum ada konfirmasi kembali dari MD untuk mengupload dokumen appraisal selama 7 hari."
    }
  ] 

  isOpen: boolean = false;

  openModal() {
    this.isOpen = true;
  }

  closeModal() {
    this.isOpen = false;
  }
}
