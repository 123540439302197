
import { forkJoin, throwError, zip, from, of, BehaviorSubject, Observable, Subscription, interval } from 'rxjs';
import { concatMapTo, take, filter, tap, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { EventService } from './event.service';
import { MHookEventBeforeCall, MHookEventFailed, MHookEventSuccess, MHookItem } from './hook.model';

export class HookBService<THookItem extends MHookItem> {
  eventService = new EventService;
  hooks: THookItem[] = [];
  hookBeforeCallEventStream$: BehaviorSubject<MHookEventBeforeCall<THookItem>> = new BehaviorSubject(null);
  hookBeforeSuccessEventStream$: BehaviorSubject<MHookEventSuccess<THookItem>> = new BehaviorSubject(null);
  hookSuccessEventStream$: BehaviorSubject<MHookEventSuccess<THookItem>> = new BehaviorSubject(null);
  hookFailedEventStream$: BehaviorSubject<MHookEventFailed<THookItem>> = new BehaviorSubject(null);

  registerHook(targetHook: THookItem) {
    const hookIndex = this.hooks.findIndex(hook => hook.hookId === targetHook.hookId);
    if (hookIndex > -1) {
      this.hooks.splice(hookIndex, 1);
    }
    this.hooks.push(targetHook);
  }

  hasHook(hookId: string): boolean {
    return Boolean(this.getHook(hookId));
  }

  hasHookByName(hookName: string): boolean {
    return Boolean(this.getHook(hookName));
  }

  getHook(hookId: string): THookItem {
    return this.hooks.find(hook => hook.hookId === hookId);
  }

  getHookAndWaitHookReady(hookId: string, waitTimeoutMs: number = 0): Observable<THookItem> {
    return new Observable(observer => {
      let subscriptionInterval: Subscription;
      const complete = () => {
        subscriptionInterval.unsubscribe();
        observer.complete();
      };

      subscriptionInterval = interval(100)
        .pipe(
          tap(() => {
            const targetHook = this.getHook(hookId);
            if (targetHook) {
              observer.next(targetHook);
              complete();
            }
          })
        )
        .subscribe();

      if (waitTimeoutMs > 0) {
        setTimeout(() => {
          console.error(`TIMEOUT FOR WAITING HOOK ID ${hookId} ${waitTimeoutMs} READY!!`);
          complete();
        }, waitTimeoutMs);
      }
    });
  }

  getHookByName(hookName: string): THookItem {
    return this.hooks.find(hook => hook.hookName === hookName);
  }

  getHookByNameAndWaitHookReady(hookName: string, waitTimeoutMs: number = 0): Observable<THookItem> {
    return new Observable(observer => {
      let subscriptionInterval: Subscription;
      const complete = () => {
        subscriptionInterval.unsubscribe();
        observer.complete();
      };

      subscriptionInterval = interval(100)
        .pipe(
          tap(() => {
            const targetHook = this.getHookByName(hookName);
            if (targetHook) {
              observer.next(targetHook);
              complete();
            }
          })
        )

        .subscribe();

      if (waitTimeoutMs > 0) {
        setTimeout(() => {
          console.error(`TIMEOUT FOR WAITING HOOK NAME ${hookName} ${waitTimeoutMs} READY!!`);
          complete();
        }, waitTimeoutMs);
      }
    });
  }

  callHookDirectly(hookId: string, hookData?: any): Subscription {
    const subscription = this.callHook(hookId, hookData).subscribe();
    return subscription;
  }

  callHookSafeDirectly(hookId: string, hookData?: any): Subscription {
    const subscription = this.callHookSafe(hookId, hookData).subscribe();
    return subscription;
  }

  callHookDirectlyByName(hookName: string, hookData?: any): Subscription {
    const subscription = this.callHookByName(hookName, hookData).subscribe();
    return subscription;
  }

  callHookSafeDirectlyByName(hookName: string, hookData?: any): Subscription {
    const subscription = this.callHookSafeByName(hookName, hookData).subscribe();
    return subscription;
  }

  callHook(hookId: string, hookData?: any): Observable<any> {
    const hook = this.getHook(hookId);
    if (!hook) {
      throw new Error(`Cannot call hook id ${hookId}, hook is not available`);
    }

    return this.callHookHandle(hookId, hookData);
  }

  callHookSafe(hookId: string, hookData?: any): Observable<any> {
    const hook = this.getHook(hookId);
    if (!hook) {
      return of(null);
    }

    return this.callHookHandle(hookId, hookData);
  }

  callHookAndWaitHookReady(hookId: string, hookData?: any, waitTimeoutMs: number = 0): Observable<any> {
    return this.getHookAndWaitHookReady(hookId, waitTimeoutMs).pipe(
      switchMap(hook => this.callHook(hookId, hookData))
    );
  }

  callHookByName(hookName: string, hookData?: any): Observable<any> {
    const hook = this.getHookByName(hookName);
    if (!hook) {
      throw new Error(`Cannot call hook name ${hookName}, hook is not available`);
    }

    return this.callHookSafe(hook.hookId, hookData);
  }

  callHookByNameAndWaitHookReady(hookName: string, hookData?: any, waitTimeoutMs: number = 0): Observable<any> {
    return this.getHookByNameAndWaitHookReady(hookName, waitTimeoutMs).pipe(
      switchMap(hook => this.callHookByName(hookName, hookData))
    );
  }

  callHookSafeByName(hookName: string, hookData?: any): Observable<any> {
    const hook = this.getHookByName(hookName);
    if (!hook) {
      return of(null);
    }

    return this.callHookSafe(hook.hookId, hookData);
  }

  callHookHandle(hookId: string, hookData?: any): Observable<any> {
    return new Observable(observer => {
      const hook = this.getHook(hookId);

      const complete = (handleSubscription?: Subscription) => {
        if (handleSubscription) {
          handleSubscription.unsubscribe();
        }
        if (hook && hook.eventSubscription) {
          hook.eventSubscription.unsubscribe();
        }
        observer.complete();
      };

      if (hook) {
        if (hook.eventSubscription) {
          hook.eventSubscription.unsubscribe();
        }

        // listen for a hook is being called from callHook and then do the generic data processing
        hook.eventSubscription = this.eventService.listen(hookId).subscribe(event => {
          let handle;

          try {
            this.hookBeforeCallEventStream$.next({ hook, event });

            handle = hook.handle(event);

            if (_.has(handle, 'then')) {
              handle = from(handle);
            }

            if (handle instanceof Observable) {
              const obs = handle.subscribe(data => {
                if (hook.preSuccess) {
                  hook.preSuccess(data);
                }

                this.hookBeforeSuccessEventStream$.next({ hook, event, result: data });

                observer.next(data);

                if (hook.success) {
                  hook.success(data);
                }

                this.hookSuccessEventStream$.next({ hook, event, result: data });

                complete(obs);
              }, error => {
                if (hook.preError) {
                  hook.preError(error);
                }

                observer.error(error);

                if (hook.error) {
                  hook.error(error);
                }

                this.hookFailedEventStream$.next({ hook, event, error });

                complete(obs);
              });
            } else {
              if (hook.preSuccess) {
                hook.preSuccess(handle);
              }

              this.hookBeforeSuccessEventStream$.next({ hook, event, result: handle });

              observer.next(handle);

              if (hook.success) {
                hook.success(handle);
              }

              this.hookSuccessEventStream$.next({ hook, event, result: handle });

              complete();
            }
          } catch (error) {
            if (hook.preError) {
              hook.preError(error);
            }

            console.error(error);

            if (hook.error) {
              hook.error(error);
            }

            this.hookFailedEventStream$.next({ hook, event, error });

            complete();
          }
        });

        this.eventService.emit(hookId, hookData);
      } else {
        complete();
      }
    });
  }

  getHookCall(hookId: string, wait: boolean = false): Observable<any> {
    if (wait) {
      return this.callHookAndWaitHookReady(hookId);
    }

    if (this.hasHook(hookId)) {
      return this.callHookSafe(hookId);
    } else {
      console.error(`CANNOT FIND HOOK ID FOR ${hookId} WHEN CALLING MULTIPLE HOOKS`);
      return;
    }
  }

  getHookCallByName(hookName: string, wait: boolean = false): Observable<any> {
    if (wait) {
      return this.callHookByNameAndWaitHookReady(hookName);
    }

    if (this.hasHook(hookName)) {
      return this.callHookByName(hookName);
    } else {
      console.error(`CANNOT FIND HOOK NAME FOR ${hookName} WHEN CALLING MULTIPLE HOOKS`);
      return;
    }
  }

  callMultipleHooks(hookIds: any, wait: boolean = false): Observable<any> {
    const isHookIdContainsComponentName = (hookId: string) => {
      return hookId.indexOf(':') > -1;
    };

    const hooksCall: Observable<any>[] = [];
    _.forEach(hookIds, hookIdPack => {
      let hookCall: Observable<any>;
      if (_.isArray(hookIdPack)) {
        const hookCallObservables: Observable<any>[] = [];
        _.forEach(hookIdPack, hookId => {
          let hookCallObservable: Observable<any>;
          if (isHookIdContainsComponentName(hookId)) {
            hookCallObservable = this.getHookCall(hookId, wait);
          } else {
            hookCallObservable = this.getHookCallByName(hookId, wait);
          }
          if (hookCallObservable) {
            hookCallObservables.push(hookCallObservable);
          }
        });
        if (hookCallObservables.length) {
          hookCall = zip(...hookCallObservables);
        }
      } else {
        const hookId = hookIdPack as string;
        if (isHookIdContainsComponentName(hookId)) {
          hookCall = this.getHookCall(hookId, wait);
        } else {
          hookCall = this.getHookCallByName(hookId, wait);
        }
      }
      if (hookCall) {
        hooksCall.push(hookCall);
      }
    });

    if (!hooksCall.length) {
      console.error(`FAILED TO CALL MULTIPLE HOOKS FOR`, hookIds);
      return throwError(false);
    }

    let obs = hooksCall[0];
    if (hooksCall.length > 1) {
      for (let i = 1; i < hooksCall.length; i++) {
        obs = obs.pipe(concatMapTo(hooksCall[i]));
      }
    }

    return forkJoin(obs);
  }

  onHookBeforeCallById(hookId: string): Observable<MHookEventBeforeCall<THookItem>> {
    return this.hookBeforeCallEventStream$
      .pipe(
        filter(value => {
          return value && value.hook && value.hook.hookId === hookId;
        }),
        take(1)
      );
  }

  onHookBeforeCallByHookName(hookName: string): Observable<MHookEventBeforeCall<THookItem>> {
    return this.hookBeforeCallEventStream$
      .pipe(
        filter(value => {
          return value && value.hook && value.hook.hookName === hookName;

        }),
        take(1)
      );
  }

  onHookBeforeSuccessById(hookId: string): Observable<MHookEventSuccess<THookItem>> {
    return this.hookBeforeSuccessEventStream$
      .pipe(
        filter(value => {
          return value && value.hook && value.hook.hookId === hookId;
        }),
        take(1)
      );
  }

  onHookBeforeSuccessByHookName(hookName: string): Observable<MHookEventSuccess<THookItem>> {
    return this.hookBeforeSuccessEventStream$
      .pipe(
        filter(value => {
          return value && value.hook && value.hook.hookName === hookName;

        }),
        take(1)
      );
  }

  onHookSuccessById(hookId: string): Observable<MHookEventSuccess<THookItem>> {
    return this.hookSuccessEventStream$.pipe(filter(value => {
      return value && value.hook && value.hook.hookId === hookId;
    }), take(1));
  }

  onHookSuccessByHookName(hookName: string): Observable<MHookEventSuccess<THookItem>> {
    return this.hookSuccessEventStream$.pipe(filter(value => {
      return value && value.hook && value.hook.hookName === hookName;
    }), take(1));
  }

  onHookFailedById(hookId: string): Observable<MHookEventFailed<THookItem>> {
    return this.hookFailedEventStream$.pipe(filter(value => {
      return value && value.hook && value.hook.hookId === hookId;
    }), take(1));
  }

  onHookFailedByHookName(hookName: string): Observable<MHookEventFailed<THookItem>> {
    return this.hookFailedEventStream$.pipe(filter(value => {
      return value && value.hook && value.hook.hookName === hookName;
    }), take(1));
  }
}
