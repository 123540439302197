import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { AlertModule } from 'ngx-bootstrap/alert';
import { LibrariesModule } from '../libraries.module';
import { SystemMessageDisplayBlockComponent } from './block/system-message-display-block.component';
import { SystemMessageDisplayComponent } from './system-message-display.component';


@NgModule({
  imports: [
    AlertModule,
    CommonModule,
    FlexLayoutModule,
    LibrariesModule,
    TranslateModule,
  ],
  declarations: [
    SystemMessageDisplayBlockComponent,
    SystemMessageDisplayComponent,
  ],
  exports: [
    SystemMessageDisplayComponent,
  ]
})
export class SystemMessageDisplayModule { }
