import { AfterContentInit, Component, ContentChildren, Input, QueryList, TemplateRef } from '@angular/core';
import { ViewUtilityTemplateDirective } from '../../../libraries/view-utility/view-utility-template.directive';
import { BaseEntryBComponent } from './base-entry.bcomponent';

@Component({
  selector: 'app-base-entry',
  templateUrl: './base-entry.component.html',
})
export class BaseEntryComponent implements AfterContentInit {
  @Input() component: BaseEntryBComponent;
  @ContentChildren(ViewUtilityTemplateDirective) cTemplates: QueryList<ViewUtilityTemplateDirective>;

  @Input() contentTemplate: TemplateRef<any>;
  @Input() footerTemplate: TemplateRef<any>;

  ngAfterContentInit() {
    this.cTemplates.forEach(cTemplate => {
      switch (cTemplate.getType()) {
        case 'entryFooterTpl':
          this.footerTemplate = cTemplate.template;
          break;
        case 'entryContentTpl':
          this.contentTemplate = cTemplate.template;
          break;
      }
    });
  }
}
