import { Inject, Injectable } from '@angular/core';

@Injectable()
export class LogService {
  constructor(
    @Inject('Rollbar') private rollbar: any
  ) { }

  critical(params: ILog) {
    const currentURL = this.getCurrentURL();
    this.rollbar.critical(params.message, currentURL);
  }

  error(params: ILog) {
    const currentURL = this.getCurrentURL();
    this.rollbar.error(params.message, currentURL);
  }

  info(params: ILog) {
    const currentURL = this.getCurrentURL();
    this.rollbar.info(params.message, currentURL);
  }

  debug(params: ILog) {
    const currentURL = this.getCurrentURL();
    this.rollbar.debug(params.message, currentURL);
  }

  warning(params: ILog) {
    const currentURL = this.getCurrentURL();
    this.rollbar.warning(params.message, currentURL);
  }

  private getCurrentURL() {
    return location.href;
  }
}
