import { Component } from '@angular/core';
import { BaseUpdateBComponent } from '../../../libraries/base/update/base-update.bcomponent';
import { UserRestService } from '../user-rest.service';

@Component({
  selector: 'app-user-reset',
  templateUrl: 'user-reset.component.html'
})
export class UserResetComponent extends BaseUpdateBComponent {
  constructor(private _userRest: UserRestService) {
    super();
    this.componentId = 'UserResetComponent';
    this.routeURL = '/users';
    this.entrySuccessI18n = 'success.user.reset';
    this.headerTitle = 'ui.user.reset.title';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'save',
      handle: () => {
        const formValue = this.form.value;
        delete formValue.fullName;
        delete formValue.email;
        delete formValue.role;
        delete formValue.roleId;

        return this._userRest.reset(this.comp.routeParams.id, formValue);
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService.callMultipleHooks([
          'UserFormComponent:workflowBuildForm',
          'UserUpdateComponent:workflowLoadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }
}
