import { ChangeDetectorRef, Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { SpinnerService } from './spinner.service';

@Directive({
  selector: '[appSpinner]',
  exportAs: 'app-spinner',
})
export class SpinnerDirective implements OnChanges, OnInit {
  @Input() parent: HTMLElement;
  @Input() show: boolean = false;
  @Input() stretchToMain: boolean = true;
  @Input() fixedPosition: boolean = false;
  @Input() loaderType: number = 0;

  initialized: boolean = false;

  currentSpinner: any;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _elementRef: ElementRef,
    private _spinner: SpinnerService,
  ) { }

  ngOnInit() {
    if (this.show) {
      this.toggle();
    }

    this.initialized = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.initialized) {
      if (_.has(changes, 'show') && changes.show.currentValue !== changes.show.previousValue) {
        this.toggle();
      }
    }
  }

  setTitle(...parameters) {
    if (this.currentSpinner) {
      this.currentSpinner.setTitle(...parameters);
    }
  }

  unsetTitle() {
    if (this.currentSpinner) {
      this.currentSpinner.unsetTitle();
    }
  }

  setProgress(...parameters) {
    if (this.currentSpinner) {
      this.currentSpinner.setProgress(...parameters);
    }
  }

  unsetProgress() {
    if (this.currentSpinner) {
      this.currentSpinner.unsetProgress();
    }
  }

  setButton(...parameters) {
    if (this.currentSpinner) {
      this.currentSpinner.setButton(...parameters);
    }
  }

  unsetButton() {
    if (this.currentSpinner) {
      this.currentSpinner.unsetButton();
    }
  }

  get elParent() {
    return this.parent ? this.parent : this._elementRef.nativeElement;
  }

  toggle() {
    if (this.show) {
      this.forceShow();
    } else {
      this.forceHide();
    }

    this._changeDetectorRef.detectChanges();
  }

  forceShow() {
    this.currentSpinner = this._spinner.show({
      element: this.elParent,
      stretchToMain: this.stretchToMain,
      fixedSpinnerPosition: this.fixedPosition,
      loaderType: this.loaderType
    });
  }

  forceHide() {
    if (this.currentSpinner) {
      this.currentSpinner.dispose();
      this.currentSpinner = undefined;
    }
  }
}
