import { Injectable } from '@angular/core';
import { Headers, Http, ResponseContentType } from '@angular/http';
import { Observable, Subscriber } from 'rxjs';


import { AuthenticationService } from '../auth/authentication.service';

// ref: http://blog.jsgoupil.com/request-image-files-with-angular-2-and-an-bearer-access-token/

@Injectable()
export class HttpHelperService {

  constructor(
    private _http: Http,
    private _authentication: AuthenticationService) { }

  transformSecureBlobUrl(url: string): Observable<string> {
    return new Observable((observer: Subscriber<string>) => {
      let objectUrl: string = null;
      const headers: Headers = this._authentication.setAuthHeader(new Headers());

      this._http
        .get(url, {
          headers: headers,
          responseType: ResponseContentType.Blob
        })
        .subscribe(m => {
          objectUrl = URL.createObjectURL(m.blob());
          observer.next(objectUrl);
        });

      return () => {
        if (objectUrl) {
          URL.revokeObjectURL(objectUrl);
          objectUrl = null;
        }
      };
    });
  }

}
