import { NgModule } from '@angular/core';
import { RenderComponentClassComponent } from './render-component-class.component';

@NgModule({
  declarations: [
    RenderComponentClassComponent,
  ],
  exports: [
    RenderComponentClassComponent,
  ],
})
export class RenderComponentClassModule { }
