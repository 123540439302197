import { Component } from '@angular/core';
import { BaseFormBComponent } from '../../../libraries/base/form/base-form.bcomponent';

@Component({
  selector: 'app-news-categories-form',
  templateUrl: 'news-categories-form.component.html'
})
export class NewsFormComponent extends BaseFormBComponent {
  constructor() {
    super();

    this.componentId = 'NewsCategoriesFormComponent';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'buildForm',
      handle: () => {
        this.formgenSchema.fields = [
          {
            label: 'ui.categories.entity.name',
            name: 'name',
            field: 'text',
            validations: {
              required: true
            }
          }
        ];
      }
    });
  }
}
