import { Component } from '@angular/core';

@Component({
  selector: 'app-status-info-modal',
  templateUrl: 'status-info-modal.component.html',
  styleUrls: ['../list/battery-rent-list.component.scss']
})
export class StatusInfoModal {
  statusList = [
    {
      title: "Waiting For Pairing",
      description: "Baterai menunggu untuk di-pairing."
    },
    {
      title: "Renting",
      description: "Baterai sedang disewa dan terpairing pada motor pengguna."
    },
    {
      title: "Replacement",
      description: "Baterai melakukan penukaran."
    },
    {
      title: "Stop Rent",
      description: "Baterai berhenti disewa."
    }
  ] 

  isOpen: boolean = false;

  openModal() {
    this.isOpen = true;
  }

  closeModal() {
    this.isOpen = false;
  }
}
