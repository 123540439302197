import { Component, Input } from '@angular/core';

import { BaseEntryBComponent } from '../base-entry.bcomponent';

@Component({
  selector: 'app-base-entry-footer',
  templateUrl: './base-entry-footer.component.html',
})
export class BaseEntryFooterComponent {
  @Input() component: BaseEntryBComponent;
}
