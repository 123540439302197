import { Component, Output, EventEmitter } from '@angular/core';
import { BaseCreateBComponent } from '../../../libraries/base/create/base-create.bcomponent';
import { LocationRestService } from '../location-rest.service';

@Component({
  selector: 'app-location-create',
  templateUrl: 'location-create.component.html'
})
export class LocationCreateComponent extends BaseCreateBComponent {
  @Output() success: EventEmitter<boolean> = new EventEmitter();

  constructor(private _locationRest: LocationRestService) {
    super();
    this.componentId = 'LocationCreateComponent';
    this.routeURL = '/location';
    this.entrySuccessI18n = 'success.location.create';
    this.headerTitle = 'ui.location.create.title';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'save',
      handle: () => {
        const formValue = this.form.value;

        return this._locationRest.create(formValue);
      },
      success: () => {
        this.success.emit(true);
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService.callMultipleHooks([
          'LocationFormComponent:workflowBuildForm'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }
}
