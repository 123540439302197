import { Component } from '@angular/core';
import { BaseUpdateBComponent } from '../../../libraries/base/update/base-update.bcomponent';
import { FaqRestService } from '../faq-rest.service';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-faq-update',
  templateUrl: 'faq-update.component.html'
})
export class FaqUpdateComponent extends BaseUpdateBComponent {
  constructor(private _faqRest: FaqRestService) {
    super();
    this.componentId = 'FaqUpdateComponent';
    this.routeURL = '/faq';
    this.entrySuccessI18n = 'success.faq.update';
    this.headerTitle = 'ui.faq.update.title';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'load',
      handle: () => {
        return this._faqRest.load(this.comp.routeParams.id).pipe(
          switchMap(doc => {
            doc.password = null;

            return of(doc);
          })
        );
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'save',
      handle: () => {
        const formValue = this.form.value;

        return this._faqRest.update(this.comp.routeParams.id, formValue);
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService.callMultipleHooks([
          'FaqFormComponent:workflowBuildForm',
          'FaqUpdateComponent:workflowLoadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }
}
