import { Injectable } from '@angular/core';
import { APP_CONST } from '../../app.const';
import { RequestService } from './request.service';

@Injectable()
export class ApiFileRestService {
  requestUrl = this._request.new(`${APP_CONST.API_FILE}`);

  constructor(
    private _request: RequestService,
  ) { }

  uploadSingle(data: any) {
    return this.requestUrl.post<any>(`upload-single`, data);
  }
}
