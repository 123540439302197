import { NgModule } from '@angular/core';

import { SpinnerDirective } from './spinner.directive';

import { SpinnerService } from './spinner.service';

export const PROVIDERS = [
  SpinnerService,
];

@NgModule({
  declarations: [
    SpinnerDirective,
  ],
  exports: [
    SpinnerDirective,
  ],
})
export class SpinnerModule { }

export * from './spinner.service';
