import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appFCTitle]'
})
export class FieldsetCollapsibleDirective {
  contentSelector: string = '.fieldset-content';
  slideDuration: number = 500;
  sliding: boolean = false;

  constructor(
    private _element: ElementRef
  ) { }

  @HostListener('click') onclick() {
    const contentElement = this.findContentElement();
    if (contentElement && !this.sliding) {
      this.sliding = true;
      if (!this.findParentFieldsetElement().hasClass('collapsed')) {
        contentElement.slideUp(this.slideDuration, this.afterSlideUp.bind(this, contentElement));
      } else {
        contentElement.slideDown(this.slideDuration, this.afterSlideDown.bind(this, contentElement));
      }
    }
  }

  private findParentFieldsetElement() {
    return $(this._element.nativeElement).parents('fieldset').eq(0);
  }

  private findContentElement() {
    let el;
    const nextElement = $(this._element.nativeElement).next(this.contentSelector);
    if (nextElement.length) {
      el = nextElement;
    } else {
      const contentElement = this.findParentFieldsetElement().find(this.contentSelector).eq(0);
      if (contentElement.length) {
        el = contentElement;
      }
    }
    return el ? el : false;
  }

  private afterSlideUp(contentElement: any) {
    this.findParentFieldsetElement().addClass('collapsed');
    this.sliding = false;
  }

  private afterSlideDown(contentElement: any) {
    this.findParentFieldsetElement().removeClass('collapsed');
    this.sliding = false;
  }
}
