
import { filter } from 'rxjs/operators';


import { Injectable, OnInit } from '@angular/core';
import { NavigationStart, Router, NavigationEnd } from '@angular/router';

@Injectable()
export class RouteStateService {
  previousUrl: string;
  currentUrl: string;

  constructor(private router: Router) {
    // router.events.pipe(
    //   filter(event => event instanceof NavigationStart))
    //   .subscribe(e => {
    //     this.previousUrl = this.currentUrl;
    //     this.currentUrl = (<any>e).url;

    //     // call simulates a page refresh, causing Intercom to check for any new messages we should deliver to the user
    //     // (<any>window).Intercom('update');
    //   });

    // this.router.events.subscribe(event => {
    //   if ((<any>window).ga && event instanceof NavigationEnd) {
    //     (<any>window).ga('set', 'page', event.urlAfterRedirects);
    //     (<any>window).ga('send', 'pageview');
    //   }
    // });
  }
}
