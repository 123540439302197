import { AfterContentInit, AfterViewInit, Component, ContentChildren, Host, Input, QueryList, TemplateRef, ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
import { FormgenFormComponent } from '../form/formgen-form.component';
import { FormgenTemplateDirective } from '../formgen-template.directive';

@Component({
  selector: 'app-formgen-field',
  templateUrl: './formgen-field.component.html',
})
export class FormgenFieldComponent implements AfterContentInit, AfterViewInit {
  @Input() name: string;
  @ContentChildren(FormgenTemplateDirective) contentFormgenTemplateDirectives: QueryList<FormgenTemplateDirective>;

  providedAvailableTemplates: FormgenTemplateDirective[];
  fieldRenderedTemplate: TemplateRef<any>;
  contentTemplates: { [key: string]: TemplateRef<any> } = {};

  get availableTemplates() {
    return this.providedAvailableTemplates || this.formgenFormComponentHost.vTemplates && this.formgenFormComponentHost.vTemplates.filter(tpl => tpl.name === this.name);
  }

  constructor(
    @Host() public formgenFormComponentHost: FormgenFormComponent,
    private _changeDetectorRef: ChangeDetectorRef
  ) { }

  ngAfterContentInit() {
    this.contentFormgenTemplateDirectives.toArray().forEach(contentFormgenTemplate => {
      this.contentTemplates[contentFormgenTemplate.name] = contentFormgenTemplate.template;
    });
  }

  ngAfterViewInit() {
    this.populateTemplates();

    this.formgenFormComponentHost.vTemplates.changes.subscribe(() => {
      this.populateTemplates();
    });

    this._changeDetectorRef.detectChanges();
  }

  populateTemplates() {
    const targetTemplate = _.find(this.availableTemplates, tpl => tpl.name === this.name);
    if (targetTemplate) {
      this.fieldRenderedTemplate = targetTemplate.template;
    }
  }
}
