import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { Router } from '@angular/router';

import { AuthenticationService } from '../auth/authentication.service';

@Injectable()
export class IdleGuardService {
  @LocalStorage() private _idle: boolean;

  idle$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  private idleTimeout: number = 600;
  private timer: any;

  constructor(
    private _authentication: AuthenticationService,
    private _router: Router,
  ) {
    if (!this.idle) {
      // this.startTimer();
    } else {
      this.idle = false;
      this._router.navigateByUrl('/login');
    }

    const resetTimerHandler = () => {
      if (!this.idle && this._authentication.user) {
        this.resetTimer();
      }
    };

    $(document)
      .mousemove(resetTimerHandler.bind(this))
      .click(resetTimerHandler.bind(this))
      .keydown(resetTimerHandler.bind(this));

    this._authentication.user$.subscribe(user => {
      if (!user) {
        this.idle = false;
        this.destroyTimer();
      }
    });
  }

  set idle(state: boolean) {
    this._idle = state;
    this.idle$.next(state);
  }

  get idle() {
    return this._idle;
  }

  private startTimer() {
    this.timer = setTimeout(() => {
      this.idle = true;
    }, this.idleTimeout * 1000);
  }

  private destroyTimer() {
    clearTimeout(this.timer);
  }

  private resetTimer() {
    this.destroyTimer();
    // this.startTimer();
  }
}
