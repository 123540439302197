
import {finalize, filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Subject, BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompLoadingService {
  loadings: any[] = [];
  loadingsById: any[] = [];
  loadingDone$: Subject<string> = new Subject();
  configStretchToMain = false;

  private loadingShown$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  get isLoadingShown() {
    return this.loadingShown$;
  }

  createLoading(loadingId?: string) {
    const targetArray = loadingId ? this.loadingsById : this.loadings;
    targetArray.push(loadingId);

    this.loadingShown$.next(true);

    return {
      dispose: () => {
        if (_.isString(loadingId)) {
          const loadingIdIndex = _.findIndex(this.loadingsById, id => id === loadingId);
          if (loadingIdIndex > -1) {
            targetArray.splice(loadingIdIndex, 1);
          }

          this.loadingDone$.next(loadingId);
        } else {
          targetArray.splice(0, 1);
        }

        if (!targetArray.length) {
          this.loadingShown$.next(false);
        }
      },
    };
  }

  observableWrapLoading(obs: Observable<any>, loadingId?: string) {
    const loading = this.createLoading(loadingId);
    return obs.pipe(finalize(() => {
      loading.dispose();
    }));
  }

  onLoadingShown() {
    return this.loadingShown$.pipe(filter(state => state === true));
  }

  onLoadingHidden() {
    return this.loadingShown$.pipe(filter(state => state === false));
  }

  setLoadingState(state: boolean) {
    this.loadingShown$.next(state);
  }
}
