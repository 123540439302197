import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable()
export class ConstantService {

  primeNGOptionsYesNo: any = [
    { label: this._translate.instant('ui.option.yes'), value: true },
    { label: this._translate.instant('ui.option.no'), value: false }
  ];

  constructor(
    private _translate: TranslateService
  ) { }

}
