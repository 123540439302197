import { Component, Input } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BuybackListComponent } from "../list/buyback-list.component";
import { CommonService } from "../../../libraries/libraries.module";
import { BuybackRestService } from "../buyback-rest.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-unit-acceptance-modal',
  templateUrl: 'unit-acceptance-modal.component.html',
  styleUrls: ['../buyback.component.scss']
})
export class UnitAcceptanceModalComponent {
  @Input() buyback: any;
  @Input() disabled: boolean;

  constructor(
    private _BuybackRest: BuybackRestService,
    public _buybackListComponent: BuybackListComponent
  ) {
    this.initFormChangeStatus();
  }

  initFormChangeStatus() {
    this.formChangeStatus = new FormGroup({
      statusPenerimaanUnit: new FormControl('', [Validators.required])
    });
  }
  

  isLoading: boolean = false;
  formChangeStatus: FormGroup;

  isUnitAcceptanceModalOpen: boolean = false;

  openUnitAcceptanceModal() {
    this.isUnitAcceptanceModalOpen = true;
  }

  closeUnitAcceptanceModal() {
    this.isUnitAcceptanceModalOpen = false;
  }

    
  onChangeStatusBuyback() {
    if (!this.formChangeStatus.valid) {
      CommonService.markAsDirty(this.formChangeStatus);
    } else {
      Swal.fire({
        title: 'Update Status Penerimaan Unit?',
        text: '',
        icon: 'question',
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.changeStatus();
        }
      });
    }
  }

  async changeStatus() {
    if (!this.formChangeStatus.valid) {
      CommonService.markAsDirty(this.formChangeStatus);
    } else {
      const payload = {
        ...this.formChangeStatus.value,
        _id: this.buyback._id
      };
      this.isLoading = true;

      await this._BuybackRest
        .update(payload)
        .toPromise()
        .then((response: any) => {
          if (!!response?.invalid) {
            Swal.fire({
              icon: 'warning',
              title: 'Change Status Failed',
              confirmButtonText: 'OK'
            });
          } else {
            Swal.fire(
              'Change Status Successfully!',
              '',
              'success'
            )

            this._buybackListComponent.callHookDirectly('init');
            this.closeUnitAcceptanceModal();
          }
        })
        .catch(error => {
          console.log(error)
          Swal.fire(
            'Oops..',
            'Something went wrong!',
            'error'
          )
        });
      this.isLoading = false;
    }
  }
}