import { AbstractControl, AsyncValidatorFn, FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import * as _ from 'lodash';
import { CommonService } from './common.service';

export type MFormDefinition = MFieldDefinition[];

export class MFieldDefinition {
  name: string;

  treePath?: string;
  treeParentPath?: string;
  disabled?: boolean;
  defaultValue?: any;
  isFormArray?: boolean;
  asyncValidators?: AsyncValidatorFn | AsyncValidatorFn[];
  syncValidators?: ValidatorFn | ValidatorFn[];
  children?: MFormDefinition;
  control?: AbstractControl;
  formGroup?: FormGroup;

  controlEvents?: {
    valueChanges?: (newValue: any, form: FormGroup) => void;
  };
}

export class MFormValidationFailed {
  fullControlName: string;
  controlName: string;
  control: AbstractControl;
  errorName: string;
  errorInfo: {
    [key: string]: any;
  };

  pureControlName?: string;
  arrayControlIndex?: number;
  arrayControlNumber?: number;
}

export class MForm extends FormGroup {
  private _formDefinition: MFormDefinition;
  get formDefinition() {
    return this._formDefinition;
  }
  set formDefinition(newFormDefinition: MFormDefinition) {
    CommonService.assignObjectTreePath(newFormDefinition, ['children'], 'name', 'treePath', 'treeParentPath', true);

    this._formDefinition = newFormDefinition;

    this.buildForm(newFormDefinition, this);
  }

  buildFormByExistingFormGroup(srcForm: FormGroup) {

  }

  buildForm(formDefinition: MFormDefinition = this.formDefinition, existingForm?: FormGroup) {
    const form = existingForm || new FormGroup({});

    formDefinition.forEach(fieldDefinition => {
      this.buildFormControl(form, fieldDefinition);
    });

    return form;
  }

  buildFormControl(targetForm: FormGroup, fieldDefinition: MFieldDefinition): AbstractControl {
    const hasChildren = fieldDefinition.children && fieldDefinition.children.length;
    const syncValidators = _.castArray(fieldDefinition.syncValidators).filter(_.identity);
    const asyncValidators = _.castArray(fieldDefinition.asyncValidators).filter(_.identity);

    let targetControl;
    if (fieldDefinition.isFormArray) {
      targetControl = new FormArray([], syncValidators, asyncValidators);
    } else {
      if (hasChildren) {
        targetControl = this.buildForm(fieldDefinition.children);
      } else {
        targetControl = new FormControl({ value: fieldDefinition.defaultValue, disabled: fieldDefinition.disabled }, syncValidators, asyncValidators);
      }
    }
    targetForm.addControl(fieldDefinition.name, targetControl);

    if (fieldDefinition.isFormArray && fieldDefinition.defaultValue && _.isArray(fieldDefinition.defaultValue)) {
      _.forEach(fieldDefinition.defaultValue, () => {
        this.formArrayPush(fieldDefinition.treePath, undefined, targetForm);
      });
    } else if (!fieldDefinition.isFormArray && fieldDefinition.children && fieldDefinition.children.length && _.isObject(fieldDefinition.defaultValue) && !_.isArray(fieldDefinition.defaultValue)) {
      targetControl.patchValue(fieldDefinition.defaultValue);
    }

    if (fieldDefinition.controlEvents && fieldDefinition.controlEvents.valueChanges && targetControl) {
      targetControl.valueChanges.subscribe((newvalue) => {
        fieldDefinition.controlEvents.valueChanges(newvalue, targetForm);
      });
    }

    fieldDefinition.control = targetControl;

    return fieldDefinition.control;
  }

  formArrayPush(fieldParentKey: string, formValue?: any, targetForm: FormGroup | FormArray = this): FormGroup {
    const fieldDefinition = this.getFieldDefinitionByName(fieldParentKey);
    if (fieldDefinition && ((targetForm instanceof FormGroup && targetForm.get(fieldDefinition.name)) || targetForm instanceof FormArray)) {
      const formChild = this.addFormChildToFormArray(targetForm, fieldDefinition);
      if (formValue) {
        formChild.patchValue(formValue);
      }

      return formChild;
    }
    return null;
  }

  formArrayReset(fieldParentKey: string, index: number, targetForm: FormGroup | FormArray = this) {
    const fieldDefinition = this.getFieldDefinitionByName(fieldParentKey);
    const formDefaultValue = this.getFormChildValue(fieldDefinition);
    let formArray: FormArray;
    if (targetForm instanceof FormGroup) {
      formArray = targetForm.get(fieldParentKey) as FormArray;
    } else if (targetForm instanceof FormArray) {
      formArray = targetForm;
    }
    formArray.at(index).reset(formDefaultValue);
  }

  formArrayRemove(fieldParentKey: string, index: number, targetForm: FormGroup | FormArray = this) {
    let formArray: FormArray;
    if (targetForm instanceof FormGroup) {
      formArray = targetForm.get(fieldParentKey) as FormArray;
    } else if (targetForm instanceof FormArray) {
      formArray = targetForm;
    }
    formArray.removeAt(index);
  }

  formArrayClear(fieldParentKey: string, targetForm: FormGroup | FormArray = this) {
    let formArray: FormArray;
    if (targetForm instanceof FormGroup) {
      formArray = targetForm.get(fieldParentKey) as FormArray;
    } else if (targetForm instanceof FormArray) {
      formArray = targetForm;
    }
    while (formArray.length) {
      formArray.removeAt(0);
    }
  }

  populateFromValues(formValues: any = {}, targetForm: FormGroup = this, targetFormDefinition: MFormDefinition = this.formDefinition) {
    _.forEach(targetFormDefinition, fieldDefinition => {
      const formValue = _.get(formValues, fieldDefinition.name);
      if (formValue) {
        const control = targetForm.get(fieldDefinition.name);
        if (control) {
          if (fieldDefinition.isFormArray) {
            if (_.isArray(formValue)) {
              const controlArr = control as FormArray;
              _.forEach(formValue, (formValueItem, formValueItemIdx) => {
                const formGroupChild = controlArr.at(formValueItemIdx) || this.addFormChildToFormArray(controlArr, fieldDefinition);
                this.populateFromValues(formValueItem, <FormGroup>formGroupChild, fieldDefinition.children);
              });
            }
          } else {
            const controlGroup = control as FormGroup;
            controlGroup.patchValue(formValue);
          }
        }
      }
    });
  }

  addFormChildToFormArray(existingForm: FormGroup | FormArray, fieldDefinition: MFieldDefinition) {
    const newFormGroup = this.buildForm(fieldDefinition.children);

    let formArray: FormArray;
    if (existingForm instanceof FormGroup) {
      formArray = existingForm.get(fieldDefinition.name) as FormArray;
    } else {
      formArray = existingForm;
    }

    formArray.push(newFormGroup);

    const newFormGroupIndex = formArray.length - 1;
    if (fieldDefinition.defaultValue && fieldDefinition.defaultValue[newFormGroupIndex]) {
      newFormGroup.patchValue(fieldDefinition.defaultValue[newFormGroupIndex]);
    }

    return newFormGroup;
  }

  removeFormChild(existingForm: FormGroup, fieldParentKey: string, index: number) {
    const formArray = existingForm.get(fieldParentKey) as FormArray;
    formArray.removeAt(index);
  }

  mergeFormGroups(...forms: FormGroup[]) {
    const mergedFormGroup = new FormGroup({});

    _.forEach(forms, form => {
      this.mergeFormGroup(mergedFormGroup, form);
    });

    return mergedFormGroup;
  }

  mergeFormGroup(
    targetForm: FormGroup,
    srcForm: FormGroup,
    parentControlName: string = '',
  ) {
    _.forEach(srcForm.controls, (control, controlName) => {
      if (control instanceof FormControl) {
        targetForm.setControl(controlName, control);
      }
    });
  }

  mergeBasicFormGroup(srcForm: FormGroup) {
    _.forEach(srcForm.controls, (control, controlName) => {
      this.setControl(controlName, control);
    });
  }

  getFormValidationErrors(form: FormGroup | FormArray = this, parentControlKey = null, pureControlName = null, arrayControlIndex = null): MFormValidationFailed[] {
    let errors: MFormValidationFailed[] = [];

    _.forEach<any>(form.controls, (control, controlKey) => {
      pureControlName = CommonService.joinToString([pureControlName, !(form instanceof FormArray) ? controlKey : null]);

      const targetControlKey = parentControlKey != null ? `${parentControlKey}.${controlKey}` : controlKey;
      if (form instanceof FormArray) {
        errors = errors.concat(this.getFormValidationErrors(<FormGroup>control, targetControlKey, pureControlName, controlKey));
      } else if (control instanceof FormGroup) {
        errors = errors.concat(this.getFormValidationErrors(control, targetControlKey, pureControlName));
      } else if (control instanceof FormArray) {
        (<FormArray>control).controls.forEach((controlChild, controlChildKey) => {
          const targetControlChildKey = CommonService.joinToString([targetControlKey, controlChildKey]);
          errors = errors.concat(this.getFormValidationErrors(<FormGroup>controlChild, targetControlChildKey, pureControlName, controlChildKey));
        });
      }

      const controlErrors = control.errors;
      if (controlErrors !== null) {
        Object.keys(controlErrors).forEach(controlKeyError => {
          errors.push({
            arrayControlIndex,
            arrayControlNumber: arrayControlIndex + 1,
            pureControlName,
            fullControlName: targetControlKey,
            control: control,
            controlName: controlKey,
            errorName: controlKeyError,
            errorInfo: controlErrors[controlKeyError]
          });
        });
      }
    });
    return errors;
  }

  private getFormChildValue(fieldDefinition: MFieldDefinition, fieldDefinitionValues = {}) {
    if (fieldDefinition.children && fieldDefinition.children.length) {
      _.forEach(fieldDefinition.children, fieldDefinitionChild => {
        _.set(fieldDefinitionValues, fieldDefinitionChild.name, fieldDefinitionChild.defaultValue);

        if (fieldDefinitionChild.children) {
          this.getFormChildValue(fieldDefinitionChild, fieldDefinitionValues);
        }
      });
    }

    return fieldDefinitionValues;
  }

  getFieldDefinitionByName(fieldName: string, formDefinition: MFormDefinition = this.formDefinition): MFieldDefinition {
    for (const field of formDefinition) {
      if (field.treePath === fieldName) {
        return field;
      } else if (field.children && field.children.length) {
        const fieldSearch = this.getFieldDefinitionByName(fieldName, field.children);
        if (fieldSearch) {
          return fieldSearch;
        }
      }
    }
  }
}
