import { Injectable } from '@angular/core';
import { BroadcastChannel, createLeaderElection } from 'broadcast-channel';
import { BroadcastChannelEvent } from './broadcast-channel.model';
import { WindowTabService } from './window-tab.service';

@Injectable({
  providedIn: 'root',
})
export class BroadcastChannelService {
  channelName: string = 'main';
  channel: BroadcastChannel<BroadcastChannelEvent> = new BroadcastChannel(this.channelName);
  leaderElector = createLeaderElection(this.channel);

  isLeader: boolean = false;

  constructor(private _windowTabService: WindowTabService) {
    this.leaderElector.awaitLeadership().then(() => {
      this.isLeader = this.leaderElector.isLeader;
    });
  }

  postMessage(messageId: string, data: any, sendToLeaderOnly?: boolean, p2pTargetId?: number) {
    const event = new BroadcastChannelEvent(messageId, data, sendToLeaderOnly, p2pTargetId);

    if (sendToLeaderOnly && this.isLeader) {
      throw new Error('Cannot broadcast message to leader, you are the leader.');
    }

    return this.channel.postMessage(event);
  }

  postEvent(event: BroadcastChannelEvent) {
    if (event.sendToLeaderOnly && this.isLeader) {
      throw new Error('Cannot broadcast message to leader, you are the leader.');
    }
    return this.channel.postMessage(event);
  }

  addEventListener(
    eventType: 'message' | 'internal',
    handler: (event: BroadcastChannelEvent) => void
  ) {
    return this.channel.addEventListener(eventType, this.buildEventHandler(handler));
  }

  removeEventListener(
    eventType: 'message' | 'internal',
    handler: (event: BroadcastChannelEvent) => void
  ) {
    return this.channel.removeEventListener(eventType, this.buildEventHandler(handler));
  }

  buildEventHandler(handler: (event: BroadcastChannelEvent) => void) {
    return (event: BroadcastChannelEvent) => {
      if (event.sendToLeaderOnly && this.isLeader) {
        handler(event);
      } else if (event.p2pTargetId) {
        if (event.p2pTargetId === this._windowTabService.tabId) {
          handler(event);
        }
      } else {
        handler(event);
      }
    };
  }
}
