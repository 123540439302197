import { Injectable } from '@angular/core';
import { APP_CONST } from '../../app.const';
import { RequestService } from '../../libraries/http/request.service';

@Injectable()
export class RoleRestService {
  baseURL = `${APP_CONST.API_MASTER}/admin/roles`;
  request = this._request.new(this.baseURL);

  constructor(private _request: RequestService) {}

  create(payload: any) {
    return this.request.post<any>(``, payload);
  }

  findAll(queryParams: any = {}) {
    return this.request.get<any>(``, { params: queryParams });
  }

  load(roleId: string) {
    return this.request.get<any>(`${roleId}`);
  }

  delete(roleId: string) {
    return this.request.delete<any>(`${roleId}`);
  }

  update(roleId: string, payload: any) {
    return this.request.put<any>(`${roleId}`, payload);
  }

  getAccessRole() {
    return this.request.get<any>(`default-role-access`);
  }
}
