
import {of as observableOf,  Observable } from 'rxjs';

import {switchMap, map} from 'rxjs/operators';


import { Injectable } from '@angular/core';
import { Http, Response, ResponseContentType, Headers } from '@angular/http';

import { CommonService } from '../common/common.service';
import { FileService } from '../file/file.service';

@Injectable()
export class HttpFileService {
  constructor(
    private _file: FileService,
    private _http: Http,
  ) { }

  downloadFileToBase64(fileUrl: string, replaceHttpsWithHttp: boolean = false): Observable<{
    base64?: string,
    contentType?: string,
    fileName?: string,
    fileSize?: number,
  }> {
    if (replaceHttpsWithHttp) {
      fileUrl = fileUrl.replace(/^https/, 'http');
    }
    return this._http
      .get(fileUrl, {
        responseType: ResponseContentType.Blob,
      }).pipe(
      map((res: Response) => res.blob()),
      switchMap((fileBlob) => {
        return this._file.blobToBase64(fileBlob)
          .switchMap(base64File => {
            return observableOf({
              base64: base64File.replace(`data:${fileBlob.type};base64,`, ''),
              contentType: fileBlob.type,
              fileName: CommonService.baseName(fileUrl),
              fileSize: fileBlob.size,
            });
          });
      }),);
  }
}
