import { NgModule } from '@angular/core';

import { ViewUtilityTemplateDirective } from './view-utility-template.directive';

@NgModule({
  declarations: [
    ViewUtilityTemplateDirective,
  ],
  exports: [
    ViewUtilityTemplateDirective,
  ],
})
export class ViewUtilityModule { }
