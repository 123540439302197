import { Component } from '@angular/core';
import 'moment/locale/id';
import * as moment from 'moment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { BluebirdCreditBriRestService } from '../bluebird-credit-bri-rest.service';
import { ExportDataTableService } from '../../../libraries/export/export-data-table.service';
import { AuthenticationService } from '../../../libraries/auth/authentication.service';
import * as pdfMake from 'pdfmake';

interface FileDetails {
  originalFileName: string;
  fileUrl: string; // S3 URL
  storageFileName: string;
}

@Component({
  selector: 'app-bluebird-credit-bri-list',
  templateUrl: 'bluebird-credit-bri-list.component.html',
  styleUrls: ['./bluebird-credit-bri-list.component.scss']
})
export class BluebirdCreditBriListComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'ui.bluebirdCreditBri.entity.no',
      field: 'no'
    },
    {
      i18nLabel: 'ui.bluebirdCreditBri.entity.name',
      field: 'fullname',
      sort: true
    },
    {
      i18nLabel: 'ui.bluebirdCreditBri.entity.phone',
      field: 'phone',
      sort: true
    },
    {
      i18nLabel: 'ui.bluebirdCreditBri.entity.nik',
      field: 'nik',
      sort: true
    },
    {
      i18nLabel: 'ui.bluebirdCreditBri.entity.fullAddress',
      field: 'address',
      sort: true
    },
    {
      i18nLabel: 'ui.bluebirdCreditBri.entity.salary',
      field: 'salary',
      sort: true
    },
    {
      i18nLabel: 'ui.bluebirdCreditBri.entity.yearsOfService',
      field: 'years_of_service',
      sort: true
    },
    {
      i18nLabel: 'ui.bluebirdCreditBri.entity.vehicleType',
      field: 'vehicle_type',
      sort: true
    },
    {
      i18nLabel: 'ui.bluebirdCreditBri.entity.vehicleColor',
      field: 'vehicle_color',
      sort: true
    },
    {
      i18nLabel: 'ui.bluebirdCreditBri.entity.tenor',
      field: 'tenor',
      sort: true
    },
    {
      i18nLabel: 'ui.bluebirdCreditBri.entity.vehiclePrice',
      field: 'vehicle_price',
      sort: true
    },
    // TODO: validate based on ktp_wife_image_url
    {
      i18nLabel: 'ui.bluebirdCreditBri.entity.marriageStatus',
      name: 'marriageStatus',
      field: '',
      sort: true
    },
    {
      i18nLabel: 'ui.bluebirdCreditBri.entity.createdAt',
      field: 'createdAt',
      sort: true
    },
    {
      i18nLabel: 'ui.bluebirdCreditBri.entity.action',
      field: '',
      name: 'action'
    }
  ];
  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(this.tableColumns);
  isLoadingExportExcel: boolean = false;
  isKtpModalOpen: boolean = false;
  ktpData: any = {};
  isPreviewModalOpen: boolean = false;
  selectedPreviewImage: string | null = null;

  constructor(
    public _authentication: AuthenticationService,
    private _BluebirdCreditBriRest: BluebirdCreditBriRestService,
    private _export: ExportDataTableService
  ) {
    super();
    this.componentId = 'BluebirdCreditBriListComponent';
    this.headerTitle = 'ui.bluebirdCreditBri.list.title';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qOption = event.data.qOption;
        qOption['limit'] = qOption['take'];
        delete qOption['take'];
        delete qOption['skip'];
        const qParams = Object.assign({ channel: 'bb_semolis' }, event.data.qParams, qOption, this.qParams);
        return this._BluebirdCreditBriRest.findAll(qParams).pipe(
          switchMap(doc => {
            const dataFormatted = doc?.data?.map(d => {
              d.createdAt = moment(d?.createdAt).format('DD MMMM YYYY HH:mm');
              d.updatedAt = moment(d?.updatedAt).format('DD MMMM YYYY HH:mm');
              // Format salary to Indonesian Rupiah
              d.salary = new Intl.NumberFormat('id-ID', {
                style: 'currency',
                currency: 'IDR',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }).format(d.salary);

              // Format vehicle price to Indonesian Rupiah
              d.vehicle_price = new Intl.NumberFormat('id-ID', {
                style: 'currency',
                currency: 'IDR',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }).format(d.vehicle_price);
              return d;
            });
            doc.data = dataFormatted;
            return of(doc);
          })
        );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () => this.page.compHookService?.callMultipleHooks(['BluebirdCreditBriListComponent:workflowLoadData'])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  fetchImageAsDataUrl = async (imageUrl: string): Promise<string> => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  async onDownloadPdf(data) {
    const extractUrl = async (storageFileName: string) =>
      (await this._BluebirdCreditBriRest.getPresignedUrl({ storageFileName }).toPromise())?.url;

    // Helper function to convert URL to base64
    const getBase64FromUrl = async (url: string): Promise<string> => {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      } catch (error) {
        console.error('Error converting image to base64:', error);
        throw error;
      }
    };

    try {
      // Get the URLs
      const ktp_image_url = await extractUrl(data.ktp_image_url);
      const kk_image_url = await extractUrl(data.kk_image_url);
      const ktp_wife_image_url = data.ktp_wife_image_url ? await extractUrl(data.ktp_wife_image_url) : null; // Optional check

      // Convert URLs to base64
      const ktp_image_base64 = await getBase64FromUrl(ktp_image_url);
      const kk_image_base64 = await getBase64FromUrl(kk_image_url);
      const ktp_wife_image_base64 = ktp_wife_image_url ? await getBase64FromUrl(ktp_wife_image_url) : null; // Optional check

      const documentDefinition = {
        content: [
          { text: 'Detail Karyawan', style: 'header' },
          { text: '\n' },
          {
            text: [
              { text: 'Nama Lengkap: ', bold: true },
              data.fullname,
              '\n',
              { text: 'NIK: ', bold: true },
              data.nik,
              '\n',
              { text: 'NIP: ', bold: true },
              data.nip,
              '\n',
              { text: 'Alamat: ', bold: true },
              `${data.address}, RT ${data.rt}/RW ${data.rw}, ${data.subdistrict}, ${data.district}, ${data.city}, ${data.province}, ${data.postal_code}`,
              '\n',
              { text: 'No Telepon: ', bold: true },
              data.phone,
              '\n',
              { text: 'Gaji: ', bold: true },
              data.salary,
              '\n',
              { text: 'Lama Bekerja: ', bold: true },
              `${data.years_of_service} tahun`,
              '\n',
              { text: 'Tenor: ', bold: true },
              `${data.tenor} bulan`,
              '\n',
              { text: 'Tipe Motor: ', bold: true },
              data.vehicle_type,
              '\n',
              { text: 'Warna Motor: ', bold: true },
              data.vehicle_color,
              '\n',
              { text: 'Harga Motor: ', bold: true },
              data.vehicle_price,
              '\n'
            ]
          },
          { text: '\nKartu Tanda Penduduk', style: 'subheader' },
          {
            image: ktp_image_base64,
            width: 150,
            alignment: 'center'
          },
          { text: '\nKartu Keluarga', style: 'subheader' },
          {
            image: kk_image_base64,
            width: 150,
            alignment: 'center'
          },
          ktp_wife_image_base64 && { text: '\nKartu Tanda Penduduk Istri', style: 'subheader' },
          ktp_wife_image_base64 && {
            image: ktp_wife_image_base64,
            width: 150,
            alignment: 'center'
          }
        ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            alignment: 'center'
          },
          subheader: {
            fontSize: 14,
            bold: true,
            margin: [0, 10, 0, 5]
          },
          link: {
            color: 'blue',
            decoration: 'underline'
          }
        }
      };

      pdfMake.createPdf(documentDefinition).open();
    } catch (error) {
      console.error('Error generating PDF:', error);
      // Handle the error appropriately (e.g., show an error message to the user)
    }
  }

  async openKtpModal(data) {
    this.isKtpModalOpen = true;
    this.ktpData = {
      ...data,
      ktp_original_filename: data.ktp_image_url,
      kk_original_filename: data.kk_image_url,
      ...(data.ktp_wife_image_url && {
        ktp_wife_original_filename: data.ktp_wife_image_url
      })
    };

    // Get presigned URLs
    this.ktpData.ktp_image_url = await this.getPresignedUrl(this.ktpData.ktp_image_url);
    this.ktpData.kk_image_url = await this.getPresignedUrl(this.ktpData.kk_image_url);
    // Only get presigned URL for wife KTP if it exists
    if (data.ktp_wife_image_url) {
      this.ktpData.ktp_wife_image_url = await this.getPresignedUrl(data.ktp_wife_image_url);
    }

    console.log(this.ktpData);
  }

  closeKtpModal() {
    this.isKtpModalOpen = false;
  }

  isImageFile(file: string | undefined): boolean {
    // First check if file exists and is a string
    if (!file || typeof file !== 'string') {
      return false;
    }

    const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif'];
    const extension = file
      .split('.')
      .pop()
      ?.toLowerCase();

    // Check if extension exists and is in allowed list
    return extension ? allowedExtensions.includes(extension) : false;
  }

  async getPresignedUrl(file: string): Promise<string> {
    try {
      if (file === '') {
        return '';
      }
      const data = await this._BluebirdCreditBriRest
        .getPresignedUrl({ storageFileName: file })
        .pipe(map((data: any) => data.url))
        .toPromise();
      return data;
    } catch (error) {
      console.error('Error fetching presigned URL:', error);
      throw new Error('Failed to fetch presigned URL');
    }
  }

  async downloadFileWithPresignedUrl(file: string): Promise<void> {
    try {
      const url = await this.getPresignedUrl(file);
      const link = document.createElement('a');
      link.href = url;
      link.download = file;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  }

  async onDownloadExcel() {
    this.isLoadingExportExcel = true;
    try {
      const result = await this._BluebirdCreditBriRest.findAll({ channel: 'bb_semolis' }).toPromise();
      const mapOption = {
        fullname: { header: 'Nama' },
        phone: { header: 'Telepon' },
        nik: { header: 'NIK (KTP)' },
        dob: { header: 'Tanggal Lahir' },
        gender: { header: 'Jenis Kelamin' },
        email: { header: 'Email' },
        address: { header: 'Alamat' },
        createdAt: { header: 'Tanggal Dibuat' }
      };
      const payload = result.data.map(item => ({
        ...item,
        dob: moment(item?.dob).format('DD MMMM YYYY'),
        gender: item?.gender == 'M' ? 'Laki-laki' : 'Perempuan',
        createdAt: moment(item?.createdAt).format('DD MMMM YYYY HH:mm')
      }));
      this._export.export({
        extension: 'xlsx',
        fileName: 'BluebirdCreditBri',
        records: payload,
        mapOptions: mapOption,
        templateData: null,
        pdfOptions: null
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoadingExportExcel = false;
    }
  }

  openImagePreview(imageUrl: string) {
    this.selectedPreviewImage = imageUrl;
    this.isPreviewModalOpen = true;
  }

  closeImagePreview() {
    this.isPreviewModalOpen = false;
    this.selectedPreviewImage = null;
  }
}
