import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-grid-table-footer',
  templateUrl: './grid-table-footer.component.html'
})
export class GridTableFooterComponent {
  @Input() gridDataSource: any;
  @Output() gridChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() pageChanged: EventEmitter<boolean> = new EventEmitter();
}
