import { EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { MForm } from '../../common/form.model';
import { MEfScheme } from '../../ef/ef.model';
import { MFormgenSchema } from '../../formgen/formgen.model';
import { BaseCompBComponent } from '../comp/base-comp.bcomponent';

export class BaseFormBComponent extends BaseCompBComponent implements OnInit {
  @Input() doc: any;
  @Input('form') _form: MForm = new MForm({});
  get form(): MForm | FormGroup {
    return (this.formgenSchema && this.formgenSchema.fields && this.formgenSchema.fields.length && this.formgenSchema.form) || this._form;
  }
  set form(newForm: MForm | FormGroup) {
    this._form = newForm as MForm;
  }
  @Input() formType: string;

  @Output('formgenSchemaChange') formgenSchemaFromInputChange: EventEmitter<MFormgenSchema> = new EventEmitter();
  @Input('formgenSchema')
  set formgenSchemaFromInput(newFormgenSchema: MFormgenSchema) {
    this.formgenSchema = newFormgenSchema;
  }
  get formgenSchemaFromInput() {
    return this.formgenSchema;
  }
  get hasUnsavedData() {
    const formValue = this.form.value;
    for (const key of Object.keys(formValue)) {
      if (formValue[key] !== this.doc[key]) return true
    }
    return false;
  }

  _formgenSchema: MFormgenSchema = new MFormgenSchema();
  set formgenSchema(newFormgenSchema: MFormgenSchema) {
    this._formgenSchema = newFormgenSchema;
    this.formgenSchemaFromInputChange.emit(newFormgenSchema);
  }
  get formgenSchema() {
    return this._formgenSchema;
  }

  relatedData: any = {};
  relatedDataReady: boolean = false;
  formReady: boolean = false;

  efSchemes: { [key: string]: MEfScheme } = {};

  get mform() {
    return this.form as MForm;
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'workflowBuildForm',
      handle: () => {
        return this.callHook('buildForm').pipe(
          tap(() => {
            this.formReady = true;
          }));
      }
    });

    this.registerHook({
      hookName: 'workflowLoadRelated',
      handle: () => {
        return this.callHook('loadRelated').pipe(
          tap(relatedData => {
            this.relatedData = relatedData;
            this.relatedDataReady = true;
            this.comp._changeDetectorRef.detectChanges();
          }));
      }
    });
  }
}

