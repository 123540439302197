import { NgModule } from '@angular/core';

import { SecureFilePipe } from './secure-file.pipe';

@NgModule({
  declarations: [
    SecureFilePipe
  ],
  exports: [
    SecureFilePipe
  ]
})
export class FileSecurityModule { }
