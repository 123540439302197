import { Component, Input } from '@angular/core';
import { BaseCompBComponent } from '../../comp/base-comp.bcomponent';

@Component({
  selector: 'app-base-action-header',
  templateUrl: './base-action-header.component.html',
})
export class BaseActionHeaderComponent {
  @Input() component: BaseCompBComponent;
}
