import {
  Injectable
} from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class AclService {
  serverPermissionKeys: string[] = [
    'role.create',
    'role.read',
    'role.update',
    'role.delete',

    'user.create',
    'user.read',
    'user.update',
    'user.delete',
  ];

  abilities = {
    guest: [
      'login',
    ],
    admin: [
      'dashboard',
    ]
  };
  roles: string[] = [];

  exclusivePermissions: string[] = [];

  hasRole(role: string) {
    return this.roles.includes(role);
  }

  attachRole(role: string | string[]) {
    const roles = _.castArray(role);
    this.roles = _.uniq(_.concat(this.roles, roles));
  }

  can(...accesses) {
    accesses = accesses.filter(_.identity);
    if (!accesses.length) {
      return true;
    }
    return _.map(accesses, accessGroup => this.canByGroup(accessGroup)).filter(_.identity).length > 0;
  }

  flushRoles() {
    this.roles = [];
  }

  private canByGroup(access: string | string[]) {
    const abilities = this.getAbilities();
    const accesses = _.castArray(<any>access);
    const results = accesses.map(ability => {
      return _.includes(abilities, ability);
    });

    const matchAbilities = results.filter(_.identity).length;
    return matchAbilities === accesses.length;
  }

  private getAbilities() {
    const rolesAbilities = this.roles.map(role => this.abilities[role] || []);
    const abilities = _.uniq(
      _.concat(
        _.first([...rolesAbilities]),
        this.exclusivePermissions,
      )
    ).sort();

    return abilities;
  }
}
