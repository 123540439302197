import { Component } from '@angular/core';
import * as _ from 'lodash';
import { BaseFormBComponent } from '../../../libraries/base/form/base-form.bcomponent';
import { RoleRestService } from '../role-rest.service';

@Component({
  selector: 'app-role-form',
  templateUrl: 'role-form.component.html'
})
export class RoleFormComponent extends BaseFormBComponent {
  permissionGroups: any[] = [];
  selectedPermissionGroup: string[] = [];
  selectedPermission: any;

  constructor(private _roleRest: RoleRestService) {
    super();

    this.componentId = 'RoleFormComponent';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'buildForm',
      handle: () => {
        this.formgenSchema.fields = [
          {
            label: 'ui.role.entity.roleName',
            name: 'name',
            field: 'text',
            validations: {
              required: true
            }
          },
          {
            name: 'roleAccess',
            ignore: true
          }
        ];

        this.form.get('roleAccess').setValue(this.selectedPermission);
      }
    });

    this.registerHook({
      hookName: 'loadRelated',
      handle: () => {
        return this._roleRest.getAccessRole();
      },
      success: relatedData => {
        // Transform object to array
        this.permissionGroups = Object.keys(relatedData).map(key => ({
          groupName: key,
          value: Object.keys(relatedData[key]).map(childKey => ({
            permissionName: childKey,
            value: relatedData[key][childKey]
          }))
        }));

        this.permissionGroups = _.orderBy(this.permissionGroups, [
          'groupName',
          'asc'
        ]);

        this.selectedPermission = _.mapValues(relatedData, permissionGroup => {
          return _.mapValues(permissionGroup, () => false);
        });
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });
  }

  appOnInit() {
    super.appOnInit();

    this.page.compHookService
      .onHookSuccessById('RoleUpdateComponent:workflowLoadData')
      .subscribe(() => {
        this.selectedPermission = this.doc.roleAccess;
        const selectedPermissionGroup = [];

        Object.keys(this.selectedPermission).forEach(key => {
          const permission = this.selectedPermission[key];

          if (permission._id) {
            delete this.selectedPermission[key]._id;
          }

          if (_.values(permission).includes(true)) {
            selectedPermissionGroup.push(key);
          }
        });

        this.selectedPermissionGroup = selectedPermissionGroup;
        this.comp._changeDetectorRef.detectChanges();
      });
  }

  onPermissionGroupChange(isChecked: any, selectedGroup: string) {
    this.selectedPermission[selectedGroup] = _.mapValues(
      this.selectedPermission[selectedGroup],
      () => isChecked
    );
    this.form.get('roleAccess').setValue(this.selectedPermission);
  }

  onPermissionChange() {
    this.form.get('roleAccess').setValue(this.selectedPermission);
  }
}
