import { Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { of } from 'rxjs';
import { BroadcastChannelService } from './broadcast-channel.service';
import { CentralCacheStorage } from './central-cache.model';
import { EventService } from './event.service';

@Injectable({
  providedIn: 'root',
})
export class CentralCacheService {
  id: string;
  cacheQueryEventId: string = 'CENTRALCACHE:QUERY';
  cacheTransferEventId: string = 'CENTRALCACHE:TRANSFER';
  cacheUpdateEventId: string = 'CENTRALCACHE:UPDATE';
  caches: CentralCacheStorage[] = [];

  constructor(
    private _broadcastChannelService: BroadcastChannelService,
    @Inject('GlobalEvent') private _globalEventService: EventService
  ) {}

  boot() {
    this.listenCacheQueryEvent();
    this.listenCacheTransferEvent();
    this.listenCacheUpdateEvent();

    try {
      this._broadcastChannelService.postMessage(this.cacheQueryEventId, null, true);
    } catch (e) {
      // will throw if current tab is leader and use self caches
    }

    this._globalEventService.listen('CORE:LOGOUT:SUCCESS').subscribe(() => {
      this.resetLocally();
    });

    return of(true);
  }

  resetLocally() {
    this.caches = [];
  }

  listenCacheQueryEvent() {
    this._broadcastChannelService.addEventListener('message', event => {
      if (event.name === this.cacheQueryEventId) {
        this._broadcastChannelService.postMessage(
          this.cacheTransferEventId,
          this.caches,
          false,
          event.p2pSourceId
        );
      }
    });
  }

  listenCacheTransferEvent() {
    this._broadcastChannelService.addEventListener('message', event => {
      if (event.name === this.cacheTransferEventId) {
        this.caches = event.data;
      }
    });
  }

  listenCacheUpdateEvent() {
    this._broadcastChannelService.addEventListener('message', event => {
      if (event.name === this.cacheUpdateEventId) {
        const cache: CentralCacheStorage = event.data;
        this.setCache(cache.id, cache);
      }
    });
  }

  hasCacheStorage(cacheStorageId: string) {
    const existingCacheIndex = _.findIndex(this.caches, { id: cacheStorageId });
    return existingCacheIndex > -1;
  }

  registerCacheStorageIfNotExists(cacheStorage: CentralCacheStorage) {
    const existingCacheStorage = _.find(this.caches, { id: cacheStorage.id });
    if (!existingCacheStorage) {
      this.caches.push(cacheStorage);
    }

    return existingCacheStorage || cacheStorage;
  }

  getCacheItem(cacheStorageId: string) {
    const cacheStorage = _.find(this.caches, { id: cacheStorageId });
    if (cacheStorage) {
      return cacheStorage.data;
    }
  }

  setCache(cacheStorageId: string, cache: Partial<CentralCacheStorage>, broadcastChange: boolean = false) {
    const targetCache = new CentralCacheStorage(cache.id, cache.options);
    targetCache.data = cache.data;
    targetCache.cacheActivatedAt = cache.cacheActivatedAt;
    targetCache.tickFlush();

    const cacheStorageIndex = _.findIndex(this.caches, { id: cacheStorageId });
    if (cacheStorageIndex > -1) {
      this.caches[cacheStorageIndex] = targetCache;
    } else {
      this.caches.push(targetCache);
    }

    if (broadcastChange) {
      this._broadcastChannelService.postMessage(this.cacheUpdateEventId, cache);
    }
  }

  setCacheData(cacheStorageId: string, cacheData: any, broadcastChange: boolean = true) {
    const cacheStorage = _.find(this.caches, { id: cacheStorageId });
    if (cacheStorage) {
      cacheStorage.data = cacheData;

      if (broadcastChange) {
        this._broadcastChannelService.postMessage(this.cacheUpdateEventId, cacheStorage);
      }
    }
  }
}
