import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';

import { MFormgenSchema, MFormgenSchemaField } from './formgen.model';

@Injectable()
export class FormgenService {
  buildSchemaControls(schema: MFormgenSchema) {
    _.forEach(schema.form.controls, (control, controlName) => {
      schema.form.removeControl(controlName);
    });

    _.forEach(schema.fields, field => {
      this.buildSchemaControl(schema, field);
    });
  }

  buildSchemaControl(schema: MFormgenSchema, field: MFormgenSchemaField) {
    const control = new FormControl(field.defaultValue);

    schema.form.setControl(field.name, control);

    const fieldSyncValidators = _.castArray(field.syncValidators).filter(_.identity);
    const fieldAsyncValidators = _.castArray(field.asyncValidators).filter(_.identity);

    const syncValidators = fieldSyncValidators.concat(this.buildSyncValidators(field));
    const asyncValidators = fieldAsyncValidators.concat(this.buildAsyncValidators(field));

    schema.form.get(field.name).setValidators(syncValidators);
    schema.form.get(field.name).setAsyncValidators(asyncValidators);

    return control;
  }

  buildSyncValidators(field: MFormgenSchemaField) {
    const validators = [];

    if (_.get(field, 'validations.required')) {
      validators.push(Validators.required);
    }
    if (_.get(field, 'validations.maxLength')) {
      const maxLength = _.get(field, 'validations.maxLength');
      validators.push(Validators.maxLength(maxLength));
    }

    return validators;
  }

  buildAsyncValidators(field: MFormgenSchemaField) {
    const validators = [];

    return validators;
  }
}
