import { Directive, Input } from '@angular/core';
import { NgControl, FormControlName } from '@angular/forms';

@Directive({
  selector: '[controlDisable]'
})
export class ControlDisableDirective {

  @Input() set controlDisable(condition: boolean) {
    const action = condition ? 'disable' : 'enable';
    if (this.ngControl.control) {
      this.ngControl.control[action]();
    } else {
      (this.ngControl as any)._parent.control[action]();
    }
  }

  constructor(private ngControl: NgControl) { }

}
