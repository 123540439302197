
import {from as observableFrom,  Observable, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ObservableService {
  convertFunctionToObservable(handle: any) {
    return new Observable(observer => {
      const complete = (handleSubscription?: Subscription) => {
        if (handleSubscription) {
          handleSubscription.unsubscribe();
        }
        observer.complete();
      };

      try {
        let targetHandle: any = handle();

        if (_.has(targetHandle, 'then')) {
          targetHandle = observableFrom(targetHandle);
        }

        if (targetHandle instanceof Observable) {
          const sub = targetHandle.subscribe(data => {
            observer.next(data);
            complete(sub);
          }, error => {
            observer.error(error);
            complete(sub);
          });
        } else {
          observer.next(targetHandle);
          complete();
        }
      } catch (error) {
        console.error(error);
        complete();
      }
    });
  }
}
