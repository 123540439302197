import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login.component';
import { AuthorizationService } from '../../libraries/auth/authorization.service';


export const routes: Routes = [{
  path: '',
  component: LoginComponent,
  canActivate: [AuthorizationService],
  data: {
    name: 'login'
  }
}];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class LoginRoutingModule { }
