import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { CommonService } from './common.service';
import { MFieldDefinition, MFormDefinition, MFormValidationFailed } from './form.model';

@Injectable()
export class FormService {
  mergeBasicFormGroup(...forms: FormGroup[]) {
    const mergedFormGroup = new FormGroup({});

    _.forEach(forms, form => {
      _.forEach(form.controls, (control, controlName) => {
        mergedFormGroup.setControl(controlName, control);
      });
    });

    return mergedFormGroup;
  }

  getFormValidationErrors(form: FormGroup | FormArray, parentControlKey = null, pureControlName = null, arrayControlIndex = null): MFormValidationFailed[] {
    let errors: MFormValidationFailed[] = [];

    _.forEach<any>(form.controls, (control, controlKey) => {
      pureControlName = CommonService.joinToString([pureControlName, !(form instanceof FormArray) ? controlKey : null]);

      const targetControlKey = parentControlKey != null ? `${parentControlKey}.${controlKey}` : controlKey;
      if (form instanceof FormArray) {
        errors = errors.concat(this.getFormValidationErrors(<FormGroup>control, targetControlKey, pureControlName, controlKey));
      } else if (control instanceof FormGroup) {
        errors = errors.concat(this.getFormValidationErrors(control, targetControlKey, pureControlName));
      } else if (control instanceof FormArray) {
        (<FormArray>control).controls.forEach((controlChild, controlChildKey) => {
          const targetControlChildKey = CommonService.joinToString([targetControlKey, controlChildKey]);
          errors = errors.concat(this.getFormValidationErrors(<FormGroup>controlChild, targetControlChildKey, pureControlName, controlChildKey));
        });
      }

      const controlErrors = control.errors;
      if (controlErrors !== null) {
        Object.keys(controlErrors).forEach(controlKeyError => {
          errors.push({
            arrayControlIndex,
            arrayControlNumber: arrayControlIndex + 1,
            pureControlName,
            fullControlName: targetControlKey,
            control: control,
            controlName: controlKey,
            errorName: controlKeyError,
            errorInfo: controlErrors[controlKeyError]
          });
        });
      }
    });
    return errors;
  }

  buildFormDefinitionFromExistingFormGroup(existingFormGroup: FormGroup): MFormDefinition {
    const flatFormDefinition = this.getFlatFormDefinitionFromExistingFormGroup(existingFormGroup);

    const formDefinition: MFormDefinition = [];
    _.forEach(flatFormDefinition, fieldDefinition => {
      const fieldPaths = fieldDefinition.treePath.split('.');

      let currentIteratorFormDefinition = formDefinition;
      let currentIteratorFieldDefinition = _.find<MFieldDefinition>(flatFormDefinition, { treePath: fieldPaths[0] });
      _.forEach(fieldPaths, (fieldPath, fieldPathIndex) => {
        if (+fieldPathIndex === fieldPaths.length - 1) {
          currentIteratorFormDefinition.push(fieldDefinition);
        } else {
          currentIteratorFieldDefinition = _.find<MFieldDefinition>(flatFormDefinition, { treePath: fieldPaths.slice(0, fieldPathIndex + 1).join('.') });
          currentIteratorFormDefinition = currentIteratorFieldDefinition.children;
        }
      });
    });

    return formDefinition;
  }

  getFlatFormDefinitionFromExistingFormGroup(existingFormGroup: FormGroup, targetFormDefinition: MFormDefinition = [], treeParentPath: string = '') {
    _.forEach(existingFormGroup.controls, (childAbstractControl, childAbstractControlName) => {
      const fieldDefinition = new MFieldDefinition;
      fieldDefinition.name = childAbstractControlName;
      fieldDefinition.control = childAbstractControl;
      fieldDefinition.syncValidators = childAbstractControl.validator;
      fieldDefinition.asyncValidators = childAbstractControl.asyncValidator;
      fieldDefinition.defaultValue = childAbstractControl.value;
      fieldDefinition.treeParentPath = treeParentPath;

      const fieldDefinitionTreePath = treeParentPath ? `${treeParentPath}.${childAbstractControlName}` : childAbstractControlName;
      fieldDefinition.treePath = fieldDefinitionTreePath;

      targetFormDefinition.push(fieldDefinition);

      if (childAbstractControl instanceof FormGroup || childAbstractControl instanceof FormArray) {
        fieldDefinition.children = [];

        if (childAbstractControl instanceof FormGroup) {
          this.getFlatFormDefinitionFromExistingFormGroup(childAbstractControl, targetFormDefinition, fieldDefinitionTreePath);
        } else if (childAbstractControl instanceof FormArray) {
          fieldDefinition.isFormArray = true;

          const childAbstractControlAsFormArray = childAbstractControl as FormArray;
          const childAbstractControlFormGroup = childAbstractControlAsFormArray.at(0) as FormGroup;
          this.getFlatFormDefinitionFromExistingFormGroup(childAbstractControlFormGroup, targetFormDefinition, fieldDefinitionTreePath);
        }
      }
    });

    targetFormDefinition.sort((fieldDefinitionA, fieldDefinitionB) => fieldDefinitionA.treePath.length - fieldDefinitionB.treePath.length); // deep paths should be on the last

    return targetFormDefinition;
  }
}
