import * as _ from 'lodash';
import { Injectable } from '@angular/core';

@Injectable()
export class ExportPDFTemplate1Service {
  transform({ mapOptions, records, templateData, pdfOptions }: { mapOptions: IExportDataTableMap, records: any, templateData?: any, pdfOptions?: any }) {
    const targetContent: any = {
      content: [],
      pageOrientation: pdfOptions.pageOrientation,
      styles: {
        header: {
          fontSize: 22,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black',
        },
      },
    };

    targetContent.content.push({ text: _.get(templateData, 'title'), style: 'header' });

    const recordsTable = {
      headerRows: 1,
      widths: [],
      // widths: _.range(_.size(mapOptions)).map(() => '*'),
      body: [],
    };

    _.forEach(mapOptions, (mapOption, field) => {
      const option = <any>mapOption;
      if (_.get(option, 'widthPDF')) {
        recordsTable.widths.push(option.widthPDF);
      } else {
        recordsTable.widths.push('*');
      }
    });

    const rowHeader = [];
    _.forEach(mapOptions, (mapOption, field) => {
      const headerLabel = _.get(mapOption, 'header', field);
      rowHeader.push({ text: headerLabel, style: 'tableHeader' });
    });
    recordsTable.body.push(rowHeader);


    records.forEach(record => {
      const rowRecord = [];
      _.forEach(mapOptions, (mapOption, field) => {
        let fieldContent = _.get(record, field, mapOption.emptyPlaceholder || '-');
        if (_.get(mapOption, 'formatter')) {
          fieldContent = mapOption.formatter(fieldContent, record);
        }
        rowRecord.push(fieldContent);
      });
      recordsTable.body.push(rowRecord);
    });

    targetContent.content.push({ table: recordsTable });

    return targetContent;
  }
}
