import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-grid-table-header',
  templateUrl: './grid-table-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridTableHeaderComponent {
  @Input() column: IGridTableColumn;
  @Input() table: Table;
}
