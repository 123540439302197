import { Component } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { FormgenFieldControlBComponent } from '../field-control/formgen-field-control.bcomponent';

@Component({
  selector: 'app-formgen-field-textarea',
  templateUrl: './formgen-field-textarea.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class FormgenFieldTextareaComponent extends FormgenFieldControlBComponent { }
