import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LibrariesModule } from '../libraries.module';
import { FieldStatesDirective } from './states/field-states.directive';

@NgModule({
  imports: [
    CommonModule,
    LibrariesModule,
  ],
  declarations: [
    FieldStatesDirective,
  ],
  exports: [
    FieldStatesDirective,
  ],
})
export class FieldModule { }
