import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appFgenTpl]',
})
export class FormgenTemplateDirective {
  @Input() type: string;

  @Input('appFgenTpl') name: string;
  @Input('appFgenTplGroup') groupName: string;

  constructor(public template: TemplateRef<any>) { }

  getType(): string {
    return this.name;
  }
}
