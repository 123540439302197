import { Injectable, IterableDiffer, IterableDiffers } from '@angular/core';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { SpinnerDirective } from '../spinner/spinner.directive';

@Injectable()
export class GridTableLoadingService {
  wraps: { differ: IterableDiffer<any>; loading: SpinnerDirective; table: Table; }[] = [];
  subscriptions: Subscription[] = [];

  constructor(
    private _iterableDiffers: IterableDiffers,
  ) { }

  wrap(loading: SpinnerDirective, table: Table) {
    this.subscriptions.push(
      table.onLazyLoad.subscribe(() => {
        loading.forceShow();
      })
    );

    const differ = this._iterableDiffers.find([]).create(null);
    const wrap = {
      differ,
      loading,
      table,
    };
    this.wraps.push(wrap);

    return wrap;
  }

  tick() {
    this.wraps.forEach(wrap => {
      const changes = wrap.differ.diff(wrap.table.value);
      if (changes) {
        wrap.loading.forceHide();
      }
    });
  }

  unwrap() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
