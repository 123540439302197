import { Component } from '@angular/core';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { RoleRestService } from '../role-rest.service';

@Component({
  selector: 'app-role-list',
  templateUrl: 'role-list.component.html'
})
export class RoleListComponent extends BaseListBComponent<any> {
  tableColumns: IGridTableColumn[] = [
    {
      i18nLabel: 'ui.role.entity.roleName',
      field: 'name'
    }
  ];
  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(
    this.tableColumns
  );

  constructor(private _roleRest: RoleRestService) {
    super();
    this.componentId = 'RoleListComponent';
    this.headerTitle = 'ui.role.list.title';
    this.exportFileName = 'roles';
    this.exportDocumentTitle = 'ui.role.list.title';
    this.deleteRecordI18nLabel = 'confirm.any.delete.label';
    this.deleteRecordI18nDescription = 'confirm.any.delete.description';
    this.deleteRecordI18nSuccess = 'success.role.delete';

    this.headerButtons = [
      {
        type: 'custom',
        label: 'ui.role.list.button.create',
        color: 'primary-outline',
        routerLink: '/roles/create',
        permissions: ['role.create']
      }
    ];
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qOption = event.data.qOption;
        const qParams = Object.assign(
          {},
          event.data.qParams,
          qOption,
          this.qParams
        );

        return this._roleRest.findAll(qParams);
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'deleteRecord',
      handle: event => {
        const { _id } = event.data;
        return this._roleRest.delete(_id);
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService.callMultipleHooks([
          'RoleListComponent:workflowLoadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }
}
