import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { SystemMessageService } from '../../system-message/system-message.service';
import { ESystemMessageType, MSystemMessage } from '../../system-message/system-message.model';

@Component({
  selector: 'app-system-message-display-block',
  templateUrl: 'system-message-display-block.html'
})
export class SystemMessageDisplayBlockComponent implements OnInit {
  @Input() message: MSystemMessage;
  @Input() scope;
  @Input() service: SystemMessageService;
  @ViewChild('elAlert', { static: false }) elAlert: AlertComponent;

  alertType = '';
  targetIcon = '';
  targetTitle = '';

  ngOnInit() {
    switch (ESystemMessageType[this.message.options.type]) {
      case 'Success':
        this.alertType = 'success';
        this.targetIcon = 'far fa-check-circle';
        this.targetTitle = 'ui.alert.success.title';
        break;
      case 'Warning':
        this.alertType = 'warning';
        this.targetIcon = 'mi mi-info-outline';
        this.targetTitle = 'ui.alert.warning.title';
        break;
      case 'Error':
        this.alertType = 'danger';
        this.targetIcon = 'mi mi-block';
        this.targetTitle = 'ui.alert.error.title';
        break;
      default:
        this.alertType = 'info';
        this.targetIcon = 'mi mi-error-outline';
        this.targetTitle = 'ui.alert.info.title';
        break;
    }
  }

  onClosed() {
    this.service.removeMessage(this.message);
  }

  getSystemMessageMessageType(message: any) {
    if (_.isString(message)) {
      return 'string';
    } else if (message instanceof TemplateRef) {
      return 'templateRef';
    }
  }
}
