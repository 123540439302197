import * as _ from 'lodash';
import { Directive, Input, OnChanges, OnInit, SimpleChanges, ViewContainerRef } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
  selector: '[appFieldStates]'
})
export class FieldStatesDirective implements OnChanges, OnInit {
  @Input('appFieldStates') control: FormControl;
  @Input('appFieldElement') customElement: ViewContainerRef;

  constructor(
    private _viewContainerRef: ViewContainerRef,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'control') && !changes.control.previousValue && changes.control.currentValue) {
      this.init();
    }
  }

  ngOnInit() {
    if (this.control) {
      this.init();
    }
  }

  init() {
    this.setRequiredAppearance();

    this.control.statusChanges.subscribe(() => this.setRequiredAppearance());
  }

  get $element() {
    return this.customElement ? $(this.customElement.element.nativeElement) : $(this._viewContainerRef.element.nativeElement);
  }

  get hasRequiredValidation(): boolean {
    const _validators: any = this.control.validator && this.control.validator(new FormControl);
    return _validators && _validators.required;
  }

  setRequiredAppearance() {
    if (this.hasRequiredValidation) {
      this.$element.addClass('field-required');
    } else {
      this.$element.removeClass('field-required');
    }
  }
}
