import { Component, ElementRef, Input } from '@angular/core';
import { APP_CONST } from '../../../app.const';
import { BuybackRestService } from '../buyback-rest.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { CommonService } from '../../../libraries/libraries.module';
import { BuybackListComponent } from '../list/buyback-list.component';
import { AclService } from '../../../libraries/auth/acl.service';

@Component({
  selector: 'app-document-checking',
  templateUrl: 'document-checking.component.html',
  styleUrls: ['../buyback.component.scss', './document-checking.component.scss']
  // styleUrls: ['status-info-modal.component.scss']
})
export class DocumentChecking {
  @Input() data: any;
  @Input() disabled: boolean;
  form: FormGroup;
  photoPreviewModal: boolean = false;
  photoPreviewTitle: string = '';
  isOpen: boolean = false;
  today: string;
  uploadEndpoint = APP_CONST.API_FILE;
  vehicleAssessments: any = [
    {
      id: 1,
      category: 'Body',
      component: 'Cover body/Permukaan luar',
      decrease_value: -6
    },
    {
      id: 2,
      category: 'Body',
      component: 'Saddle/jok',
      decrease_value: -3
    },
    {
      id: 3,
      category: 'Body',
      component: 'Lamp & Reflector/ Lampu & Reflektor',
      decrease_value: -3
    },
    {
      id: 4,
      category: 'Body',
      component: 'Tire/Ban',
      decrease_value: -6
    },
    {
      id: 5,
      category: 'Body',
      component: 'Velg',
      decrease_value: -6
    },
    {
      id: 6,
      category: 'Body',
      component: 'Shockbreaker',
      decrease_value: -6
    },
    {
      id: 7,
      category: 'Body',
      component: 'Speedometer',
      decrease_value: -6
    },
    {
      id: 8,
      category: 'Electric',
      component: 'Switch/tombol',
      decrease_value: -3
    },
    {
      id: 9,
      category: 'Electric',
      component: 'Main Cabling / Kabel Body',
      decrease_value: -6
    },
    {
      id: 10,
      category: 'Supporting Tools',
      component: 'Rearview Mirror/Kaca spion',
      decrease_value: -2
    },
    {
      id: 11,
      category: 'Supporting Tools',
      component: 'Key/Kunci',
      decrease_value: -2
    },
    {
      id: 12,
      category: 'Supporting Tools',
      component: 'Remote & NFC',
      decrease_value: -2
    },
    {
      id: 13,
      category: 'Supporting Tools',
      component: 'Charger',
      decrease_value: -9
    },
    {
      id: 14,
      category: 'Battery',
      component: 'Test Ride',
      decrease_value: -15
    },
    {
      id: 15,
      category: 'Battery',
      component: 'Indicator',
      decrease_value: -10
    },
    {
      id: 16,
      category: 'Battery',
      component: 'Healthiness',
      decrease_value: -15
    }
  ];
  isLoading: boolean = false;

  groupedAssessments: { category: string; components: any[] }[] = [];

  documentTypes = [
    { label: 'STNK', key: 'stnk' },
    { label: 'BPKB', key: 'bpkb' },
    { label: 'INVOICE', key: 'invoice' },
    { label: 'FAKTUR', key: 'faktur' },
    { label: 'KTP', key: 'ktp' }
  ];

  canAccess(pageAction: string) {
    const userAccess: string[] = this._AclService?.exclusivePermissions;
    return userAccess.find(action => action === pageAction);
  }

  isPdfFile(fileUrl: string): boolean {
    return fileUrl?.toLowerCase().endsWith('.pdf');
  }

  openPdf(fileUrl: string): void {
    window.open(fileUrl, '_blank');
  }

  dateFormatter(date: string | Date): string {
    if (!date) return '';

    const parsedDate = typeof date === 'string' ? new Date(date) : date;

    if (isNaN(parsedDate.getTime())) {
      console.error('Invalid Date:', parsedDate);
      return '';
    }

    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    };

    const formattedDate = parsedDate.toLocaleString('en-GB', options);
    const [time, ...dateParts] = formattedDate.split(', ');

    return `${time} ${dateParts.join(' ')}`;
  }

  ngOnInit() {
    this.groupAssessmentsByCategory();
    this.form = this.fb.group({
      _id: [this.data._id],
      statusDocumentChecking: ['done'],
      actionType: [''],
      dataDokumen: this.fb.group({
        note: [''],
        stnk: this.fb.group({
          available: this.data.dataDokumen?.stnk?.available,
          uploaded_file_url: [this.data.dataDokumen?.stnk?.uploaded_file_url],
          uploaded_file_url_1: [this.data.dataDokumen?.stnk?.uploaded_file_url_1],
          status: [this.data.dataDokumen?.stnk?.status, this.data.dataDokumen?.stnk?.uploaded_file_url && Validators.required],
          last_updated: [this.data.dataDokumen?.stnk?.last_updated]
        }),
        bpkb: this.fb.group({
          available: this.data.dataDokumen?.bpkb?.available,
          uploaded_file_url: [this.data.dataDokumen?.bpkb?.uploaded_file_url],
          uploaded_file_url_1: [this.data.dataDokumen?.bpkb?.uploaded_file_url_1],
          uploaded_file_url_2: [this.data.dataDokumen?.bpkb?.uploaded_file_url_2],
          uploaded_file_url_3: [this.data.dataDokumen?.bpkb?.uploaded_file_url_3],
          status: [this.data.dataDokumen?.bpkb?.status, this.data.dataDokumen?.bpkb?.uploaded_file_url && Validators.required],
          last_updated: [this.data.dataDokumen?.bpkb?.last_updated]
        }),
        invoice: this.fb.group({
          available: this.data.dataDokumen?.invoice?.available,
          uploaded_file_url: [this.data.dataDokumen?.invoice?.uploaded_file_url],
          status: [this.data.dataDokumen?.invoice?.status, this.data.dataDokumen?.invoice?.uploaded_file_url && Validators.required],
          last_updated: [this.data.dataDokumen?.invoice?.last_updated]
        }),
        faktur: this.fb.group({
          available: this.data.dataDokumen?.faktur?.available,
          uploaded_file_url: [this.data.dataDokumen?.faktur?.uploaded_file_url],
          status: [this.data.dataDokumen?.faktur?.status, this.data.dataDokumen?.faktur?.uploaded_file_url && Validators.required],
          last_updated: [this.data.dataDokumen?.faktur?.last_updated]
        }),
        ktp: this.fb.group({
          available: this.data.dataDokumen?.ktp?.available,
          uploaded_file_url: [this.data.dataDokumen?.ktp?.uploaded_file_url],
          status: [this.data.dataDokumen?.ktp?.status, this.data.dataDokumen?.ktp?.uploaded_file_url && Validators.required],
          last_updated: [this.data.dataDokumen?.ktp?.last_updated]
        })
      })
    });

    const dataDokumen = this.form.get('dataDokumen');

    // Call to check the initial document statuses
    this.checkDocumentStatuses(this.data.dataDokumen);

    // Subscribe to changes in each document's status
    this.subscribeToStatusChanges();

    // Listen for value changes in the form
    dataDokumen?.valueChanges.subscribe(dataDokumen => {
      this.checkDocumentStatuses(dataDokumen);
    });

    this.trackStatusChanges();
  }

  // Function to mark invalid if 'on review'
  markInvalidIfOnReview() {
    const stnkStatus = this.form.get('dataDokumen.stnk.status')?.value;
    const bpkbStatus = this.form.get('dataDokumen.bpkb.status')?.value;
    const invoiceStatus = this.form.get('dataDokumen.invoice.status')?.value;
    const fakturStatus = this.form.get('dataDokumen.faktur.status')?.value;
    const ktpStatus = this.form.get('dataDokumen.ktp.status')?.value;

    // Check each status and mark as invalid if it is 'on review'
    if (stnkStatus === 'on review') {
      this.form.get('dataDokumen.stnk.status')?.setErrors({ invalidStatus: true });
    }
    if (bpkbStatus === 'on review') {
      this.form.get('dataDokumen.bpkb.status')?.setErrors({ invalidStatus: true });
    }
    if (invoiceStatus === 'on review') {
      this.form.get('dataDokumen.invoice.status')?.setErrors({ invalidStatus: true });
    }
    if (fakturStatus === 'on review') {
      this.form.get('dataDokumen.faktur.status')?.setErrors({ invalidStatus: true });
    }
    if (ktpStatus === 'on review') {
      this.form.get('dataDokumen.ktp.status')?.setErrors({ invalidStatus: true });
    }
  }

  subscribeToStatusChanges() {
    const documents = ['stnk', 'bpkb', 'invoice', 'faktur', 'ktp'];
  
    documents.forEach(doc => {
      const statusControl = this.form.get(`dataDokumen.${doc}.status`);
      if (statusControl) {
        statusControl.valueChanges.subscribe(() => {
          this.checkDocumentStatuses(this.form.get('dataDokumen')?.value);
        });
      }
    });
  }

  checkDocumentStatuses(dataDokumen: any) {
    // Check if any document has status 'reject'
    const hasReject = Object.keys(dataDokumen).some(key => {
      const document = dataDokumen[key];
      return document.status === 'reject';
    });

    // Get the control for statusDocumentChecking
    const statusControl = this.form.get('statusDocumentChecking');
    const actionType = this.form.get('actionType');
    // Check if the form control exists before setting the value
    if (statusControl) {
      if (hasReject) {
        statusControl.setValue('revise');
        actionType?.setValue('pengecekan dokumen');
      } else {
        statusControl.setValue('done');
      }
    }
  }

  trackStatusChanges() {
    const dataDokumen = this.form.get('dataDokumen') as FormGroup;

    // List of document keys
    const documentKeys = ['stnk', 'bpkb', 'invoice', 'faktur', 'ktp'];

    documentKeys.forEach(key => {
      const documentFormGroup = dataDokumen.get(key) as FormGroup;

      // Listen for status changes in each document
      documentFormGroup.get('status')?.valueChanges.subscribe(newStatus => {
        // When status changes, set last_updated to the current date and time
        documentFormGroup.get('last_updated')?.setValue(new Date().toISOString());
      });
    });
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  }

  async renderPresignedUrlImage(payload: any, elementId: string) {
    await this._BuybackRest.getPresignedUrl(payload).subscribe(
      data => {
        const photoPreview = this.el.nativeElement.querySelector(elementId);

        console.log(payload);

        if (photoPreview) {
          // photoPreview.src = data.url;
          // photoPreview.href = data.url;
          photoPreview.src = payload?.storageFileName;
          photoPreview.href = payload?.storageFileName;
        }
      },
      error => {
        const photoPreview = this.el.nativeElement.querySelector(elementId);
        if (photoPreview) {
          photoPreview.src = '';
        }
      }
    );
  }

  closePhotoPreviewModal() {
    this.photoPreviewModal = false;
  }

  async openPhotoPreview(url, title) {
    const payload = { storageFileName: url };
    await this.renderPresignedUrlImage(payload, '#js-photoPreview');
    this.photoPreviewModal = true;
    this.photoPreviewTitle = title;
  }

  groupAssessmentsByCategory() {
    const groups = this.vehicleAssessments.reduce((acc, item) => {
      const category = item.category;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(item);
      return acc;
    }, {} as { [key: string]: any[] });

    this.groupedAssessments = Object.keys(groups).map(category => ({
      category,
      components: groups[category]
    }));
  }

  constructor(
    private el: ElementRef,
    private _BuybackRest: BuybackRestService,
    private fb: FormBuilder,
    public _buybackListComponent: BuybackListComponent,
    private _AclService: AclService
  ) {
    this.today = new Date().toISOString().split('T')[0]; // Format the date as YYYY-MM-DD
  }

  onUpdateStatusDocumentChecking() {
    this.markInvalidIfOnReview();
    if (this.form.invalid) {
      // Create an alert message
      const alertMessage = `Tolong lengkapi status`;

      // Show SweetAlert2 alert
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: alertMessage,
        confirmButtonText: 'OK'
      });
      return;
    }
    // this.generatePayload();
    if (!this.form.valid) {
      CommonService.markAsDirty(this.form);
    } else {
      Swal.fire({
        title: 'Apakah anda yakin ingin mengupdate status pengecekan dokumen?',
        text: 'Pastikan semua data terisi dengan benar',
        icon: 'question',
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.updateStatusDocumentChecking();
        }
      });
    }
  }

  async updateStatusDocumentChecking() {
    if (!this.form.valid) {
      CommonService.markAsDirty(this.form);
    } else {
      this.isLoading = true;

      await this._BuybackRest
        .update(this.form.value)
        .toPromise()
        .then((response: any) => {
          if (!!response?.invalid) {
            Swal.fire({
              icon: 'warning',
              title: 'Update Status Failed',
              confirmButtonText: 'OK'
            });
          } else {
            Swal.fire('Update Status Success!', '', 'success');

            this._buybackListComponent.callHookDirectly('init');
            this.closeModal();
            // setTimeout(() => {
            //   window.location.reload();
            // }, 1000)
          }
        })
        .catch(error => {
          console.log(error);
          Swal.fire('Oops..', 'Something went wrong!', 'error');
        });
      this.isLoading = false;
    }
  }

  openModal() {
    this.isOpen = true;
  }

  closeModal() {
    this.isOpen = false;
  }

  clearForm() {
    console.log('this function is for clear form');
  }

  submitPengajuan() {
    alert('submission submitted!');
    this.closeModal();
    this.clearForm();
  }
}
