import { InjectorService } from '../common/injector.service';
import { WindowTabService } from './window-tab.service';

export class BroadcastChannelEvent {
  private _windowTabService = InjectorService.get<WindowTabService>(WindowTabService);

  timestamp: Date = new Date();
  p2pSourceId = this._windowTabService.tabId;

  constructor(public name: string, public data: any, public sendToLeaderOnly: boolean = false, public p2pTargetId?: number) { }
}
