import { AfterViewInit, Directive, DoCheck, Input, IterableDiffer, IterableDiffers, KeyValueDiffer, KeyValueDiffers, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AutoComplete } from 'primeng/autocomplete';
import { AutocompleteService } from '../common/autocomplete.service';

@Directive({
  selector: '[appWrapperAC]',
  exportAs: 'appWrapperAC'
})
export class WrapperAutocompleteDirective implements AfterViewInit, DoCheck, OnInit {
  @Input('appWrapperAC') ac: AutoComplete;
  @Input('appWrapperACClearOnChange') clearOnChange: boolean = true;
  @Input('appWrapperACLimit') limit: number = 30;
  @Input('appWrapperDisableClearButton') disableClearButton: boolean = false;

  differPanelVisible: KeyValueDiffer<string, any>;
  differSuggestions: IterableDiffer<any>;
  selectFirstItem: boolean = false;
  selectFirstItemIfOnlyOneResult: boolean = false;

  limitDescTopOffset: number;

  isPrependingNullSuggestions: boolean;

  previousInputValue: string;
  currentInputValue: string;
  inputValueHasChanged: boolean = false;

  constructor(
    private _autocomplete: AutocompleteService,
    private _iterableDiffers: IterableDiffers,
    private _keyValueDiffers: KeyValueDiffers,
    private _translate: TranslateService,
  ) {
    this.differSuggestions = this._iterableDiffers.find([]).create(null);
  }

  ngDoCheck() {
    if (this.ac && !this.ac.multiple) {
      const changes = this.differSuggestions.diff(this.ac.suggestions);
      if (changes) {
        this.handleSuggestionsChange();
      }

      if (this.limit && this.differPanelVisible) {
        const changesPanelVisible = this.differPanelVisible.diff({ visible: Boolean(this.ac.overlay) });
        if (changesPanelVisible) {
          if (this.ac.overlayVisible) {
            this.appendLimitDescription();
          }
        }
      }
    }
  }

  ngOnInit() {
    if (this.ac && !this.ac.multiple) {
      this.ac.onSelect.subscribe(() => {
        this.selectFirstItem = false;
        this.selectFirstItemIfOnlyOneResult = false;
      });

      this.ac.autoHighlight = true;

      if (this.limit) {
        this.differPanelVisible = this._keyValueDiffers.find({ visible: Boolean(this.ac.overlay) }).create();

        this.adjustLimitDescriptionEl();
      }
    }
  }

  ngAfterViewInit() {
    if (this.ac && !this.ac.multiple) {
      const $el = $(this.ac.inputEL.nativeElement);
      $el.attr('autocomplete', 'new-password');

      $el.on('blur', event => {
        const inputValue = event.target.value;
        if (inputValue && !this.ac.value) {
          this.selectFirstItem = true;
        }
      });

      $el.on('keydown', event => {
        const inputValue = event.target.value;
        this.previousInputValue = this.currentInputValue;
        this.currentInputValue = inputValue;
        this.inputValueHasChanged = this.previousInputValue !== this.currentInputValue;

        if (this.inputValueHasChanged) {
          this.selectFirstItem = false;
          this.selectFirstItemIfOnlyOneResult = false;

          if (this.clearOnChange && event.keyCode !== 13) {
            this._autocomplete.silentlyClearValue(this.ac);
          }
        }

        if (event.keyCode === 13 && !this.ac.overlayVisible) {
          this.selectFirstItem = true;
          this.selectFirstItemIfOnlyOneResult = true;
        }
      });

      if (!this.disableClearButton) {
        const clearButton = $('<a/>');
        // clearButton.text('x');
        clearButton.click(() => {
          this._autocomplete.clearValue(this.ac);
        });
        clearButton.css({ 'font-size': '12px', 'color': '#757a89' });
        clearButton.addClass('ui-autocomplete-custom-clear fa fa-close');
        $el.after(clearButton).css('width', 'calc(100% - 30px + 2px)');
      }
    }
  }

  handleSuggestionsChange() {
    this.detectPrependNullSuggestions();

    if (this.selectFirstItem) {
      const targetItem = this.getTargetSuggestion();
      if (targetItem) {
        this.ac.highlightOption = targetItem;
        this.ac.selectItem(targetItem);
        this.ac.hide();
      }
    }

    this.selectFirstItem = false;
    this.selectFirstItemIfOnlyOneResult = false;
  }

  getTargetSuggestion() {
    let targetItem;

    let targetIndexIfOnlyOneResult = 0;
    if (this.isPrependingNullSuggestions) {
      targetIndexIfOnlyOneResult = 1;
    }

    if (
      (this.selectFirstItemIfOnlyOneResult && this.ac.suggestions && this.ac.suggestions.length === targetIndexIfOnlyOneResult + 1) ||
      (!this.selectFirstItemIfOnlyOneResult && this.ac.suggestions && this.ac.suggestions.length > targetIndexIfOnlyOneResult)
    ) {
      targetItem = this.ac.suggestions[targetIndexIfOnlyOneResult];
    }

    return targetItem;
  }

  detectPrependNullSuggestions() {
    this.isPrependingNullSuggestions = this.ac.suggestions && this.ac.suggestions.length && this.ac.suggestions[0] === null;
  }

  appendLimitDescription() {
    const $panelEl = $(this.ac.overlay);
    if (!$panelEl.hasClass('panel-limited')) {
      $panelEl.addClass('panel-limited');
    }
    let $limitDescriptionEl = $panelEl.find('.limit-description');
    if (!$limitDescriptionEl.length) {
      $limitDescriptionEl = $(this.ac.overlay).append(`
        <p class="limit-description">${this._translate.instant('ui.wrapperAutocomplete.limitDescription', { limit: this.limit })}</p>
      `);
    }
  }

  adjustLimitDescriptionEl() {
    const $panelEl = $(this.ac.overlay);
    $panelEl.on('scroll', () => {
      const $limitDescriptionEl = $panelEl.find('.limit-description');
      if ($limitDescriptionEl.length) {
        if (!this.limitDescTopOffset) {
          this.limitDescTopOffset = $limitDescriptionEl.scrollTop() - 1;
        }
        const panelElScrollTop = $panelEl.scrollTop();
        const topOffsetDeviation = this.limitDescTopOffset - panelElScrollTop;
        $limitDescriptionEl.css('bottom', topOffsetDeviation);
      }
    });
  }
}

