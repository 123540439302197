

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  blobToBase64(file: Blob) {
    return Observable.create(observer => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        observer.next(reader.result);
        observer.complete();
      }, false);

      if (file) {
        reader.readAsDataURL(file);
      }
    });
  }

  removeQueryStringFromUrl(fileUrl: string) {
    return fileUrl.split('?').shift();
  }
}
