import * as moment from 'moment';

export class CentralCacheStorageOptions {
  flushAfterMs: number = 3 * 60 * 1000;
}

export class CentralCacheStorage {
  cacheActivatedAt: number = Date.now();
  timer: any;

  private _data: any;
  get data() {
    return this._data;
  }
  set data(newData: any) {
    this._data = newData;

    if (!this._data && newData) {
      this.cacheActivatedAt = Date.now();
      this.tickFlush();
    }
  }

  constructor(
    public id: string,
    public options: CentralCacheStorageOptions = new CentralCacheStorageOptions(),
  ) {
    if (this.options.flushAfterMs) {
      this.tickFlush();
    }
  }
  
  tickFlush() {
    if (this.cacheActivatedAt) {
      if (this.timer) {
        clearTimeout(this.timer);
      }

      const remainingMs = moment(this.cacheActivatedAt).add(this.options.flushAfterMs, 'ms').diff(Date.now());
      if (remainingMs) {
        this.timer = setTimeout(() => {
          this.data = undefined;
        }, remainingMs);
      } else {
        this.data = undefined;
      }
    }
  }
}