import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appTpl]',
})
export class ViewUtilityTemplateDirective {
  @Input() type: string;

  @Input('appTpl') name: string;

  constructor(public template: TemplateRef<any>) { }

  getType(): string {
    return this.name;
  }
}
