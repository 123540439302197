import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';

import { ApiQueryOption } from '../http/api-query-option.model';

@Injectable()
export class GridTableFilterService {
  parse(
    queries: {
      default?: string;
      [key: string]: string;
    },
    filterMap: IGridTableFilterMap,
  ): IGridTableFilterParsed {
    const rawValues = this.map(queries, filterMap);

    const qParams = {};
    const qBody = {
      or: [],
      and: []
    };
    _.forEach(filterMap, (map, field) => {
      const targetFieldForParameter = _.get(map, 'field', field);
      const query = _.get(rawValues, field, undefined);

      if (!_.isUndefined(query)) {
        const targetFilter = _.get(map, 'targetFilter', 'body');

        if (targetFilter === 'body') {
          const defaultFilterObj: IApiQueryOptionFilterValue = {
            field: targetFieldForParameter,
            operator: 'contains',
            value: query,
            ignoreCase: true
          };

          const filterObj = _.isObject(map) && !_.isArray(map) ? Object.assign(defaultFilterObj, map) : defaultFilterObj;

          let group = 'or';
          if (_.isObject(map) && !_.isArray(map) && _.has(map, 'group')) {
            group = (<any>map).group;
          }
          qBody[group].push(filterObj);
        } else {
          _.set(qParams, targetFieldForParameter, query);
        }
      }
    });

    return {
      qBody,
      qParams,
    };
  }

  appendQBodyFilters(
    qOption: ApiQueryOption,
    customFilters?: { [key: string]: IApiQueryOptionFilterValue[] },
  ) {
    if (_.size(customFilters)) {
      if (customFilters.or.length) {
        qOption.filter.push({
          filterValues: customFilters.or
        });
      }


      customFilters.and.forEach(filter => {
        qOption.filter.push({
          filterValues: [filter]
        });
      });
    }
  }

  private map(
    queries: {
      default?: string;
      [key: string]: string;
    },
    filterMap: IGridTableFilterMap
  ) {
    const rawValues = {};

    _.forEach(filterMap, (map, field) => {
      const targetVar = _.get(map, 'targetVar');

      let rawValue = _.get(queries, field, _.get(rawValues, 'default', _.get(queries, 'default')));
      switch (targetVar) {
        case 'boolean':
          rawValue = Boolean(rawValue);
          break;
        case 'date':
          if (rawValue) {
            const dateFormat = _.get(map, 'dateFormat', 'date');
            const dateSource = moment(rawValue);

            if (dateSource.isValid()) {
              switch (dateFormat) {
                case 'date':
                  rawValue = moment(rawValue).toDate();
                  break;
                default:
                  rawValue = moment(rawValue).format(dateFormat);
                  break;
              }
            }
          }
          break;
        case 'arrstring':
          if (rawValue === '') {
            rawValue = _.castArray();
          } else {
            rawValue = _(rawValue).castArray().map(_.toString).value();
          }
          break;
        case 'arrstringjoin':
          const targetVarSeparator = _.get(map, 'targetVarSeparator', ',');
          rawValue = _(rawValue).castArray().map(_.toString).value().join(targetVarSeparator);
          break;
        default:
          rawValue = _.toString(rawValue);
          break;
      }

      if (!_.isUndefined(rawValue) && rawValue) {
        _.set(rawValues, field, rawValue);
      }
    });

    return rawValues;
  }
}
