import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ListboxModule } from 'primeng/listbox';
import { TableModule } from 'primeng/table';
import { FormgenModule } from '../../libraries/formgen/formgen.module';
import { LibrariesModule } from '../../libraries/libraries.module';
import { SharedModule } from '../../libraries/shared.module';
import { UserCreateComponent } from './create/user-create.component';
import { UserFormComponent } from './form/user-form.component';
import { UserListComponent } from './list/user-list.component';
import { UserRestService } from './user-rest.service';
import { UserRoutingModule } from './user-routing.module';
import { UserUpdateComponent } from './update/user-update.component';
import { UserResetComponent } from './reset/user-reset.component';

export const PROVIDERS = [
  UserRestService,
];

@NgModule({
  imports: [
    UserRoutingModule,
    BsDropdownModule,
    CommonModule,
    FlexLayoutModule,
    FormgenModule,
    FormsModule,
    ListboxModule,
    LibrariesModule,
    PaginationModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    TableModule,
    TranslateModule,
  ],
  declarations: [
    UserCreateComponent,
    UserFormComponent,
    UserListComponent,
    UserUpdateComponent,
    UserResetComponent
  ],
})
export class UserModule { }
