import { AgmCoreModule } from '@agm/core';
import { HttpClientModule } from '@angular/common/http';
import { InjectionToken, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConnectionBackend, HttpModule, XHRBackend } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { LayoutsModule } from './layouts/layouts.module';
import { TranslateLoaderService } from './libraries/common/translate-loader.service';
import { LibrariesModule } from './libraries/libraries.module';
import { PROVIDERS as RoleProviders } from './main/role/role.module';
import { PROVIDERS as UserProviders } from './main/user/user.module';
import { PROVIDERS as NewsProviders } from './main/news/news.module';
import { PROVIDERS as FaqProviders } from './main/faq/faq.module';
import { PROVIDERS as NewsCategoriesProviders } from './main/news-categories/news-categories.module';
import { PROVIDERS as LocationProviders } from './main/location/location.module';
import { PROVIDERS as BannerProviders } from './main/banner/banner.module';
import { PROVIDERS as SubsidiProviders } from './main/subsidi/subsidi.module';
import { PROVIDERS as SparepartProviders } from './main/product/sparepart/product.module';
import { PROVIDERS as MotorcycleProviders } from './main/product/motorcycle/product.module';
import { PROVIDERS as SbgMitraProviders } from './main/sgb-mitra/sgb-mitra.module';
import { PROVIDERS as ContactProviders } from './main/contact/contact.module';
import { PROVIDERS as CustomerProviders } from './main/customer/customer.module';
import { PROVIDERS as DashboardProviders } from './main/dashboard/dashboard.module';
import { PROVIDERS as DealerProvider } from './main/dealer/dealer.module';
import { PROVIDERS as SPKProvider } from './main/spk/spk.module';
import { PROVIDERS as SPKCashProvider } from './main/spk-cash/spk-cash.module';
import { PROVIDERS as SPKIndentProvider } from './main/spk-indent/spk-indent.module';
import { PROVIDERS as ProductTypeVariantProvider } from './main/product-type-variant/product-type-variant.module';
import { PROVIDERS as SPKBatteryModule } from './main/spk-battery/spk-battery.module';
import { PROVIDERS as BatteryModule } from './main/battery/battery.module';
import { PROVIDERS as PromoProviders } from './main/promo/promo.module';
import { PROVIDERS as DealerProviders } from './main/partnership/partnership.module';
import { PROVIDERS as PartnershipProviders } from './main/partnership/partnership.module';
import { PROVIDERS as PartnershipBusinessProviders } from './main/partnership-business/partnership-business.module';
import { PROVIDERS as AffiliatorProviders } from './main/affiliator/affiliator.module';
import { PROVIDERS as AffiliatorClaimProviders } from './main/affiliator-claim/affiliator-claim.module';
import { PROVIDERS as AgentBfiProviders } from './main/agent-bfi/agent-bfi.module';
import { PROVIDERS as AgentBfiClaimProviders } from './main/agent-bfi-claim/agent-bfi-claim.module';
import { PROVIDERS as MarketingSupportProvider } from './main/marketing-support/marketing-support.module';
import { PROVIDERS as PartnershipGentariProvider } from './main/partnership-gentari/partnership-gentari.module';
import { PROVIDERS as BuybackProvider } from './main/buyback/buyback.module';
import { PROVIDERS as TradeinProvider } from './main/tradein/buyback.module';
import { PROVIDERS as GojekSwadayaProvider } from './main/gojek-swadaya/gojek-swadaya.module';
import { PROVIDERS as SPKBatteryRentProvider } from './main/spk-battery-rent/spk-battery-rent.module';
import { PROVIDERS as BatteryRentProvider } from './main/battery-rent/battery-rent.module';
import { PROVIDERS as BluebirdRegulerProvider } from './main/bluebird-reguler/bluebird-reguler.module';
import { PROVIDERS as BluebirdCreditBriProviders } from './main/bluebird-credit-bri/bluebird-credit-bri.module';

export const REDUCERS_TOKEN = new InjectionToken<ActionReducerMap<{}>>(
  'Registered Reducers'
);
export const REDUCERS: ActionReducerMap<{}> = {};

export function HttpLoaderFactory(
  translatorLoaderService: TranslateLoaderService
) {
  return {
    getTranslation(lang: string): Observable<Object> {
      return translatorLoaderService.load(lang);
    }
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AlertModule.forRoot(),
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    FormsModule,
    HttpClientModule,
    HttpModule,
    LayoutsModule,
    LibrariesModule.forRoot(),
    NgxWebstorageModule.forRoot(),
    PaginationModule.forRoot(),
    ReactiveFormsModule,
    StoreModule.forRoot(REDUCERS_TOKEN),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [TranslateLoaderService]
      }
    })
  ],
  providers: [
    { provide: ConnectionBackend, useClass: XHRBackend },
    { provide: REDUCERS_TOKEN, useValue: REDUCERS },
    ...RoleProviders,
    ...UserProviders,
    ...NewsProviders,
    ...FaqProviders,
    ...NewsCategoriesProviders,
    ...LocationProviders,
    ...BannerProviders,
    ...SubsidiProviders,
    ...SparepartProviders,
    ...MotorcycleProviders,
    ...SbgMitraProviders,
    ...ContactProviders,
    ...CustomerProviders,
    ...DashboardProviders,
    ...DealerProvider,
    ...SPKProvider,
    ...SPKCashProvider,
    ...SPKIndentProvider,
    ...SPKBatteryModule,
    ...ProductTypeVariantProvider,
    ...BatteryModule,
    ...PromoProviders,
    ...DealerProviders,
    ...PartnershipProviders,
    ...PartnershipBusinessProviders,
    ...AffiliatorProviders,
    ...AffiliatorClaimProviders,
    ...AgentBfiProviders,
    ...AgentBfiClaimProviders,
    ...MarketingSupportProvider,
    ...PartnershipGentariProvider,
    ...BuybackProvider,
    ...TradeinProvider,
    ...GojekSwadayaProvider,
    ...SPKBatteryRentProvider,
    ...BatteryRentProvider,,
    ...BluebirdRegulerProvider,
    ...BluebirdCreditBriProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
