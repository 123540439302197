import { Injectable } from '@angular/core';

@Injectable()
export class DateTimezoneService {
	convertUtctoLocalTimezone(dateUtc: Date): Date {
		const offset = (new Date().getTimezoneOffset() * -1);
		dateUtc.setMinutes(dateUtc.getMinutes() + offset);
		return dateUtc;
	}
}
