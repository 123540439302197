import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { CommonService } from './common.service';

@Injectable()
export class TextParseService {
  parse(text: string, parserOptions: IParseMap) {
    text = CommonService.removeNonUnicodeCharacterFromString(text);

    const results = {};
    const chunks = text.split(parserOptions.explodeOperator);
    chunks.forEach(chunk => {
      const lastField = _.last(_.keys(results));
      const content = this.parseContent(chunk, lastField, parserOptions);
      if (content) {
        const parseOption = parserOptions.fields[content.field];
        if (parseOption.array) {
          results[content.field] = _.castArray(results[content.field]).filter(_.identity);
          results[content.field].push(content.output);
        } else if (_.isString(content.output) && content.output) {
          results[content.field] = (results[content.field] || '') + content.output;
        } else {
          results[content.field] = content.output;
        }
      }
    });

    this.normalizeResults(results);

    return results;
  }

  private parseContent(chunk: string, lastField: string, parserOptions: IParseMap) {
    let field;
    let output;

    _.forEach(parserOptions.fields, (fieldOption, fieldName) => {
      if (field) {
        return;
      }

      const pattern = _.first(
        _.filter(
          fieldOption.patterns.map(regEx => {
            if (regEx.test(chunk)) {
              return regEx;
            }
            return false;
          })
          , _.identity
        )
      );
      if (pattern) {
        field = fieldName;
        output = chunk.replace(pattern, '').trim();
      }
    });

    if (!field) {
      field = lastField;
      output = chunk.trim();
    }

    const mapLine: Function = <any>_.get(parserOptions.fields, `${field}.mapLine`);
    if (output && mapLine) {
      output = mapLine(output);
    }

    return field ? { field, output } : false;
  }

  private normalizeResults(results) {
    _.forEach(results, (result, fieldName) => {
      if (_.isArray(result)) {
        results[fieldName] = _.filter(result, _.identity);
      }
    });
  }
}
