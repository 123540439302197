import { Directive, Input, OnInit, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appFgenFormArray]',
})
export class FormgenFormArrayDirective implements OnInit {
  @Input() formArrayIndex: number;
  @Input() formArrayName: string;

  availableTemplates: TemplateRef<any>[] = [];

  ngOnInit() {
  }
}
