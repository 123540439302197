import { Component, Input } from "@angular/core";
import { BuybackListComponent } from "../list/buyback-list.component";
import { CommonService } from "../../../libraries/libraries.module";
import { APP_CONST } from "../../../app.const";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import Swal from "sweetalert2";
import { BuybackRestService } from "../buyback-rest.service";
import * as pdfMake from 'pdfmake';
import * as moment from 'moment';
import { AclService } from "../../../libraries/auth/acl.service";

@Component({
  selector: 'app-appraisal-doc-modal',
  templateUrl: 'appraisal-doc-modal.component.html',
  styleUrls: ['../buyback.component.scss']
})
export class AppraisalDocModalComponent {
  @Input() buyback: any;
  @Input() disabled: boolean;

  ngOnInit() {
    this.form = this.fb.group({
      _id: [this.buyback._id],
      statusPengajuanBuyback: ['on process'],
      actionType: ['pengajuan buyback'],
      approvalDokumen: this.fb.group({
          note: [''],
          document_file_url: ['', Validators.required],
          invoice_file_url: [''],
        })
      
    })
    this.formPembayaran = this.fb.group({
      _id: [this.buyback._id],
      statusPengajuanBuyback: ['', Validators.required],
      statusDocumentChecking: [''],
      actionType: ['approval buyback'],
      approvalDokumen: this.fb.group({
          note: [''],
          document_file_url: [this.buyback.approvalDokumen?.document_file_url, Validators.required],
          invoice_file_url: [this.buyback.approvalDokumen?.invoice_file_url],
        })
    })

     // Subscribe to changes in the statusPengajuanBuyback field
     this.formPembayaran.get('statusPengajuanBuyback')?.valueChanges.subscribe((value) => {
      if (value === 'done') {
        this.formPembayaran.get('statusDocumentChecking')?.setValue('on process');
      } else {
        this.formPembayaran.get('statusDocumentChecking')?.setValue(''); // Or whatever default you want
      }
    });
  }

  constructor(
    private fb: FormBuilder,
    public _buybackListComponent: BuybackListComponent,
    private _BuybackRest: BuybackRestService,
    private _AclService: AclService
  ) {}

  form: FormGroup;
  formPembayaran: FormGroup;
  isModalOpen: boolean = false;
  step: number = 1;
  uploadEndpoint = APP_CONST.API_FILE;
  isLoading: boolean = false;
  pdfSrc: any;  // Variable to hold the PDF source for preview
  isPreviewVisible: boolean = false;  // Flag to control the preview visibility

  isPdfFile(fileUrl: string): boolean {
    return fileUrl?.toLowerCase().endsWith('.pdf');
  }
  
  openPdf(fileUrl: string): void {
    window.open(fileUrl, '_blank');
  }

  formatToRupiah(amount) {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(amount);
  }

  priceBeforeTax(amount) {
    return amount / 111 * 100;
  }

  downloadPdf() {
    const documentDefinition = {
      content: [
        {
          text: 'FORMULIR PENGAJUAN BUYBACK VOLTA',
          style: 'header',
          alignment: 'center',
          bold: true
        },
        {
          text: `No Dokumen: ${this.buyback.noDokumen}`,
          style: 'subheader',
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },
        {
          text: 'Yang bertandatangan dibawah ini :',
          style: 'sectionHeader'
        },
        {
          columns: [
            [
              { text: 'Nama', width: '20%' },
              { text: 'NIK', width: '20%' },
              { text: 'Alamat Sesuai KTP', width: '20%' },
              { text: 'Alamat Sesuai Domisili (jika alamat berbeda)', width: '20%' }
            ],
            [
              { text: `: ${this.buyback.dataPemilikUnitMotor.nama}`, width: '80%' },
              { text: `: ${this.buyback.dataPemilikUnitMotor.nik}`, width: '80%' },
              { text: `: ${this.buyback.dataPemilikUnitMotor.alamatSesuaiKtp}`, width: '80%' },
              { text: `: ${this.buyback.dataPemilikUnitMotor.alamatSesuaiDomisili}`, width: '80%' }
            ]
          ],
          columnGap: 10,
          margin: [0, 0, 0, 20]
        },
        {
          text: 'Dengan sadar bermaksud untuk melakukan penjualan unit motor bekas pakai milik pribadi sebagai berikut',
          style: 'sectionHeader'
        },
        {
          columns: [
            [
              { text: 'Model Volta', width: '20%' },
              { text: 'Warna', width: '20%' },
              { text: 'VIN', width: '20%' },
              { text: 'TNKB', width: '20%' }
            ],
            [
              { text: `: ${this.buyback.unitLama.modelVolta}`, width: '80%' },
              { text: `: ${this.buyback.unitLama.warna}`, width: '80%' },
              { text: `: ${this.buyback.unitLama.vin}`, width: '80%' },
              { text: `: ${this.buyback.unitLama.tnkb}`, width: '80%' }
            ]
          ],
          columnGap: 10,
          margin: [0, 0, 0, 20]
        },
        {
          text: 'Menyatakan bahwa :',
          style: 'sectionHeader'
        },
        {
          ul: [
            'Unit motor adalah milik pribadi',
            'Unit motor sudah lunas dari cicilan pembiayaan dan tidak dalam agunan pembiayaan',
            'Unit motor tidak mengalami kecelakaan transportasi dan atau melanggar tata tertib lalu lintas',
            'Pajak masih dalam kondisi aktif minimal 2 bulan dan lunas dari biaya tilang',
            'Bersedia menaati segala syarat & ketentuan yang berlaku dari transaksi jual beli ini.',
            'Bersedia untuk mempertanggung jawabkan didepan hukum apabila terbukti melakukan pelanggaran dan/atau kecurangan'
          ],
          margin: [0, 0, 0, 20]
        },
        {
          text: 'Telah menyepakati bahwa nilai jual dari unit motor bekas pakai adalah sebagai berikut',
          style: 'sectionHeader'
        },
        {
          columns: [
            [
              { text: 'Harga Sebelum Pajak PPN', width: '50%' },
              { text: 'Harga Termasuk Pajak PPN', width: '50%' }
            ],
            [
              { text: `: ${this.formatToRupiah(this.priceBeforeTax(this.buyback.totalAppraisal))}`, width: '50%' },
              { text: `: ${this.formatToRupiah(this.buyback.totalAppraisal)}`, width: '50%' }
            ]
          ],
          columnGap: 10,
          margin: [0, 0, 0, 20]
        },
        {
          columns: [
            { text: 'Pemilik Motor / Penjual', alignment: 'center', width: '50%', style: 'sectionHeader' },
            { text: 'Pembeli / Yang Mewakili', alignment: 'center', width: '50%', style: 'sectionHeader' }
          ],
          margin: [0, 20, 0, 80]
        },
        {
          columns: [
            {
              width: '50%',
              stack: [
                { text: 'Tanda tangan sesuai KTP', alignment: 'center', style: 'subheader' },
                { text: `Nama : ...........................`, alignment: 'center' },
                { text: `Tanggal : ${moment(this.buyback.tanggal).format('DD MMMM YYYY')}`, alignment: 'center', margin: [0, 10] }
              ]
            },
            {
              width: '50%',
              stack: [
                { text: 'Tanda tangan sesuai KTP', alignment: 'center', style: 'subheader' },
                { text: `Nama : ...........................`, alignment: 'center' },
                { text: `Tanggal : ${moment(this.buyback.tanggal).format('DD MMMM YYYY')}`, alignment: 'center', margin: [0, 10] }
              ]
            }
          ],
          columnGap: 10,
          margin: [0, 0, 0, 80]
        }
      ],
      styles: {
        header: {
          fontSize: 14,
          bold: true
        },
        subheader: {
          fontSize: 10,
          bold: false,
          margin: [0, 10, 0, 10]
        },
        sectionHeader: {
          fontSize: 12,
          bold: true,
          margin: [0, 10, 0, 5]
        }
      }
    };

    pdfMake.createPdf(documentDefinition).download(`Dokumen_Appraisal_${this.buyback.noDokumen}.pdf`);
  }

  closePreview() {
    this.isPreviewVisible = false;
    this.pdfSrc = null;  // Clear the PDF source
  }

  onImageFinishUploaded(image, inputName: string) {
    this.form.patchValue({
      approvalDokumen: {
        [inputName]: image?.fileUrl,
      }
    });
  }

  onSubmitAppraisalDoc() {
    if (this.form.invalid) {
     
      console.log(this.form.value);
      // Create an alert message
      const alertMessage = `Data belum lengkap`;
      
      // Show SweetAlert2 alert
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: alertMessage,
        confirmButtonText: 'OK'
      });
      return;
    }
    // this.generatePayload();
    if (!this.form.valid) {
      CommonService.markAsDirty(this.form);
    } else {
      Swal.fire({
        title: 'Apakah anda yakin ingin mengsubmit dokumen?',
        text: 'Pastikan dokumen sudah ditandatangani',
        icon: 'question',
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.submitAppraisalDoc();
          console.log(this.form.value);
        }
      });
    }
  }

  async submitAppraisalDoc() {
    if (!this.form.valid) {
      CommonService.markAsDirty(this.form);
    } else {
      this.isLoading = true;

      await this._BuybackRest
        .update(this.form.value)
        .toPromise()
        .then((response: any) => {
          if (!!response?.invalid) {
            Swal.fire({
              icon: 'warning',
              title: 'Submit Dokumen Failed',
              confirmButtonText: 'OK'
            });
          } else {
            Swal.fire(
              'Submit Dokumen Success!',
              '',
              'success'
            )

            this._buybackListComponent.callHookDirectly('init');
            this.closeModal();
            this.step = 1;
            // setTimeout(() => {
            //   window.location.reload();
            // }, 1000)
          }
        })
        .catch(error => {
          console.log(error)
          Swal.fire(
            'Oops..',
            'Something went wrong!',
            'error'
          )
        });
      this.isLoading = false;
    }
  }

  onUpdateStatusPembayaran() {
    if (this.formPembayaran.invalid) {
      console.log(this.formPembayaran.value);
      // Create an alert message
      const alertMessage = `Data belum lengkap`;
      
      // Show SweetAlert2 alert
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: alertMessage,
        confirmButtonText: 'OK'
      });
      return;
    }
    // this.generatePayload();
    if (!this.formPembayaran.valid) {
      CommonService.markAsDirty(this.formPembayaran);
    } else {
      Swal.fire({
        title: 'Apakah anda yakin ingin mengupdate status pembayaran?',
        text: '',
        icon: 'question',
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.updateStatusPembayaran();
          console.log(this.form.value);
        }
      });
    }
  }

  async updateStatusPembayaran() {
    if (!this.formPembayaran.valid) {
      CommonService.markAsDirty(this.formPembayaran);
    } else {
      this.isLoading = true;

      await this._BuybackRest
        .update(this.formPembayaran.value)
        .toPromise()
        .then((response: any) => {
          if (!!response?.invalid) {
            Swal.fire({
              icon: 'warning',
              title: 'Update Status Failed',
              confirmButtonText: 'OK'
            });
          } else {
            Swal.fire(
              'Update Status Success!',
              '',
              'success'
            )

            this._buybackListComponent.callHookDirectly('init');
            this.closeModal();
            this.step = 1;
            // setTimeout(() => {
            //   window.location.reload();
            // }, 1000)
          }
        })
        .catch(error => {
          console.log(error)
          Swal.fire(
            'Oops..',
            'Something went wrong!',
            'error'
          )
        });
      this.isLoading = false;
    }
  }

  canAccess(pageAction: string) {
    const userAccess: string[] = this._AclService?.exclusivePermissions;
    return userAccess.find(action => action === pageAction);
  }

  openModal() {
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
    this.step = 1;
  }

  prevStep() {
    this.step--;
  }

  nextStep() {
    this.step++;
  }
}