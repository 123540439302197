import { Inject, Injectable } from '@angular/core';
import { AxiosInstance, AxiosResponse, AxiosStatic } from 'axios';
import * as _ from 'lodash';

import { SystemMessageService } from '../system-message/system-message.service';

@Injectable()
export class HttpExtsrvService {
  constructor(
    @Inject('GlobalSystemMessage') private _globalSystemMessage: SystemMessageService,
  ) { }

  axiosInterceptors(axios: AxiosInstance | AxiosStatic) {
    axios.interceptors.response.use(response => {
      this.handleResponse(response);

      return response;
    }, error => {
      const response = error.response;
      this.handleResponse(response);

      throw error;
    });
  }

  private handleResponse(response: AxiosResponse) {
    if (_.has(response, 'data.errors') && _.has(response, 'data.data')) {
      _.set(response.data, 'summary', {});

      this.handleErrors(response);
    }
  }

  private handleErrors(response: AxiosResponse) {
    const errorMessages = [];

    _.forEach(response.data.errors, error => {
      const errorMessage = _.get(error, 'error_message');
      const errorCode = error.error_code;
      if (errorMessage || errorCode) {
        if (errorMessage && errorCode) {
          errorMessages.push(`${errorMessage} [${errorCode}]`);
        } else if (errorMessage && !errorCode) {
          errorMessages.push(errorMessage);
        } else if (!errorMessage && errorCode) {
          errorMessages.push(`Error code ${errorCode}`);
        }

        if (errorCode) {
          _.set(response.data, `summary.${error.error_code}`, true);
        }
      }
    });

    this._globalSystemMessage.log({
      message: errorMessages,
      type: 'error',
      showAs: 'growl',
      scroll: false,
      timer: 5000
    });
  }
}
