import { AfterViewInit, ChangeDetectorRef, Component, Input, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MFormgenSchema, MFormgenSchemaField } from '../formgen.model';

@Component({
  selector: 'app-formgen-field-control',
  templateUrl: './formgen-field-control.component.html',
})
export class FormgenFieldControlComponent implements AfterViewInit {
  @Input() schema: MFormgenSchema;
  @Input() field: MFormgenSchemaField;
  @Input() formGroup: FormGroup;
  @Input() contentTemplates: { [key: string]: TemplateRef<any> } = {};

  constructor(private _changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this._changeDetectorRef.detectChanges();
  }
}
