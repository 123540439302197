import { Component, ElementRef } from '@angular/core';
import 'moment/locale/id';
import { BatteryRentRestService } from '../battery-rent-rest.service';
import Swal from 'sweetalert2';
import { APP_CONST } from '../../../app.const';
import { BatteryRentListComponent } from '../list/battery-rent-list.component';

@Component({
  selector: 'app-battery-rent-modal',
  templateUrl: 'add-battery-rent.component.html',
  styleUrls: ['../list/battery-rent-list.component.scss']
})
export class AddBatteryRentModalComponent {
  isModalOpen: boolean = false;
  nikInput: string = ''; // To hold user input for NIK
  customerData: any = null; // To store fetched customer data
  selectedCustomer: any = null;
  uploadEndpoint = APP_CONST.API_FILE;

  onMotorcycleNumberChange(index: number) {
    this.selectedCustomer = this.customerData[index];
  }

  constructor(
    private _BatteryRentRest: BatteryRentRestService,

    public _batteryRentListComponent: BatteryRentListComponent
  ) {}

  openModal() {
    this.isModalOpen = true;
    this.customerData = null; // Reset customer data when modal is opened
    this.nikInput = ''; // Clear NIK input
  }

  closeModal() {
    this.isModalOpen = false;
  }

  onNikInputChange() {
    if (this.nikInput.length === 16) {
      this.fetchCustomerData(); // Trigger fetch when input reaches 16 characters
    }
  }

  fetchCustomerData() {
    if (!this.nikInput || this.nikInput.length !== 16) {
      Swal.fire('Error', 'Please enter a valid 16-digit NIK.', 'error');
      return;
    }

    this._BatteryRentRest.getCustomer({ nik: this.nikInput }).subscribe({
      next: response => {
        if (response.data.data.length > 0) {
          console.log('response', response.data.data);
          this.customerData = response.data.data;
          console.log('Customer Data:', this.customerData);
        } else {
          Swal.fire('Error', 'Failed to fetch customer data. Please try again.', 'error');
        }
      },
      error: err => {
        console.error('Error fetching customer data:', err);
        Swal.fire('Error', 'Failed to fetch customer data. Please try again.', 'error');
      }
    });
  }

  generatePayload() {
    if (!this.selectedCustomer) {
      Swal.fire('Error', 'Please select a motorcycle number.', 'error');
      return null; // Return null if no customer is selected
    }

    const payload = {
      nik: this.nikInput,
      motorcycleNumber: this.selectedCustomer.motorcycle_number,
      customerName: this.selectedCustomer.customer_name,
      chassisNumber: this.selectedCustomer.chassis_number,
      customerPhone: this.selectedCustomer.customer_phone,
      customerAddress: this.selectedCustomer.customer_address,
      invoice: this.selectedCustomer.invoice,
      pks: this.selectedCustomer.pks,
      // Add any other fields you want to include in the payload
    };

    return payload;
  }

  onImageFinishUploaded(image, inputName: string) {
    this.selectedCustomer[inputName] = image?.fileUrl;
  }


  // Method to handle the submit action
  submitForm() {
    const payload = this.generatePayload();

    if (!payload) {
      // Payload generation failed, likely due to no selection
      return;
    }

    console.log('Submitting form with payload:', payload);

    this._BatteryRentRest.create(payload).subscribe({
      next: response => {
        Swal.fire('Success', 'Battery rent submission successful!', 'success');
        this._batteryRentListComponent.callHookDirectly('init');
        this.closeModal(); // Close modal on success
      },
      error: err => {
        console.error('Error submitting form:', err);
        Swal.fire('Error', 'Failed to submit battery rent. Please try again.', 'error');
      }
    });
  }
}
