
import {of as observableOf, from as observableFrom,  Observable } from 'rxjs';

import {switchMap} from 'rxjs/operators';
import Axios, { AxiosInstance, AxiosPromise } from 'axios';

import { Injectable } from '@angular/core';

import { AuthenticationService } from '../auth/authentication.service';

import { APP_CONST } from '../../app.const';

@Injectable()
export class TokenValidatorService {
  axios: AxiosInstance;
  baseURL = APP_CONST.API_MAIN;

  constructor(
    private _authentication: AuthenticationService,
  ) {
    this.axios = Axios.create();
    this.axios.defaults.baseURL = this.baseURL;

    this._authentication.axiosInterceptors(this.axios);
  }

  validate(): Observable<boolean> {
    return observableFrom(
      <AxiosPromise>this.axios.get('/validate-token')
    ).pipe(switchMap(response => observableOf(response.data)));
  }
}
