import { Injectable, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { SelectItem } from 'primeng/api';

@Injectable()
export class GridTableToggleService {
  constructor(
    private _translate: TranslateService,
    private _ngZone: NgZone,
  ) { }

  mapToggleOptionsFromColumns(tableColumns: IGridTableColumn[]): {
    isActive: Function,
    options: SelectItem[],
    toggled: IGridTableColumn[],
  } {
    const instance: any = {
      toggled: tableColumns.filter(tableColumn => !tableColumn.hidden),
    };

    instance.options = tableColumns.map(col => {
      return {
        label: this._translate.instant(col.i18nLabel),
        value: col
      };
    });

    instance.isActive = (o) => {
      return Boolean(_.find(instance.toggled, o));
    };

    return instance;
  }
}
