import { Component } from '@angular/core';
import 'moment/locale/id';
import * as moment from 'moment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { BluebirdRegulerRestService } from '../bluebird-reguler-rest.service';
import { ExportDataTableService } from '../../../libraries/export/export-data-table.service';
import { AuthenticationService } from '../../../libraries/auth/authentication.service';

@Component({
  selector: 'app-bluebird-reguler-list',
  templateUrl: 'bluebird-reguler-list.component.html'
})
export class BluebirdRegulerListComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'ui.bluebirdReguler.entity.no',
      field: 'no'
    },
    {
      i18nLabel: 'ui.bluebirdReguler.entity.name',
      field: 'fullname',
      sort: true
    },
    {
      i18nLabel: 'ui.bluebirdReguler.entity.phone',
      field: 'phone',
      sort: true
    },
    {
      i18nLabel: 'ui.bluebirdReguler.entity.nik',
      field: 'nik',
      sort: true
    },
    {
      i18nLabel: 'ui.bluebirdReguler.entity.dob',
      field: 'dob',
      sort: true
    },
    {
      i18nLabel: 'ui.bluebirdReguler.entity.gender',
      field: 'genderName',
      sort: true
    },
    {
      i18nLabel: 'ui.bluebirdReguler.entity.email',
      field: 'email',
      sort: true
    },
    {
      i18nLabel: 'ui.bluebirdReguler.entity.fullAddress',
      field: 'address',
      sort: true
    },
    {
      i18nLabel: 'ui.bluebirdReguler.entity.createdAt',
      field: 'createdAt',
      sort: true
    }
  ];
  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(this.tableColumns);
  isLoadingExportExcel: boolean = false;

  constructor(
    public _authentication: AuthenticationService,
    private _BluebirdRegulerRest: BluebirdRegulerRestService,
    private _export: ExportDataTableService
  ) {
    super();
    this.componentId = 'BluebirdRegulerListComponent';
    this.headerTitle = 'ui.bluebirdReguler.list.title';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qOption = event.data.qOption;
        qOption['limit'] = qOption['take'];
        delete qOption['take'];
        delete qOption['skip'];
        const qParams = Object.assign({ channel: 'bb_semolis'}, event.data.qParams, qOption, this.qParams);
        return this._BluebirdRegulerRest.findAll(qParams).pipe(
          switchMap(doc => {
            const dataFormatted = doc?.data?.map(d => {
              d.dob = moment(d?.dob).format('DD MMMM YYYY');
              d.createdAt = moment(d?.createdAt).format('DD MMMM YYYY HH:mm');
              d.updatedAt = moment(d?.updatedAt).format('DD MMMM YYYY HH:mm');
              return d;
            });
            doc.data = dataFormatted;
            return of(doc);
          })
        );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () => this.page.compHookService?.callMultipleHooks(['BluebirdRegulerListComponent:workflowLoadData'])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  async onDownloadExcel() {
    this.isLoadingExportExcel = true;
    try {
      const result = await this._BluebirdRegulerRest.findAll({ channel: "bb_semolis" }).toPromise();
      const mapOption = {
        fullname: { header: 'Nama' },
        phone: { header: 'Telepon' },
        nik: { header: 'NIK (KTP)' },
        dob: { header: 'Tanggal Lahir' },
        gender: { header: 'Jenis Kelamin' },
        email: { header: 'Email' },
        address: { header: 'Alamat' },
        createdAt: { header: 'Tanggal Dibuat' }
      };
      const payload = result.data.map(item => ({
        ...item,
        dob: moment(item?.dob).format('DD MMMM YYYY'),
        gender: item?.gender == 'M' ? 'Laki-laki' : 'Perempuan',
        createdAt: moment(item?.createdAt).format('DD MMMM YYYY HH:mm')
      }));
      this._export.export({
        extension: 'xlsx',
        fileName: 'BluebirdReguler',
        records: payload,
        mapOptions: mapOption,
        templateData: null,
        pdfOptions: null
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoadingExportExcel = false;
    }
  }
}
