import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class UnsavedDataGuard implements CanDeactivate<any> {
  constructor(
    private _translate: TranslateService
  ) {}

  canDeactivate(component: any): boolean {
    if (component.hasUnsavedData) {
      return confirm(this._translate.instant('confirm.any.unsavedData'));
    }
    return true;
  }
}