import { Component, Output, EventEmitter } from '@angular/core';
import { BaseCreateBComponent } from '../../../libraries/base/create/base-create.bcomponent';
import { RoleRestService } from '../role-rest.service';

@Component({
  selector: 'app-role-create',
  templateUrl: 'role-create.component.html'
})
export class RoleCreateComponent extends BaseCreateBComponent {
  @Output() success: EventEmitter<boolean> = new EventEmitter();

  constructor(private _roleRest: RoleRestService) {
    super();
    this.componentId = 'RoleCreateComponent';
    this.routeURL = '/roles';
    this.entrySuccessI18n = 'success.role.create';
    this.headerTitle = 'ui.role.create.title';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'save',
      handle: () => {
        const formValue = this.form.value;

        return this._roleRest.create(formValue);
      },
      success: () => {
        this.success.emit(true);
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService.callMultipleHooks([
          'RoleFormComponent:workflowLoadRelated',
          'RoleFormComponent:workflowBuildForm'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }
}
