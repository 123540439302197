import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxModule } from 'primeng/checkbox';
import { LibrariesModule } from '../../libraries/libraries.module';
import { SharedModule } from '../../libraries/shared.module';
import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';

@NgModule({
  imports: [
    CheckboxModule,
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    LibrariesModule,
    LoginRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule
  ],
  declarations: [
    LoginComponent
  ]
})
export class LoginModule { }
