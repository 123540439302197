import { Component, Output, SimpleChange } from '@angular/core';
import { BaseUpdateBComponent } from '../../../libraries/base/update/base-update.bcomponent';
import { NewsCategoriesRestService } from '../news-categories-rest.service';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-news-categories-update',
  templateUrl: 'news-categories-update.component.html'
})
export class NewsCategoriesUpdateComponent extends BaseUpdateBComponent {
  constructor(private _newsCategoriesRest: NewsCategoriesRestService) {
    super();
    this.componentId = 'NewsCategoriesUpdateComponent';
    this.routeURL = '/news-categories';
    this.entrySuccessI18n = 'success.categories.update';
    this.headerTitle = 'ui.categories.update.title';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'load',
      handle: () => {
        return this._newsCategoriesRest.load(this.comp.routeParams.id);
      },

      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'save',
      handle: () => {
        const formValue = this.form.value;

        return this._newsCategoriesRest.update(
          this.comp.routeParams.id,
          formValue
        );
      },

      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService.callMultipleHooks([
          'NewsCategoriesFormComponent:workflowBuildForm',
          'NewsCategoriesUpdateComponent:workflowLoadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }
}
