import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AuthenticationService } from '../auth/authentication.service';
import { IdleGuardService } from '../common/idle-guard.service';
import { ApiBootstrapService } from '../http/api-bootstrap.service';

@Component({
  selector: 'app-idle-guard',
  templateUrl: 'idle-guard.component.html',
  styleUrls: ['idle-guard.component.scss']
})
export class IdleGuardComponent {
  form: FormGroup;

  constructor(
    private _apiBootstrap: ApiBootstrapService,
    private _authentication: AuthenticationService,
    private _fb: FormBuilder,
    public _idleGuard: IdleGuardService
  ) {
    this.buildForm();
  }

  buildForm() {
    this.form = this._fb.group({
      password: new FormControl()
    });
  }

  submit(ev) {
    this._authentication.checkPassword(this.form.value.password).subscribe(response => {
      this._apiBootstrap.boot().subscribe();
      this._idleGuard.idle = false;
      this.form.reset();
    });
  }
}
