import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent {
  @Input() status: string;

  // Method to get the class name based on status
  getStatusClass(status: string): string {
    switch (status) {
      case 'done':
        return 'done';
      case 'on process':
        return 'onprocess';
      case 'submitted':
        return 'submitted';
      case 'rejected':
        return 'rejected';
      case 'revise':
        return 'revise';
      default:
        return '';
    }
  }

  // Method to calculate background color with 6% opacity
  getBackgroundColor(status: string): string {
    const colors: { [key: string]: string } = {
      'done': '#5FAC0F',
      'on process': '#EB9B00',
      'submitted': '#2A8C99',
      'rejected': '#DC4646',
      'revise': '#EB9B00',
    };

    const color = colors[status] || '#000000';
    return this.hexToRGBA(color, 0.06);
  }

  // Method to convert HEX to RGBA
  hexToRGBA(hex: string, alpha: number): string {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
}
