import { AfterViewInit, Component, ComponentFactoryResolver, Input, ViewChild, ViewContainerRef } from '@angular/core';
import * as _ from 'lodash';
import { Constructor } from '../common/common.type';

@Component({
  selector: 'app-render-component-class',
  templateUrl: './render-component-class.component.html',
})
export class RenderComponentClassComponent<TClass> implements AfterViewInit {
  @ViewChild('container', { read: ViewContainerRef, static: false }) container: ViewContainerRef;
  @Input() componentClass: Constructor<TClass>;
  @Input() inputs: any;

  constructor(
    private _componentFactoryResolver: ComponentFactoryResolver,
  ) { }

  ngAfterViewInit() {
    const componentFactory = this._componentFactoryResolver.resolveComponentFactory(this.componentClass);
    const componentRef = this.container.createComponent(componentFactory);
    _.forEach(this.inputs, (inputValue, inputKey) => {
      componentRef.instance[inputKey] = inputValue;
    });
  }
}
