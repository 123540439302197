import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';

import { FieldsetCollapsibleContentComponent, FieldsetCollapsibleTitleComponent } from './fieldset-collapsible.component';
import { FieldsetCollapsibleDirective } from './fieldset-collapsible.directive';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule
  ],
  declarations: [
    FieldsetCollapsibleContentComponent,
    FieldsetCollapsibleDirective,
    FieldsetCollapsibleTitleComponent,
  ],
  exports: [
    FieldsetCollapsibleContentComponent,
    FieldsetCollapsibleDirective,
    FieldsetCollapsibleTitleComponent
  ]
})
export class FieldsetCollapsibleModule {

}
