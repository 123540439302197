import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { APP_CONST } from "../../../../app.const";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-assessment-card',
  templateUrl: 'assessment.component.html',
  styleUrls: ['../../buyback.component.scss']
})
export class AssessmentCardComponent {
  @Input() id: number;
  @Input() component: string;
  @Input() info: string;
  @Input() formGroup: FormGroup; // Accept the form group as input

  uploadEndpoint = APP_CONST.API_FILE;

  constructor(
    private _translate: TranslateService
  ) {}

  // Access form controls from the formGroup
  get isConditionGood() {
    return this.formGroup.get('isConditionGood');
  }

  get remark() {
    return this.formGroup.get('remark');
  }

  onImageFinishUploaded(image, inputName: string) {
    if (image && image.fileUrl) {
      this.formGroup.patchValue({
        uploadFoto: {
          [inputName]: image.fileUrl
        }
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: this._translate.instant('error.image.upload')
      });
    }
  }

  onDeleteImage(inputName: string) {
    this.formGroup.patchValue({
      uploadFoto: {
        [inputName]: ''
      }
    });
  }
}