import { Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, concat, forkJoin, Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AuthenticationService } from '../auth/authentication.service';
import { CentralCacheService } from '../interaction/central-cache.service';
import { CentralHookService } from '../interaction/central-hook.service';
import { EventService } from '../interaction/event.service';

@Injectable()
export class ApiBootstrapService {
  state$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    @Inject('GlobalEvent') private _event: EventService,
    private _authentication: AuthenticationService,
    private _centralCacheService: CentralCacheService,
    private _centralHookService: CentralHookService,
  ) {
    this._event.listen('CORE:LOGOUT:SUCCESS').subscribe(() => {
      this.state$.next(null);
    });

    this._centralHookService.registerHook({
      hookId: 'apiBootstrapBootRequests',
      handle: () => {
        return forkJoin(
          concat(
            this._centralCacheService.boot(),
          )
        );
      },
      runCentralized: true,
    });
  }

  get change$(): Observable<boolean> {
    return this.state$.pipe(filter(state => typeof (state) === 'boolean'));
  }

  boot(reboot: boolean = false): Observable<boolean> {
    let obs: Observable<boolean> = of(true);
    const booted = this.state$.getValue();
    if (!booted || reboot) {
      const userRoles = _.get(this._authentication, 'user.roles', []);
      if (this._authentication.user && !userRoles.includes('registration_process')) {
        obs = this._centralHookService.callHook('apiBootstrapBootRequests').pipe(
          tap(
            this.success.bind(this),
            this.failed.bind(this)
          ), map(() => true));
      } else if (userRoles.includes('registration_process')) {
        this.success();
      } else {
        this.removePreloader();
      }
    }
    return obs;
  }

  removePreloader() {
    if ((window as any).appBootstrap) {
      (window as any).appBootstrap();
    }
  }

  destroy() {
    this.success();
  }

  waitForBootstrapped() {
    return this.state$.pipe(filter(value => value === true));
  }

  private failed() {
    this.removePreloader();

    const state = this.state$.getValue();
    if (typeof (state) !== 'boolean' || state) {
      this.state$.next(false);
    }
  }

  private success() {
    this.removePreloader();

    const state = this.state$.getValue();
    if (typeof (state) !== 'boolean' || !state) {
      this.state$.next(true);
    }
  }
}
