import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import * as _ from 'lodash';

import { AclService } from './acl.service';

@Directive({
  selector: '[appCompAccess]',
})
export class CompAccessDirective {
  constructor(
    private _acl: AclService,
    private _templateRef: TemplateRef<any>,
    private _viewContainerRef: ViewContainerRef,
    ) { }

  @Input('appCompAccess') set showHide(accesses: any) {
    if (this._acl.can.apply(this._acl, _.castArray(accesses))) {
      // If condition is true add template to DOM
      this._viewContainerRef.createEmbeddedView(this._templateRef);
    } else {
     // Else remove template from DOM
      this._viewContainerRef.clear();
    }
  }
}
