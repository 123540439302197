import { Component, Input } from "@angular/core";
import { BuybackListComponent } from "../list/buyback-list.component";
import { CommonService } from "../../../libraries/libraries.module";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { APP_CONST } from "../../../app.const";
import Swal from "sweetalert2";
import { BuybackRestService } from "../buyback-rest.service";

@Component({
  selector: 'app-buyback-payment-modal',
  templateUrl: 'buyback-payment-modal.component.html',
  styleUrls: ['../buyback.component.scss']
})
export class BuybackPaymentModalComponent {
  @Input() buyback: any;
  @Input() disabled: boolean;

  constructor(
    public _buybackListComponent: BuybackListComponent,
    private fb: FormBuilder,
    private _BuybackRest: BuybackRestService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      _id: [this.buyback._id],
      statusPembayaranBuyback: ['done'],
      pembayaranBuyback: this.fb.group({
        uploaded_file_url: ['', Validators.required]
      })
    })
  }

  form: FormGroup
  isCommissionModalOpen: boolean = false;
  uploadEndpoint = APP_CONST.API_FILE;
  isLoading: boolean = false;

   isPDF(url: string): boolean {
    return url?.toLowerCase().endsWith('.pdf');
  }

  openPDF(url: string): void {
    window.open(url, '_blank');
  }

  openCommissionModal() {
    this.isCommissionModalOpen = true;
  }

  closeCommissionModal() {
    this.isCommissionModalOpen = false;
  }

  onImageFinishUploaded(image, inputName: string) {
    this.form.patchValue({
      pembayaranBuyback: {
        [inputName]: image?.fileUrl,
      }
    });
  }

  onUpdateCommission() {
    if (this.form.invalid) {
     
      console.log(this.form.value);
      // Create an alert message
      const alertMessage = `Data belum lengkap`;
      
      // Show SweetAlert2 alert
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: alertMessage,
        confirmButtonText: 'OK'
      });
      return;
    }
    // this.generatePayload();
    if (!this.form.valid) {
      CommonService.markAsDirty(this.form);
    } else {
      Swal.fire({
        title: 'Apakah anda yakin ingin mengsubmit dokumen?',
        text: '',
        icon: 'question',
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.updateCommission();
          console.log(this.form.value);
        }
      });
    }
  }

  async updateCommission() {
    if (!this.form.valid) {
      CommonService.markAsDirty(this.form);
    } else {
      this.isLoading = true;

      await this._BuybackRest
        .update(this.form.value)
        .toPromise()
        .then((response: any) => {
          if (!!response?.invalid) {
            Swal.fire({
              icon: 'warning',
              title: 'Submit Dokumen Failed',
              confirmButtonText: 'OK'
            });
          } else {
            Swal.fire(
              'Submit Dokumen Success!',
              '',
              'success'
            )

            this._buybackListComponent.callHookDirectly('init');
            this.closeCommissionModal();
            // setTimeout(() => {
            //   window.location.reload();
            // }, 1000)
          }
        })
        .catch(error => {
          console.log(error)
          Swal.fire(
            'Oops..',
            'Something went wrong!',
            'error'
          )
        });
      this.isLoading = false;
    }
  }

}