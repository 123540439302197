import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WindowTabService {
  tabId: number;

  constructor() {
    const tabId =
      sessionStorage.tabID && sessionStorage.closedLastTab !== '2'
        ? sessionStorage.tabID
        : (sessionStorage.tabID = Math.random());

    sessionStorage.closedLastTab = '2';

    this.tabId = tabId;
  }
}
