import { Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { EventService } from '../interaction/event.service';

@Injectable()
export class CrossMessageService {
  constructor(
    @Inject('GlobalEvent') private _event: EventService,
  ) {
    window.addEventListener('message', (event) => {
      const messageType = _.get(event, 'data.type', null);
      const data = _.get(event, 'data.data', {});

      if (messageType) {
        this._event.emit(messageType, data);
      }
    });
  }

  sendEvent(id: string, data: any) {
    window.postMessage({
      id,
      data,
    }, window.location.href);
  }
}
