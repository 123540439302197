import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FineUploaderBasic, status } from 'fine-uploader/lib/core';

@Component({
  selector: 'app-img-upload-item',
  templateUrl: './img-upload-item.component.html',
})
export class ImgUploadItemComponent implements OnInit {
  @Input() uploader: FineUploaderBasic;
  @Input() file: any;
  @Input() small: boolean = false;
  @Input() isImageProductVariant: boolean = false;

  @Output() onRightClicked: EventEmitter<any> = new EventEmitter;
  @Output() onDeleted: EventEmitter<boolean> = new EventEmitter;
  @Output() onPrimarySelected: EventEmitter<boolean> = new EventEmitter;

  @ViewChild('image', { static: false }) elImage: ElementRef;

  imagePlaceholder = (require('../img-upload-placeholder.png') as any).default;

  constructor(
    private _translate: TranslateService,
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.uploader.drawThumbnail(this.file.id, this.elImage.nativeElement, 0, this.file.thumbnailUrl);
    }, 500);
  }

  onCancel() {
    this.onDeleted.emit(true);

    this.uploader.setStatus(this.file.id, status.DELETED);
  }

  onRightClick() {
    this.onRightClicked.emit(this.file);
  }
}
