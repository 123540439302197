import * as _ from 'lodash';
import * as moment from 'moment';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'app-grid-table-column',
  templateUrl: 'grid-table-column.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridTableColumnComponent implements AfterViewInit, OnInit {
  @Input() field: any;
  @Input() record: any;
  @Input() multiLevel: boolean;
  @Input() setDefaultValue: boolean;
  @Input() tableColumn: IGridTableColumn;

  @ViewChild('dynamicTemplate', { read: ViewContainerRef, static: false }) elDynamicTemplate;

  value: any;

  cachedClasses: string;
  cachedLink: string;

  tempValue: any;
  tempTableColumn: any;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
  ) { }
  ngOnInit() {
    this.tempValue = this.record;
    this.tempTableColumn = this.tableColumn;
    
    const rawValue = _.get(this.record, this.tableColumn.field);
    if (this.tableColumn.formatter) {
      this.value = this.tableColumn.formatter(rawValue, this.record);
    } else if (this.tableColumn.dateFormat) {
      const targetDate = moment(rawValue);
      this.value = targetDate.isValid() ? targetDate.format(this.tableColumn.dateFormat) : rawValue;
    } else {
      this.value = rawValue;
    }

    if (this.tableColumn.classes) {
      this.cachedClasses = this.tableColumn.classes(this.value, this.record);
    }

    if (this.tableColumn.link && !this.tempTableColumn.toOtherLink) {
      this.cachedLink = this.tableColumn.link(this.record);
    }

    if (this.tempTableColumn.toOtherLink) {
      this.cachedLink = this.tableColumn.link(this.record);
    }

    if (this.multiLevel) {
      this.value = { ofRecord: this.value, paddingLevelRecord: this.tempValue.level > 0 ? (this.tempValue.level * 40).toString() + 'px' : '0px' }
    }

    if (this.setDefaultValue && (this.tempTableColumn.valueDefault)) {
      if ((_.get(this.record, this.tableColumn.field) === this.tempTableColumn.setValueDefaultIf)) {
        this.value = this.tempTableColumn.valueDefault;
      }
    }
  }

  ngAfterViewInit() {
    if (this.elDynamicTemplate && this.tableColumn.template) {
      this.elDynamicTemplate.createEmbeddedView(this.tableColumn.template, {
        $implicit: this.tableColumn,
        parsedValue: this.value,
        record: this.record,
      });

      this._changeDetectorRef.detectChanges();
    }
  }
}
