import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TableModule } from 'primeng//table';
import { GridTableFooterComponent } from './footer/grid-table-footer.component';
import { GridTableHeaderComponent } from './header/grid-table-header.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    PaginationModule,
    TranslateModule,
    TableModule,
  ],
  declarations: [
    GridTableHeaderComponent,
    GridTableFooterComponent,
  ],
  exports: [
    GridTableHeaderComponent,
    GridTableFooterComponent,
  ]
})
export class GridTableModule { }
