import { Component, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { interval, Observable, Subscription } from 'rxjs';
import { finalize, tap, zip } from 'rxjs/operators';
import { CompHookService } from '../../interaction/comp-hook.service';
import { CompLoadingService } from '../../interaction/comp-loading.service';
import { SpinnerDirective } from '../../spinner/spinner.directive';
import { BaseCompBComponent } from '../comp/base-comp.bcomponent';
import { BaseCompComponent } from '../comp/base-comp.component';

@Component({
  selector: 'app-base-page',
  templateUrl: './base-page.component.html',
  providers: [
    CompHookService,
    CompLoadingService,
  ],
  viewProviders: [
    BasePageComponent,
  ],
})
export class BasePageComponent {
  @ViewChild('pageLoading', { static: false }) elPageLoading: SpinnerDirective;

  componentId = this.constructor.name;
  routerChildrenComponents: BaseCompBComponent[] = [];

  constructor(
    public compHookService?: CompHookService,
    public compLoadingService?: CompLoadingService,
  ) { }

  getRouterChildComponent(componentId: string) {
    return this.routerChildrenComponents.find(comp => comp.componentId === componentId);
  }

  registerRouterChildComponent(comp: BaseCompBComponent) {
    this.routerChildrenComponents.push(comp);
  }

  waitForComponentIdReady(componentId: string, timeoutMs: number = 0) {
    return new Observable(observer => {
      let subscriptionInterval: Subscription;
      const complete = () => {
        subscriptionInterval.unsubscribe();
        observer.complete();
      };

      subscriptionInterval = interval(100)
        .pipe(
          tap(() => {
            const targetComponent = this.getRouterChildComponent(componentId);
            if (targetComponent && targetComponent.compInitialized) {
              observer.next(true);
              complete();
            }
          })
        )
        .subscribe();

      if (timeoutMs > 0) {
        setTimeout(() => {
          console.error(`TIMEOUT FOR WAITING COMPONENT ${componentId} READY!!`);
          complete();
        }, timeoutMs);
      }
    });
  }

  waitForMultipleComponentIdsReady(componentIds: string[], timeoutMs: number = 5000) {
    return zip(componentIds.map(componentId => this.waitForComponentIdReady(componentId), timeoutMs));
  }

  unregisterRouterChildComponent(comp: BaseCompComponent) {
    _.remove(this.routerChildrenComponents, cmp => cmp.componentId === comp.component.componentId);
  }

  createLoading(loadingId?: string, componentIds?: string | string[]) {
    const loadings: any = [];

    const arrayOfComponentIds = _.castArray(componentIds).filter(_.identity);
    if (arrayOfComponentIds.length) {
      const components: BaseCompBComponent[] = [];
      arrayOfComponentIds.forEach(componentId => {
        const routerChildComponent = this.getRouterChildComponent(componentId);
        if (routerChildComponent) {
          components.push(routerChildComponent);
        } else if (!arrayOfComponentIds.includes(this.componentId)) {
          console.error(`CANNOT FIND LOADING FOR ${componentId}`);
        }
      });

      if (arrayOfComponentIds.includes(this.componentId)) {
        loadings.push(
          this.compLoadingService.createLoading(loadingId)
        );
      }

      components.forEach(component => {
        loadings.push(
          component.elComp.compLoadingService.createLoading(loadingId)
        );
      });
    } else {
      loadings.push(
        this.compLoadingService.createLoading(loadingId)
      );
    }

    return {
      dispose: () => {
        loadings.forEach(loading => loading.dispose());
      }
    };
  }

  observableWrapLoading(observable: Observable<any>, loadingId?: string, componentIds?: string | string[]) {
    const loading = this.createLoading(loadingId, componentIds);
    return observable.pipe(
      finalize(() => {
        loading.dispose();
      })
    );
  }

  setLoadingButton(...parameters) {
    if (this.elPageLoading) {
      this.elPageLoading.setButton(...parameters);
    }
  }

  setLoadingTitle(...parameters) {
    if (this.elPageLoading) {
      this.elPageLoading.setTitle(...parameters);
    }
  }
}
