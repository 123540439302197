import { switchMap, tap } from 'rxjs/operators';
import { AppEvent } from '../../interaction/event.service';
import { BaseEntryBComponent } from '../entry/base-entry.bcomponent';

export class BaseUpdateBComponent extends BaseEntryBComponent {
  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'workflowPatchForm',
      handle: event => this.patchForm(event),
    });

    this.registerHook({
      hookName: 'workflowLoadData',
      handle: () =>
        this.callHook('load').pipe(
          tap(doc => {
            this.doc = doc;
            this.comp._changeDetectorRef.detectChanges();
          }),
          switchMap(doc => this.callHook('workflowPatchForm', doc))),
    });
  }

  patchForm(event: AppEvent) {
    if (this.formgenSchema.fields.length) {
      this.formgenSchema.populateFromValues(event.data);
    } else if (this.mform.formDefinition) {
      this.mform.populateFromValues(event.data);
    } else {
      this.form.patchValue(event.data);
    }
  }
}
