import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { InjectorService } from '../common/injector.service';
import { BroadcastChannelEvent } from './broadcast-channel.model';
import { BroadcastChannelService } from './broadcast-channel.service';

export class AppEvent {
  name: string;
  timestamp: Date;
  data: any;
  broadcastEvent?: boolean = false;

  constructor(eventName: string, data: any, broadcastEvent?: boolean) {
    this.timestamp = new Date();
    this.name = eventName;
    this.data = data;
    this.broadcastEvent = broadcastEvent;
  }
}

@Injectable()
export class EventService {
  private eventObservable: Observable<AppEvent>;
  private eventSubject = new Subject<AppEvent>();

  private _broadcastChannelService = InjectorService.get<BroadcastChannelService>(BroadcastChannelService);

  constructor() {
    this.eventObservable = this.eventSubject.asObservable();

    this._broadcastChannelService.addEventListener('message', (message: BroadcastChannelEvent) => {
      if (message && message.name) {
        this.eventSubject.next(message);
      }
    });
  }

  listen(eventName: string | string[]): Observable<AppEvent> {
    const targetEventNames: string[] = _.castArray(eventName);
    return this.eventObservable.pipe(filter(ev => targetEventNames.includes(ev.name)));
  }

  emit(eventName: string, data: any = null, broadcastEvent?: boolean) {
    const ev: AppEvent = new AppEvent(eventName, data, broadcastEvent);
    this.eventSubject.next(ev);
    if (broadcastEvent) {
      const broadCastChannelEvent = new BroadcastChannelEvent(eventName, data);
      this._broadcastChannelService.postEvent(broadCastChannelEvent);
    }
  }
}
