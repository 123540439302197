import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, ElementRef, forwardRef, Inject, Input, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AdminLayoutService } from '../../../layouts/admin/admin-layout.service';
import { ObservableService } from '../../common/observable.service';
import { CompLoadingService } from '../../interaction/comp-loading.service';
import { EventService } from '../../interaction/event.service';
import { RetryDialogComponent } from '../../retry-dialog/retry-dialog.component';
import { MRetryDialogEntryConfig } from '../../retry-dialog/retry-dialog.model';
import { SpinnerDirective } from '../../spinner/spinner.directive';
import { SystemMessageService } from '../../system-message/system-message.service';
import { ViewUtilityTemplateDirective } from '../../view-utility/view-utility-template.directive';
import { BasePageComponent } from '../page/base-page.component';
import { BaseCompBComponent } from './base-comp.bcomponent';

@Component({
  selector: 'app-base-comp',
  templateUrl: './base-comp.component.html',
  providers: [
    CompLoadingService,
    EventService,
    SystemMessageService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseCompComponent implements OnDestroy, OnInit, AfterContentInit {
  @ViewChild('compLoading', { static: false }) elCompLoading: SpinnerDirective;
  @ViewChild(RetryDialogComponent, { static: false }) elRetryDialog: RetryDialogComponent;
  @ContentChildren(ViewUtilityTemplateDirective) cTemplates: QueryList<ViewUtilityTemplateDirective>;

  @Input() component: BaseCompBComponent;

  @Input() headerTemplate: TemplateRef<any>;
  @Input() headerTitleTemplate: TemplateRef<any>;
  @Input() headerActionsTemplate: TemplateRef<any>;
  @Input() contentTemplate: TemplateRef<any>;
  @Input() footerTemplate: TemplateRef<any>;

  @Input() spinnerConfigStretchToMain: boolean = true;
  @Input() spinnerConfigFixedPosition: boolean;

  routeData: any;
  routeParams: any;
  routeQueryParams: any;

  headerHelpBlockShown: boolean = true;

  constructor(
    @Inject(forwardRef(() => BasePageComponent)) public page: BasePageComponent,
    @Inject('GlobalSystemMessage') public _globalSystemMessage: SystemMessageService,
    public _activatedRoute: ActivatedRoute,
    public _adminLayout: AdminLayoutService,
    public _changeDetectorRef: ChangeDetectorRef,
    public _elementRef: ElementRef,
    public _event: EventService,
    public _router: Router,
    public _systemMessage: SystemMessageService,
    public _translate: TranslateService,
    public compLoadingService: CompLoadingService,
    public observableService: ObservableService,
  ) { }

  ngOnInit() { }

  ngAfterViewInit() { }

  ngAfterContentInit() {
    this.cTemplates.forEach(cTemplate => {
      switch (cTemplate.getType()) {
        case 'compHeaderTpl':
          this.headerTemplate = cTemplate.template;
          break;
        case 'compHeaderTitleTpl':
          this.headerTitleTemplate = cTemplate.template;
          break;
        case 'compHeaderActionsTpl':
          this.headerActionsTemplate = cTemplate.template;
          break;
        case 'compContentTpl':
          this.contentTemplate = cTemplate.template;
          break;
        case 'compFooterTpl':
          this.footerTemplate = cTemplate.template;
          break;
      }
    });

    this._changeDetectorRef.markForCheck();
  }

  ngOnDestroy() {
    this.component.page.unregisterRouterChildComponent(this);
  }

  createRetriableTask(onRetry: Observable<any>, config?: MRetryDialogEntryConfig) {
    return this.elRetryDialog.createRetryEntry(onRetry, config);
  }

  setLoadingButton(...parameters) {
    if (this.elCompLoading) {
      this.elCompLoading.setButton(...parameters);
    }
  }

  setLoadingTitle(...parameters) {
    if (this.elCompLoading) {
      this.elCompLoading.setTitle(...parameters);
    }
  }
}