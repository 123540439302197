import { Injectable, Injector } from '@angular/core';

@Injectable()
export class InjectorService {
  static injector: Injector;

  static get<T>(varClass: any): T {
    return InjectorService.injector.get(varClass);
  }

  constructor(
    private _injector: Injector,
  ) {
    InjectorService.injector = this._injector;
  }
}
