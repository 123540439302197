import { Injectable, TemplateRef } from '@angular/core';
import * as _ from 'lodash';
import { CommonService } from '../common/common.service';
import { MFormValidationFailed } from '../common/form.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MessageParserService {
  constructor(
    private _translateService: TranslateService,
  ) { }

  parse(messages: any) {
    let parsedMessages: (string | TemplateRef<any>)[] = [];

    if (_.isArray(messages)) {
      messages.forEach(messageSet => this.parseMessageObject(messageSet, parsedMessages));
    } else if (_.isObject(messages)) {
      this.parseMessageObject(messages, parsedMessages);
    } else if (_.isString(messages)) {
      parsedMessages.push(messages);
    }

    if (!parsedMessages.length) {
      parsedMessages = parsedMessages.concat(this.populateDefaultMessages(messages));
    }

    return parsedMessages.filter(_.identity);
  }

  parseFormValidationsFailedMessage(validationFails: MFormValidationFailed[], prefix: string = null) {
    const prefixer: string = 'importFormValidationErrors';
    const messages: {
      message: string;
      interpolation: MFormValidationFailed;
    }[] = [];

    _.forEach(validationFails, validationFail => {
      const message: string[] = [];
      message.push(prefix);
      message.push(prefixer);
      message.push(validationFail.pureControlName);
      message.push(validationFail.errorName);

      const messageString = CommonService.joinToString(message);
      messages.push({
        message: messageString,
        interpolation: validationFail,
      });
    });

    return messages;
  }

  private parseMessageObject(messageSet: any, parsedMessages: (string | TemplateRef<any>)[]) {
    if (messageSet instanceof TemplateRef) {
      parsedMessages.push(messageSet);
    } else if (_.has(messageSet, 'response.data[0]') && _.isArray(_.get(messageSet, 'response.data'))) {
      _.forEach(messageSet.response.data, message => this.parseMessageObject(message, parsedMessages));
    } else if (_.isString(messageSet) && messageSet) {
      parsedMessages.push(messageSet);
    } else if (_.has(messageSet, 'data.message') && messageSet.data.message) {
      parsedMessages.push(messageSet.data.message);
    } else if (_.has(messageSet, 'errorMessage') && messageSet.errorMessage) {
      parsedMessages.push(messageSet.errorMessage);
    } else if (_.has(messageSet, 'response.data[0]') && _.isArray(_.get(messageSet, 'response.data[0]'))) {
      _.forEach(messageSet.response.data, message => this.parseMessageObject(message, parsedMessages));
    } else if (_.has(messageSet, 'errors')) {
      _.forEach(messageSet.errors, errorMessage => {
        parsedMessages.push(errorMessage);
      });
    } else if (_.has(messageSet, 'data[0]') && _.isArray(_.get(messageSet, 'data[0]'))) {
      _.forEach(messageSet.data, message => this.parseMessageObject(message, parsedMessages));
    } else if (_.has(messageSet, 'data[0]')) {
      _.forEach(messageSet.data, message => this.parseMessageObject(message, parsedMessages));
    }

    // else if (_.has(messageSet, 'data') && messageSet.data) {
    //   // parsedMessages.push(messageSet.data);
    // } else if (_.has(messageSet, 'response.data') && messageSet.response.data) {
    //   // parsedMessages.push(messageSet.response.data);
    // }
  }

  private populateDefaultMessages(messages: any): string[] {
    if (_.has(messages, 'request') && _.has(messages, 'response')) {
      const url = _.get(messages, 'config.url');
      const statusCode = _.get(messages, 'response.status');
      if (!statusCode) {
        return this._translateService.instant('error.http.noInternetConnection');
      }

      switch (statusCode) {
        case 401:
        case 404:
        case 405:
        case 408:
        case 500:
        case 502:
        case 503:
          return this._translateService.instant(`error.httpCode.${statusCode}`, { url });
      }
    }
  }
}
