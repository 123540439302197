import { AfterViewInit, Input, TemplateRef, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { ControlTagDirective } from '../../common/control-tag.directive';
import { MFormgenSchema, MFormgenSchemaField } from '../formgen.model';

export class FormgenFieldControlBComponent implements AfterViewInit {
  @Input() schema: MFormgenSchema;
  @Input() field: MFormgenSchemaField;
  @Input() contentTemplates: { [key: string]: TemplateRef<any> } = {};
  @ViewChild('controlTag') controlTag: ControlTagDirective;

  ngAfterViewInit() {
    this.callEvent('onInit', null);
  }

  hasEvent(eventName: string) {
    return this.field.fieldEvents && this.field.fieldEvents[eventName];
  }

  callEvent(eventName: string, $event: any) {
    if (this.hasEvent(eventName)) {
      const func = this.field.fieldEvents[eventName];
      return func($event, this.field, this.controlTag.control.parent);
    }
  }

  getPlaceholder() {
    let targetPlaceholder = '';

    const configPlaceholder = _.get(this.field, 'templateConfig.placeholder');
    if (configPlaceholder || configPlaceholder === 0) {
      targetPlaceholder = configPlaceholder;
    }

    return targetPlaceholder;
  }
}
