import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SPKBatteryRentRestService } from "../spk-battery-rent-rest.service";
import Swal from "sweetalert2";
import { SPKBatteryRentListComponent } from "../list/spk-battery-rent-list.component";
import { CommonService } from "../../../libraries/libraries.module";

@Component({
  selector: 'app-change-status-modal',
  templateUrl: 'change-status-modal.component.html',
  styleUrls: ['../list/spk-battery-rent-list.component.scss']
})
export class ChangeStatusModalComponent {
  @Input() spk: any;
  @Input() isOpen: boolean;
  @Output() onClose = new EventEmitter();

  constructor(
    private _SPKBatteryRentRest: SPKBatteryRentRestService,
    public _spkBatteryRentListComponent: SPKBatteryRentListComponent
  ) {
    this.initFormChangeStatus();
  }

  isLoading: boolean = false;
  formChangeStatus: FormGroup;

  initFormChangeStatus() {
    this.formChangeStatus = new FormGroup({
      status: new FormControl('', [Validators.required])
    });
  }

  openDeliveryDateModal() {
    this.formChangeStatus.patchValue({
      status: this.spk?.status
    });
    this.isOpen = true;
  }

  closeModal() {
    this.isOpen = false;
    this.formChangeStatus.reset();
    this.onClose.emit();
  }

  onChangeStatusSPK() {
    if (!this.formChangeStatus.valid) {
      CommonService.markAsDirty(this.formChangeStatus);
    } else {
      Swal.fire({
        title: 'Update Status SPK?',
        text: '',
        icon: 'question',
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.changeStatus();
        }
      });
    }
  }

  async changeStatus() {
    if (!this.formChangeStatus.valid) {
      CommonService.markAsDirty(this.formChangeStatus);
    } else {
      const payload = {
        ...this.formChangeStatus.value,
        spkId: this.spk._id
      };
      this.isLoading = true;

      await this._SPKBatteryRentRest
        .changeStatusSPK(payload)
        .toPromise()
        .then((response: any) => {
          if (!!response?.invalid) {
            Swal.fire({
              icon: 'warning',
              title: 'Invalid Unit Stock',
              html: response?.invalidStockVariant?.map(item => `
              <div class="text-left">
                  <div>Unit: ${item.unit}</div>
                  <div>Total: ${item.total}</div>
                  <div>Current Stock: ${item.currentStock}</div>
                  <hr/>
              </div>
              `).join(''),
              confirmButtonText: 'OK'
            });
          } else {
            Swal.fire(
              'Change Status Successfully!',
              '',
              'success'
            )

            this._spkBatteryRentListComponent.callHookDirectly('init');
            this.closeModal();
          }
        })
        .catch(error => {
          console.log(error)
          Swal.fire(
            'Oops..',
            'Something went wrong!',
            'error'
          )
        });
      this.isLoading = false;
    }
  }
}