import { Component, Input, OnInit } from '@angular/core';
import { BaseEntryBComponent } from '../../entry/base-entry.bcomponent';

@Component({
  selector: 'app-base-action-save',
  templateUrl: './base-action-save.component.html',
})
export class BaseActionSaveComponent implements OnInit {
  @Input() component: BaseEntryBComponent;
  saveAndNewMode: boolean = false;

  ngOnInit() {
    if (this.component.comp) {
      this.component.comp._activatedRoute.queryParams.subscribe(qParams => {
        this.saveAndNewMode = qParams.saveAndNew ? true : false;
      });
    }
  }
}
