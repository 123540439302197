import { Component, ElementRef, Input } from '@angular/core';
import { APP_CONST } from '../../../app.const';
import { BuybackRestService } from '../buyback-rest.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { CommonService } from '../../../libraries/libraries.module';
import { BuybackListComponent } from '../list/buyback-list.component';
import { AclService } from '../../../libraries/auth/acl.service';

@Component({
  selector: 'app-edit-document',
  templateUrl: 'edit-document.component.html',
  styleUrls: ['../buyback.component.scss', './edit-document.component.scss']
  // styleUrls: ['status-info-modal.component.scss']
})
export class EditDocument {
  @Input() data: any;
  @Input() disabled: boolean;
  form: FormGroup;
  photoPreviewModal: boolean = false;
  photoPreviewTitle: string = '';
  isOpen: boolean = false;
  today: string;
  uploadEndpoint = APP_CONST.API_FILE;
  isLoading: boolean = false;

  groupedAssessments: { category: string; components: any[] }[] = [];

  documentTypes = [
    { label: 'STNK', key: 'stnk' },
    { label: 'BPKB', key: 'bpkb' },
    { label: 'INVOICE', key: 'invoice' },
    { label: 'FAKTUR', key: 'faktur' },
    { label: 'KTP', key: 'ktp' },
  ];

  canAccess(pageAction: string) {
    const userAccess: string[] = this._AclService?.exclusivePermissions;
    return userAccess.find(action => action === pageAction);
  }

  isPdfFile(fileUrl: string): boolean {
    return fileUrl.toLowerCase().endsWith('.pdf');
  }
  
  openPdf(fileUrl: string): void {
    window.open(fileUrl, '_blank');
  }

  dateFormatter(date: string | Date): string {
    if (!date) return '';

    const parsedDate = typeof date === 'string' ? new Date(date) : date;

    if (isNaN(parsedDate.getTime())) {
      console.error('Invalid Date:', parsedDate);
      return '';
    }

    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    };

    const formattedDate = parsedDate.toLocaleString('en-GB', options);
    const [time, ...dateParts] = formattedDate.split(', ');

    return `${time} ${dateParts.join(' ')}`;
  }

  ngOnInit() {
    this.form = this.fb.group({
      _id: [this.data._id],
      statusDocumentChecking: ['on process'],
      dataDokumen: this.fb.group({
        note: [''],
        stnk: this.fb.group({
          available: this.data.dataDokumen?.stnk?.available,
          uploaded_file_url: [this.data.dataDokumen?.stnk?.uploaded_file_url],
          uploaded_file_url_1: [this.data.dataDokumen?.stnk?.uploaded_file_url_1],
          status: [this.data.dataDokumen?.stnk?.status],
          last_updated: [this.data.dataDokumen?.stnk?.last_updated],
        }, { validators: this.atLeastOneFileValidator('uploaded_file_url', 'uploaded_file_url_1') }), // Custom validator for stnk
        bpkb: this.fb.group({
          available: this.data.dataDokumen?.bpkb?.available,
          uploaded_file_url: [this.data.dataDokumen?.bpkb?.uploaded_file_url],
          uploaded_file_url_1: [this.data.dataDokumen?.bpkb?.uploaded_file_url_1],
          uploaded_file_url_2: [this.data.dataDokumen?.bpkb?.uploaded_file_url_2],
          uploaded_file_url_3: [this.data.dataDokumen?.bpkb?.uploaded_file_url_3],
          status: [this.data.dataDokumen?.bpkb?.status],
          last_updated: [this.data.dataDokumen?.bpkb?.last_updated],
        }, { validators: this.atLeastOneFileValidator('uploaded_file_url', 'uploaded_file_url_1', 'uploaded_file_url_2', 'uploaded_file_url_3') }), // Custom validator for bpkb
        invoice: this.fb.group({
          available: this.data.dataDokumen?.invoice?.available,
          uploaded_file_url: [this.data.dataDokumen?.invoice?.uploaded_file_url, this.getConditionalValidator(this.data.dataDokumen?.invoice?.uploaded_file_url)],
          status: [this.data.dataDokumen?.invoice?.status],
          last_updated: [this.data.dataDokumen?.invoice?.last_updated]
        }),
        faktur: this.fb.group({
          available: this.data.dataDokumen?.faktur?.available,
          uploaded_file_url: [this.data.dataDokumen?.faktur?.uploaded_file_url, this.getConditionalValidator(this.data.dataDokumen?.faktur?.uploaded_file_url)],
          status: [this.data.dataDokumen?.faktur?.status],
          last_updated: [this.data.dataDokumen?.faktur?.last_updated],
        }),
        ktp: this.fb.group({
          available: this.data.dataDokumen?.ktp?.available,
          uploaded_file_url: [this.data.dataDokumen?.ktp?.uploaded_file_url, this.getConditionalValidator(this.data.dataDokumen?.ktp?.uploaded_file_url)],
          status: [this.data.dataDokumen?.ktp?.status],
          last_updated: [this.data.dataDokumen?.ktp?.last_updated],
        })
      })
    });
  
    this.trackFileChanges();
  }
  
  // Custom Validator for requiring at least one uploaded file in the given fields
  atLeastOneFileValidator(...fields: string[]) {
    return (group: FormGroup) => {
      const isAnyFieldFilled = fields.some(field => group.get(field)?.value);
      
      return isAnyFieldFilled ? null : { atLeastOneFileRequired: true };
    };
  }  

  getConditionalValidator(uploaded_file_url: string | null) {
      return uploaded_file_url ? Validators.required : null;
  }

  trackFileChanges() {
      const dataDokumen = this.form.get('dataDokumen') as FormGroup;
    
      // List of document keys
      const documentKeys = ['stnk', 'bpkb', 'invoice', 'faktur', 'ktp'];
    
      documentKeys.forEach(key => {
        const documentFormGroup = dataDokumen.get(key) as FormGroup;
    
        // Listen for status changes in each document
        documentFormGroup.get('uploaded_file_url')?.valueChanges.subscribe((newStatus) => {
          // When status changes, set last_updated to the current date and time
          documentFormGroup.get('last_updated')?.setValue(new Date().toISOString());
          documentFormGroup.get('status')?.setValue('on review');
        });
      });
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  }

  onImageBerkasFinishUploaded(image, inputName: string) {
    this.form.patchValue({
      dataDokumen: {
        [inputName]: {
          uploaded_file_url: image?.fileUrl
        }
      }
    });
  }

  onImageBerkasFinishUploadedMultiple(image, inputName: string, index: string) {
    this.form.patchValue({
      dataDokumen: {
        [inputName]: {
          [index]: image?.fileUrl
        }
      }
    });
    console.log(inputName, index);
  }

  onDeleteBerkasImage(inputName: string) {
    this.form.patchValue({
      dataDokumen: {
        [inputName]: {
          uploaded_file_url: ''
        }
      }
    });
  }

  onDeleteBerkasImageMultiple(inputName: string, index: string) {
    this.form.patchValue({
      dataDokumen: {
        [inputName]: {
          [index]: ''
        }
      }
    });
  }

  async renderPresignedUrlImage(payload: any, elementId: string) {
    await this._BuybackRest.getPresignedUrl(payload).subscribe(data => {
      const photoPreview = this.el.nativeElement.querySelector(elementId);

      console.log(payload);

      if (photoPreview) {
        // photoPreview.src = data.url;
        // photoPreview.href = data.url;
        photoPreview.src = payload?.storageFileName;
        photoPreview.href = payload?.storageFileName;
      }
    }, error => {
      const photoPreview = this.el.nativeElement.querySelector(elementId);
      if (photoPreview) {
        photoPreview.src = '';
      }
    })
  }

  closePhotoPreviewModal() {
    this.photoPreviewModal = false;
  }

  async openPhotoPreview(url, title) {
    const payload = { storageFileName: url }
    await this.renderPresignedUrlImage(payload, '#js-photoPreview');
    this.photoPreviewModal = true;
    this.photoPreviewTitle = title;
  }

  constructor(
    private el: ElementRef,
    private _BuybackRest: BuybackRestService,
    private fb: FormBuilder,
    public _buybackListComponent: BuybackListComponent,
    private _AclService: AclService,
  ) {
    this.today = new Date().toISOString().split('T')[0]; // Format the date as YYYY-MM-DD
  }


  onUpdateStatusDocumentChecking() {
    console.log(this.form.value);
    if (this.form.invalid) {
     
      // Create an alert message
      const alertMessage = `Data belum lengkap`;
      
      // Show SweetAlert2 alert
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: alertMessage,
        confirmButtonText: 'OK'
      });
      return;
    }
    // this.generatePayload();
    if (!this.form.valid) {
      CommonService.markAsDirty(this.form);
    } else {
      Swal.fire({
        title: 'Apakah anda yakin ingin mengupdate status pengecekan dokumen?',
        text: 'Pastikan semua data terisi dengan benar',
        icon: 'question',
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.updateStatusDocumentChecking();
        }
      });
    }
  }

  async updateStatusDocumentChecking() {
    if (!this.form.valid) {
      CommonService.markAsDirty(this.form);
    } else {
      this.isLoading = true;

      await this._BuybackRest
        .update(this.form.value)
        .toPromise()
        .then((response: any) => {
          if (!!response?.invalid) {
            Swal.fire({
              icon: 'warning',
              title: 'Update Status Failed',
              confirmButtonText: 'OK'
            });
          } else {
            Swal.fire(
              'Update Document Success!',
              '',
              'success'
            )

            this._buybackListComponent.callHookDirectly('init');
            this.closeModal();
            setTimeout(() => {
              window.location.reload();
            }, 1000)
          }
        })
        .catch(error => {
          console.log(error)
          Swal.fire(
            'Oops..',
            'Something went wrong!',
            'error'
          )
        });
      this.isLoading = false;
    }
  }

  openModal() {
    this.isOpen = true;
  }

  closeModal() {
    this.isOpen = false;
  }

  clearForm() {
    console.log("this function is for clear form")
  }

  submitPengajuan() {
    alert("submission submitted!");
    this.closeModal();
    this.clearForm();
  }
}
