import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { LocalStorage } from 'ngx-webstorage';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, finalize, switchMapTo, tap } from 'rxjs/operators';
import { BroadcastChannelService } from './broadcast-channel.service';
import { CentralCacheStorage, CentralCacheStorageOptions } from './central-cache.model';
import { CentralCacheService } from './central-cache.service';
import { MCentralHookHookItem } from './central-hook.model';
import { AppEvent } from './event.service';
import { HookBService } from './hook.bservice';

@Injectable({
  providedIn: 'root',
})
export class CentralHookService extends HookBService<MCentralHookHookItem> {
  @LocalStorage() centralHookRunningCentralizedHooks: string[] = [];

  constructor(
    private _broadcastChannelService: BroadcastChannelService,
    private _centralCacheService: CentralCacheService,
  ) {
    super();

    this._broadcastChannelService.addEventListener('message', (event: AppEvent) => {
      if (event.name === 'CALLHOOK') {
        this.callHookSafeDirectly(event.data.hookId, event.data.hookData, true);
      }
    });
  }

  registerHook(targetHook: MCentralHookHookItem) {
    const hookIndex = this.hooks.findIndex(hook => hook.hookId === targetHook.hookId);
    if (hookIndex > -1) {
      this.hooks.splice(hookIndex, 1);
    }
    this.hooks.push(targetHook);

    if (targetHook && targetHook.runCentralized && targetHook.centralizedCache && !this._centralCacheService.hasCacheStorage(targetHook.hookId)) {
      const centralCacheStorageOptions: CentralCacheStorageOptions = new CentralCacheStorageOptions();
      const centralCacheStorage = new CentralCacheStorage(targetHook.hookId, centralCacheStorageOptions);
      this._centralCacheService.registerCacheStorageIfNotExists(centralCacheStorage);
    }
  }

  callHookSafe(hookId: string, hookData?: any, skipBroadcastHook?: boolean): Observable<any> {
    const hook = this.getHook(hookId);
    if (!hook) {
      return of(null);
    }

    let obs: Observable<any>;

    // variable to define if centralize chache exist or produce result
    let hookCentralizedCacheReturn: boolean = false;

    if (!hook.runCentralized || (hook.runCentralized && !this.centralHookRunningCentralizedHooks.includes(hookId))) {
      obs = of(true);

      // if run centralize
      if (hook.runCentralized) {
        obs = obs.pipe(tap(() => {
          // register running hook
          this.registerRunningHook(hookId);

          window.addEventListener('beforeunload', () => {
            this.unregisterRunningHook(hookId);

            this._broadcastChannelService.postMessage(`HOOK:${hookId}:ERROR`, 'unload');
          });
        }));
      }

      // if hook run centralized and centralized cache is true
      if (hook.runCentralized && hook.centralizedCache) {
        // this variable use to hold cache value
        const cacheData = this._centralCacheService.getCacheItem(hookId);
        // if cache value exist
        if (cacheData) {
          // set obs with cache value
          obs = obs.pipe(switchMapTo(of(cacheData)));

          // hook will be end here if hookCentralizedCacheReturn is true;
          hookCentralizedCacheReturn = true;
        }
      }

      // if hookCentralizedCacheReturn is false
      if (!hookCentralizedCacheReturn) {
        // assign obs with function callHookHandle, callHookHandle will return Observable type
        obs = obs.pipe(switchMapTo(this.callHookHandle(hookId, hookData)));
      }

      // if runCentralized
      if (hook.runCentralized) {
        obs = obs.pipe(
          catchError(hookError => {
            this._broadcastChannelService.postMessage(`HOOK:${hookId}:ERROR`, hookError);

            return throwError(hookError);
          }),
          tap(hookResult => {
            // if centralizedCache and hookCentralizedCacheReturn is false
            if (hook.centralizedCache && !hookCentralizedCacheReturn) {
              // store hook result into central cache storage
              this._centralCacheService.setCacheData(hookId, hookResult);
            }
            this._broadcastChannelService.postMessage(`HOOK:${hookId}:RESULT`, hookResult);
          }),
          finalize(() => {
            // unregister running hook
            this.unregisterRunningHook(hookId);

            this._broadcastChannelService.postMessage(`HOOK:${hookId}:COMPLETE`, null);
          }));
      }

      // skipBroadcastHook = true will disable bubbling if callHook/callHookDirectly was called by BroadcastChannelService inside the CentralHookService.constructor
      if (!hook.runCentralized && !skipBroadcastHook) {
        obs = obs.pipe(tap(() => {
          this._broadcastChannelService.postMessage(`CALLHOOK`, { hookId, hookData });
        }));
      }

    } else if (hook.runCentralized && this.centralHookRunningCentralizedHooks.includes(hookId)) {
      obs = this.observeCentralizedHook(hookId);
    }

    return obs;
  }

  callHookSafeDirectly(hookId: string, hookData?: any, skipBroadcastHook?: boolean): Subscription {
    const subscription = this.callHookSafe(hookId, hookData, skipBroadcastHook).subscribe();
    return subscription;
  }

  observeCentralizedHook(hookId: string) {
    return Observable.create(observer => {
      const handler = (event) => {
        if (event.name === `HOOK:${hookId}:RESULT`) {
          observer.next(event.data);
        }
        if (event.name === `HOOK:${hookId}:ERROR`) {
          observer.error(event.data);
        }
        if (event.name === `HOOK:${hookId}:COMPLETE`) {
          observer.complete();
        }
      };

      this._broadcastChannelService.addEventListener('message', handler);

      return () => {
        this._broadcastChannelService.removeEventListener('message', handler);
      };
    });
  }

  registerRunningHook(hookId: string) {
    this.centralHookRunningCentralizedHooks = this.centralHookRunningCentralizedHooks.concat(hookId);
  }

  unregisterRunningHook(hookId: string) {
    this.centralHookRunningCentralizedHooks = _.pull(this.centralHookRunningCentralizedHooks, hookId);
  }
}
