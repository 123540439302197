import * as PDFMake from 'pdfmake/build/pdfmake';
import * as PDFMakeFonts from 'pdfmake/build/vfs_fonts';
import { Injectable } from '@angular/core';

import { ExportPDFTemplate1Service } from './export-pdf-template1.service';

PDFMake.vfs = PDFMakeFonts.pdfMake.vfs;

@Injectable()
export class ExportPDFService {
  constructor(
    private _exportPDFTemplate1: ExportPDFTemplate1Service,
  ) { }

  async getBlob(params: {
    records: any,
    mapOptions: IExportDataTableMap,
    templateData?: any,
  }) {
    let transformedRecords = null;

    switch (1) {
      case 1:
      default:
        transformedRecords = this._exportPDFTemplate1.transform(params);
        break;
    }

    return await new Promise((resolve, reject) => {
      return PDFMake.createPdf(transformedRecords).getBlob((success, error) => !error ? resolve(success) : reject(error));
    });
  }
}
