import { Component } from '@angular/core';
import { BaseUpdateBComponent } from '../../../libraries/base/update/base-update.bcomponent';
import { LocationRestService } from '../location-rest.service';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-location-update',
  templateUrl: 'location-update.component.html'
})
export class LocationUpdateComponent extends BaseUpdateBComponent {
  constructor(private _locationRest: LocationRestService) {
    super();
    this.componentId = 'LocationUpdateComponent';
    this.routeURL = '/location';
    this.entrySuccessI18n = 'success.location.update';
    this.headerTitle = 'ui.location.update.title';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'load',
      handle: () => {
        return this._locationRest.load(this.comp.routeParams.id).pipe(
          switchMap(doc => {
            doc.password = null;

            return of(doc);
          })
        );
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'save',
      handle: () => {
        const formValue = this.form.value;

        return this._locationRest.update(this.comp.routeParams.id, formValue);
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService.callMultipleHooks([
          'LocationFormComponent:workflowBuildForm',
          'LocationUpdateComponent:workflowLoadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }
}
