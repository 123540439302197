import { NgModule } from '@angular/core';

import { TextareaAutosizeDirective } from './textarea-autosize.directive';

@NgModule({
  declarations: [
    TextareaAutosizeDirective,
  ],
  exports: [
    TextareaAutosizeDirective,
  ],
})
export class TextareaAutosizeModule { }
