import { Component, Input } from '@angular/core';
import { APP_CONST } from '../../../app.const';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { CommonService } from '../../../libraries/libraries.module';
import Swal from 'sweetalert2';
import { BuybackRestService } from '../buyback-rest.service';
import { BuybackListComponent } from '../list/buyback-list.component';

@Component({
  selector: 'app-submission-form',
  templateUrl: 'submission-form.component.html',
  styleUrls: ['../buyback.component.scss', './submission-form.component.scss']
  // styleUrls: ['status-info-modal.component.scss']
})
export class SubmissionForm {
  @Input() userData: any;
  @Input() ticketNumber: any;
  form: FormGroup;
  isOpen: boolean = false;
  step: number = 1;
  today: string;
  newPayload: any;
  uploadEndpoint = APP_CONST.API_FILE;
  isLoading: boolean = false;
  motorcycles: any[] = [];
  motorcycleOptions: { _id: string, name: string }[] = [];
  variants: any[] = [];

  value = localStorage.getItem('ngx-webstorage|currentuser');
  myObject = this.value ? JSON.parse(this.value) : null;

  ngOnInit() {
    this.getMotorcycles();
    this.form = this.fb.group({
      noTiket: [`TI/${this.ticketNumber}`],
      noDokumen: [''],
      type: ['TRADEIN'],
      statusDokumen: ['on progress'],
      statusPengajuanAppraisal: ['on process'],
      statusPengajuanBuyback: [''],
      statusDocumentChecking: [''],
      statusPenerimaanUnit: [''],
      statusPembayaranBuyback: [''],
      statusPembayaranKomisi: [''],
      tanggal: ['', Validators.required],
      dealerId: [this.userData.dealerId],
      namaMd: [this.userData.dealerName, Validators.required],
      areaMd: [this.userData.dealerRegion, Validators.required],
      namaAppraisal: ['', Validators.required],
      noTelpAppraisal: ['', Validators.required],
      namaAdmin: ['', Validators.required],
      noTelpAdmin: ['', Validators.required],
      totalAppraisal: 0,
      dataPemilikUnitMotor: this.fb.group({
          nama: ['', Validators.required],
          noTelp: ['', Validators.required],
          nik: [''],
          alamatSesuaiKtp: ['', Validators.required],
          alamatSesuaiDomisili: [''],
          tanggalPembelianMotor: ['', Validators.required],
          noFaktur: ['']
      }),
      unitLama: this.fb.group({
          modelVolta: ['', Validators.required],
          warna: ['', Validators.required],
          type: [''],
          vin: ['', Validators.required],
          tnkb: ['', Validators.required],
          productVariantId: ['', Validators.required],
          productId: ['']
      }),
      dataDokumen: this.fb.group({
        note: [''],
        stnk: this.fb.group({
          available: true,
          uploaded_file_url: [''],
          uploaded_file_url_1: [''],
          status: ['on review'],
          last_updated: [new Date().toISOString()]
        }, { validators: this.atLeastOneFileValidator('uploaded_file_url', 'uploaded_file_url_1') }),
        bpkb: this.fb.group({
          available: true,
          uploaded_file_url: [''],
          uploaded_file_url_1: [''],
          uploaded_file_url_2: [''],
          uploaded_file_url_3: [''],
          status: ['on review'],
          last_updated: [new Date().toISOString()]
        }, { validators: this.atLeastOneFileValidator('uploaded_file_url', 'uploaded_file_url_1', 'uploaded_file_url_2', 'uploaded_file_url_3') }),
        invoice: this.fb.group({
          available: true,
          uploaded_file_url: [''],
          status: ['on review'],
          last_updated: [new Date().toISOString()]
        }),
        faktur: this.fb.group({
          available: true,
          uploaded_file_url: [''],
          status: ['on review'],
          last_updated: [new Date().toISOString()]
        }),
        ktp: this.fb.group({
          available: true,
          uploaded_file_url: [''],
          status: ['on review'],
          last_updated: [new Date().toISOString()]
        }),
      }),
      pengecekanMotor: this.fb.group({
        assessmentDate: this.today,
        totalDecrease: [''],
        components: this.fb.group({
          1: this.fb.group({
            category: ['Body', Validators.required],
            isConditionGood: ['', Validators.required],
            remark: [''],
            uploadFoto: this.fb.group({
              url1: [''],
              url2: [''],
              url3: [''],
            }, { validator: this.atLeastOneUrlValidator() })
          }),
          2: this.fb.group({
            category: ['Body', Validators.required],
            isConditionGood: ['', Validators.required],
            remark: [''],
            uploadFoto: this.fb.group({
              url1: [''],
              url2: [''],
              url3: [''],
            }, { validator: this.atLeastOneUrlValidator() })
          }),
          3: this.fb.group({
            category: ['Body', Validators.required],
            isConditionGood: ['', Validators.required],
            remark: [''],
            uploadFoto: this.fb.group({
              url1: [''],
              url2: [''],
              url3: [''],
            }, { validator: this.atLeastOneUrlValidator() })
          }),
          4: this.fb.group({
            category: ['Body', Validators.required],
            isConditionGood: ['', Validators.required],
            remark: [''],
            uploadFoto: this.fb.group({
              url1: [''],
              url2: [''],
              url3: [''],
            }, { validator: this.atLeastOneUrlValidator() })
          }),
          5: this.fb.group({
            category: ['Body', Validators.required],
            isConditionGood: ['', Validators.required],
            remark: [''],
            uploadFoto: this.fb.group({
              url1: [''],
              url2: [''],
              url3: [''],
            }, { validator: this.atLeastOneUrlValidator() })
          }),
          6: this.fb.group({
            category: ['Body', Validators.required],
            isConditionGood: ['', Validators.required],
            remark: [''],
            uploadFoto: this.fb.group({
              url1: [''],
              url2: [''],
              url3: [''],
            }, { validator: this.atLeastOneUrlValidator() })
          }),
          7: this.fb.group({
            category: ['Body', Validators.required],
            isConditionGood: ['', Validators.required],
            remark: [''],
            uploadFoto: this.fb.group({
              url1: [''],
              url2: [''],
              url3: [''],
            }, { validator: this.atLeastOneUrlValidator() })
          }),
          8: this.fb.group({
            category: ['Electric', Validators.required],
            isConditionGood: ['', Validators.required],
            remark: [''],
            uploadFoto: this.fb.group({
              url1: [''],
              url2: [''],
              url3: [''],
            }, { validator: this.atLeastOneUrlValidator() })
          }),
          9: this.fb.group({
            category: ['Electric', Validators.required],
            isConditionGood: ['', Validators.required],
            remark: [''],
            uploadFoto: this.fb.group({
              url1: [''],
              url2: [''],
              url3: [''],
            }, { validator: this.atLeastOneUrlValidator() })
          }),
          10: this.fb.group({
            category: ['Supporting Tools', Validators.required],
            isConditionGood: ['', Validators.required],
            remark: [''],
            uploadFoto: this.fb.group({
              url1: [''],
              url2: [''],
              url3: [''],
            }, { validator: this.atLeastOneUrlValidator() })
          }),
          11: this.fb.group({
            category: ['Supporting Tools', Validators.required],
            isConditionGood: ['', Validators.required],
            remark: [''],
            uploadFoto: this.fb.group({
              url1: [''],
              url2: [''],
              url3: [''],
            }, { validator: this.atLeastOneUrlValidator() })
          }),
          12: this.fb.group({
            category: ['Supporting Tools', Validators.required],
            isConditionGood: ['', Validators.required],
            remark: [''],
            uploadFoto: this.fb.group({
              url1: [''],
              url2: [''],
              url3: [''],
            }, { validator: this.atLeastOneUrlValidator() })
          }),
          13: this.fb.group({
            category: ['Supporting Tools', Validators.required],
            isConditionGood: ['', Validators.required],
            remark: [''],
            uploadFoto: this.fb.group({
              url1: [''],
              url2: [''],
              url3: [''],
            }, { validator: this.atLeastOneUrlValidator() })
          }),
          14: this.fb.group({
            category: ['Battery', Validators.required],
            isConditionGood: ['', Validators.required],
            remark: [''],
            uploadFoto: this.fb.group({
              url1: [''],
              url2: [''],
              url3: [''],
            }, { validator: this.atLeastOneUrlValidator() })
          }),
          15: this.fb.group({
            category: ['Battery', Validators.required],
            isConditionGood: ['', Validators.required],
            remark: [''],
            uploadFoto: this.fb.group({
              url1: [''],
              url2: [''],
              url3: [''],
            }, { validator: this.atLeastOneUrlValidator() })
          }),
          16: this.fb.group({
            category: ['Battery', Validators.required],
            isConditionGood: ['', Validators.required],
            remark: [''],
            uploadFoto: this.fb.group({
              url1: [''],
              url2: [''],
              url3: [''],
            }, { validator: this.atLeastOneUrlValidator() })
          }),
        })
      })
    })
    this.onModelVoltaChange();
  }

  // Custom Validator for requiring at least one uploaded file in the given fields
  atLeastOneFileValidator(...fields: string[]) {
    return (group: FormGroup) => {
      const isAnyFieldFilled = fields.some(field => group.get(field)?.value);
      
      return isAnyFieldFilled ? null : { atLeastOneFileRequired: true };
    };
  }  

  atLeastOneUrlValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const url1 = control.get('url1')?.value;
      const url2 = control.get('url2')?.value;
      const url3 = control.get('url3')?.value;
  
      // Check if at least one of the URLs is non-empty
      if (url1 || url2 || url3) {
        return null; // Valid
      }
  
      return { atLeastOneUrlRequired: true }; // Invalid
    };
  }
  onImageBerkasFinishUploaded(image, inputName: string) {
    this.form.patchValue({
      dataDokumen: {
        [inputName]: {
          uploaded_file_url: image?.fileUrl,
        }
      }
    });
  }

  onDeleteBerkasImage(inputName: string) {
    this.form.patchValue({
      dataDokumen: {
        [inputName]: {
          uploaded_file_url: ''
        }
      }
    });
  }

  onImageBerkasFinishUploadedMultiple(image, inputName: string, index: string) {
    this.form.patchValue({
      dataDokumen: {
        [inputName]: {
          [index]: image?.fileUrl
        }
      }
    });
    console.log(inputName, index);
  }

  onDeleteBerkasImageMultiple(inputName: string, index: string) {
    this.form.patchValue({
      dataDokumen: {
        [inputName]: {
          [index]: ''
        }
      }
    });
  }

  // Manipulate Payload
  generatePayload() {
    if (this.form && this.form.value && this.form.value.pengecekanMotor && this.form.value.pengecekanMotor.components) {
      const components = this.form.value.pengecekanMotor.components;
  
      // Transform the components object into the desired array format
      const transformedComponents = Object.keys(components).map(key => ({
        buybackVehicleParameter: parseInt(key, 10),
        ...components[key]
      }));
  
      // Create the new payload with the transformed components and the rest of the form data
      this.newPayload = {
        ...this.form.value, // Spread the rest of the form values
        pengecekanMotor: {
          ...this.form.value.pengecekanMotor, // Spread the pengecekanMotor values
          components: transformedComponents // Replace components with the transformed array
        }
      };
  
    } else {
      console.error('Form or form components are not initialized.');
    }
  }
  

  constructor(
    private fb: FormBuilder,
    private _BuybackRest: BuybackRestService,
    public _buybackListComponent: BuybackListComponent
  ) {
    this.today = new Date().toISOString().split('T')[0]; // Format the date as YYYY-MM-DD
  }

  openModal() {
    this.isOpen = true;
  }

  closeModal() {
    this.isOpen = false;
  }

  prevStep() {
    this.step--;
  }

  isPdfFile(fileUrl: string): boolean {
    return fileUrl.toLowerCase().endsWith('.pdf');
  }
  
  openPdf(fileUrl: string): void {
    window.open(fileUrl, '_blank');
  }

  nextStep() {
    const invalidFields = [];
  
    if (this.step === 1) {
      // Required fields for step 1
      const requiredFields = [
        'namaMd', 
        'namaAppraisal', 
        'noTelpAppraisal', 
        'namaAdmin', 
        'noTelpAdmin', 
        'dataPemilikUnitMotor.nama', 
        'dataPemilikUnitMotor.noTelp', 
        'dataPemilikUnitMotor.alamatSesuaiKtp', 
        'dataPemilikUnitMotor.tanggalPembelianMotor', 
        'unitLama.modelVolta', 
        'unitLama.warna', 
        'unitLama.vin', 
        'unitLama.tnkb', 
        'unitLama.productVariantId'
      ];
  
      // Check each field for validity (Step 1)
      requiredFields.forEach(field => {
        const control = this.form.get(field);
        if (control && control.invalid) {
          invalidFields.push(field);
        }
      });
    }
  
    if (this.step === 2) {
      // Required fields for step 2
      const requiredFields2 = [
        'pengecekanMotor.assessmentDate',
        'pengecekanMotor.totalDecrease'
      ];
  
      // Check top-level fields within pengecekanMotor (Step 2)
      requiredFields2.forEach(field => {
        const control = this.form.get(field);
        if (control && control.invalid) {
          invalidFields.push(field);
        }
      });
  
      // Check each components group
      const components = this.form.get('pengecekanMotor.components') as FormGroup;
      if (components) {
        Object.keys(components.controls).forEach(key => {
          const componentGroup = components.get(key) as FormGroup;
          if (componentGroup && componentGroup.invalid) {
            invalidFields.push(`Component ${key}`);
          }
        });
      }
    }
  
    if (invalidFields.length > 0) {
      // Create an alert message with the names of invalid controls
      const alertMessage = `Periksa kembali input anda`;
    
      // Show SweetAlert2 alert
      Swal.fire({
        icon: 'error',
        title: 'Data Belum Lengkap',
        text: alertMessage,
        confirmButtonText: 'OK'
      });
      return;
    }
  
    this.step++;
  }

  getMotorcycles() {
    this._BuybackRest.getMotorcycles().subscribe(
      (res: any) => {
        this.motorcycles = res || [];
        this.motorcycleOptions = this.motorcycles.map((motorcycle: any) => ({
          _id: motorcycle._id,
          name: motorcycle.name
        }));
      },
      (error) => {
        console.error('Error fetching motorcycles:', error);
      }
    );
  }

  onWarnaChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedId = selectElement.value;
  
    // Find the selected variant based on _id
    const selectedVariant = this.variants.find(variant => variant._id === selectedId);
  
    if (selectedVariant) {
      // Assign the _id to productVariantId
      this.form.get('unitLama.productVariantId')?.setValue(selectedVariant._id);
  
      // Assign the nameColor to warna
      this.form.get('unitLama.warna')?.setValue(selectedVariant.nameColor);
    }
  }

  onModelChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedId = selectElement.value;
  
    // Find the selected variant based on _id
    const selectedModel = this.motorcycleOptions.find(motorcycle => motorcycle._id === selectedId);
  
    if (selectedModel) {
      // Assign the _id to productVariantId
      this.form.get('unitLama.productId')?.setValue(selectedModel._id);
  
      // Assign the nameColor to warna
      this.form.get('unitLama.modelVolta')?.setValue(selectedModel.name);
    }
  }

  onModelVoltaChange() {
    const modelVoltaControl = this.form.get('unitLama.productId');
  
    if (modelVoltaControl) {
      modelVoltaControl.valueChanges.subscribe(selectedId => {
        if (selectedId) {
          this._BuybackRest.getMotorcyclesVariant({}, selectedId).subscribe(
            (res: any) => {
              this.variants = res?.data || [];
            },
            (error) => {
              console.error('Error fetching variants:', error);
            }
          );
        }
      });
    } else {
      console.error('modelVolta control is not found in the form');
    }
  }

  
  onSaveProgress() {
    this.form.patchValue({
      statusDokumen: 'draft',
      statusPengajuanAppraisal: '',
    })
    this.generatePayload();
    // if (!this.form.valid) {
    //   CommonService.markAsDirty(this.form);
    // } else {
      Swal.fire({
        title: 'Apakah anda yakin ingin menyimpan ke draft?',
        text: 'Pastikan semua data terisi dengan benar',
        icon: 'question',
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.saveProgress();
        }
      });
    // }
  }

  async saveProgress() {
    // if (!this.form.valid) {
    //   CommonService.markAsDirty(this.form);
    // } else {
      this.isLoading = true;

      await this._BuybackRest
        .saveDraft(this.newPayload)
        .toPromise()
        .then((response: any) => {
          if (!!response?.invalid) {
            Swal.fire({
              icon: 'warning',
              title: 'Simpan Draft Failed',
              confirmButtonText: 'OK'
            });
          } else {
            Swal.fire(
              'Simpan Draft Success!',
              '',
              'success'
            )

            this._buybackListComponent.callHookDirectly('init');
            this.closeModal();
            this.step = 1;
            setTimeout(() => {
              window.location.reload();
            }, 1000)
          }
        })
        .catch(error => {
          Swal.fire('Error', error.response?.data?.message, 'error');
        });
      this.isLoading = false;
    // }
  }

  onCreateBuyback() {
    if (this.form.invalid) {
     
      // Show SweetAlert2 alert
      Swal.fire({
        icon: 'error',
        title: 'Data Belum Lengkap',
        text: 'Periksa kembali input anda',
        confirmButtonText: 'OK'
      });
      return;
    }
    this.generatePayload();
    if (!this.form.valid) {
      CommonService.markAsDirty(this.form);
    } else {
      Swal.fire({
        title: 'Apakah anda yakin ingin mengajukan buyback?',
        text: 'Pastikan semua data terisi dengan benar',
        icon: 'question',
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.createBuyback();
        }
      });
    }
  }

  async createBuyback() {
    if (!this.form.valid) {
      CommonService.markAsDirty(this.form);
    } else {
      this.isLoading = true;

      await this._BuybackRest
        .create(this.newPayload)
        .toPromise()
        .then((response: any) => {
          if (!!response?.invalid) {
            Swal.fire({
              icon: 'warning',
              title: 'Create Trade In Failed',
              confirmButtonText: 'OK'
            });
          } else {
            Swal.fire(
              'Create Trade In Success!',
              '',
              'success'
            )

            this._buybackListComponent.callHookDirectly('init');
            this.closeModal();
            this.step = 1;
            setTimeout(() => {
              window.location.reload();
            }, 1000)
          }
        })
        .catch(error => {
          Swal.fire('Error', error.response?.data?.message, 'error');
        });
      this.isLoading = false;
    }
  }
}
