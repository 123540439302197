import * as _ from 'lodash';
import * as XLSX from 'xlsx';
import { Injectable } from '@angular/core';

@Injectable()
export class ImportExcelService {
  getBinary(content: ArrayBuffer) {
    let binary = '';
    const bytes = new Uint8Array(content);
    const length = bytes.byteLength;
    for (let i = 0; i < length; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return binary;
  }

  parse(content: ArrayBuffer) {
    const contentBinary = this.getBinary(content);
    const workbook = XLSX.read(contentBinary, { type: 'binary', cellDates: true, cellStyles: true });

    return XLSX.utils.sheet_to_json(_.values(workbook.Sheets)[0], { header: 1 });
  }
}
