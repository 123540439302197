import { Injectable } from '@angular/core';
import { APP_CONST } from '../../app.const';
import { ApiQueryOption } from '../../libraries/http/api-query-option.model';
import { RequestService } from '../../libraries/http/request.service';

@Injectable()
export class NewsCategoriesRestService {
  baseURL = `${APP_CONST.API_MAIN}/admin/news-categories`;

  request = this._request.new(this.baseURL);

  constructor(private _request: RequestService) {}

  create(payload: any) {
    return this.request.post<any>(`/`, payload);
  }

  findAll(queryParams: any = {}) {
    return this.request.get<any>(`/`, { params: queryParams });
  }

  load(categoriesId: string) {
    return this.request.get<any>(`${categoriesId}`);
  }

  delete(newsId: string) {
    return this.request.delete<any>(`${newsId}`);
  }

  update(newsId: string, payload: any) {
    return this.request.put<any>(`${newsId}`, payload);
  }
}
