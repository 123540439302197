import { Injectable } from '@angular/core';
import { APP_CONST } from '../../app.const';
import { RequestService } from '../../libraries/http/request.service';

@Injectable()
export class SPKBatteryRentRestService {
  baseURL = `${APP_CONST.API_MASTER}`;
  request = this._request.new(this.baseURL);

  constructor(private _request: RequestService) {}

  findAll(queryParams: any = {}) {
    return this.request.get<any>(`/admin/spk`, { params: queryParams });
  }

  getPresignedUrl(payload) {
    return this.request.post<any>('/admin/spk/pre-signed-url', payload)
  }

  updateShippingInfo(payload) {
    return this.request.patch('/admin/spk/update-shipping-info', payload);
  }

  createInvoice(payload) {
    return this.request.patch('/admin/spk/create-invoice-battery-rent', payload);
  }

  getMotorcycleUnits(queryParams: any = {}) {
    return this.request.get<any>(`/admin/spk/motorcycle-units`, { params: queryParams });
  }

  getDealerByUser(userId: string) {
    const adminDealer = `${APP_CONST.API_MASTER}/admin/dealers`;
    const adminRequest = this._request.new(adminDealer);
    return adminRequest.get<any>(`/profile/${userId}`);
  }

  createSPK(payload) {
    return this.request.post<any>('/admin/spk/spk-battery-rent', payload);
  }

  delete(id: string) {
    return this.request.delete(`/admin/spk/${id}`);
  }

  rejectSPK(payload) {
    return this.request.put('/admin/spk/reject', payload);
  }

  changeStatusSPK(payload) {
    return this.request.patch('/admin/spk/change-status', payload);
  }

  updateShippingAddress(payload) {
    return this.request.patch('/admin/spk/update-shipping-address', payload);
  }

  updateBatteriesRent(payload) {
    return this.request.patch('/admin/spk/update-batteries-rent', payload);
  }

  getSPKNumber(queryParams: any = {}) {
    return this.request.get<any>(`/admin/spk/spk-number-by-battery-code`, { params: queryParams });
  }
}
