import { Component } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { FormgenFieldControlBComponent } from '../field-control/formgen-field-control.bcomponent';

@Component({
  selector: 'app-formgen-field-date',
  templateUrl: './formgen-field-date.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class FormgenFieldDateComponent extends FormgenFieldControlBComponent {
  yearRange: string = (new Date().getFullYear() - 60) + ':' + (new Date().getFullYear());
}
