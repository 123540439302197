import { TemplateRef } from '@angular/core';
import * as _ from 'lodash';

export enum ESystemMessageType {
  Success = 1,
  Info = 2,
  Warning = 3,
  Error = 4,
}

export enum ESystemMessageShowAs {
  Block = 1,
  Growl = 2,
}

export class MSystemMessage {
  messages: (string | TemplateRef<any>)[] = [];
  options: MSystemMessageOptions = new MSystemMessageOptions;

  constructor(defaultOptions: any = {}) {
    _.forEach(defaultOptions, (value, property) => {
      if (_.has(this, property)) {
        _.set(this, property, value);
      }
    });
  }
}

export class MSystemMessageOptions {
  timer: number = 3500;
  type: ESystemMessageType = ESystemMessageType.Info;
  showAs: ESystemMessageShowAs = ESystemMessageShowAs.Block;

  constructor(defaultOptions: any = {}) {
    _.forEach(defaultOptions, (value, property) => {
      if (_.has(this, property)) {
        _.set(this, property, value);
      }
    });
  }
}
