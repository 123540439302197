import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Subject } from 'rxjs';

import { MSpinnerButton, MSpinnerProgress } from './spinner.model';

@Injectable({
  providedIn: 'root'
})

export class SpinnerService {
  htmlLoadingIndicators: any = {
    1: `
      <div class='spinner-loader spinner-loader1'>
        <div class="ph-item">
          <div class="ph-col-12">
            <div class="ph-picture"></div>
            <div class="ph-row">
              <div class="ph-col-6 big"></div>
              <div class="ph-col-4 empty big"></div>
              <div class="ph-col-2 big"></div>
              <div class="ph-col-4"></div>
              <div class="ph-col-8 empty"></div>
              <div class="ph-col-6"></div>
              <div class="ph-col-6 empty"></div>
              <div class="ph-col-12"></div>
              <div class="ph-col-12"></div>
              <div class="ph-col-12"></div>
              <div class="ph-col-12"></div>
            </div>
          </div>
        </div>
      </div>
    `,
    // no. 2: default page spinner
    2: `
      <div class='spinner-loader spinner-loader2'></div>
    `,
    // no. 3: small spinner
    3: `
      <div class='spinner-loader spinner-loader3'></div>
    `
  };

  showDefault() {
    return this.show({
      element: $('main.main'),
      stretchToMain: true,
    });
  }

  show({
    element,
    blockParentElementDisplay = false,
    blockElementDisplay = false,
    stretchToMain = false,
    loaderType = 0,
    fixedSpinnerPosition = false,
  }: {
    element: any,
    blockParentElementDisplay?: boolean,
    blockElementDisplay?: boolean,
    stretchToMain?: boolean,
    loaderType?: number,
    fixedSpinnerPosition?: boolean,
  }
  ) {
    const elementSource: HTMLDocument = _.isString(element) ? $(element) : element;

    const spinnerTitle$: Subject<string | null> = new Subject;
    const spinnerProgress$: Subject<MSpinnerProgress | null> = new Subject;
    const spinnerButton$: Subject<MSpinnerButton | null> = new Subject;

    let $spinnerElement;

    const $elementSource = $(elementSource);
    let targetLoaderType = loaderType;

    if (!$elementSource.children('.spinner').length) {
      if ($elementSource.parent().css('display') === 'inline') {
        $elementSource.parent().css('display', 'block');
      }
      if ($elementSource.css('position') === 'static') {
        $elementSource.css('position', 'relative');
      }
      if ($elementSource.css('display') === 'inline') {
        $elementSource.css('display', 'block');
      }

      if (!stretchToMain && loaderType === 0) {
        const elementHeight = $elementSource.height();
        const elementWidth = $elementSource.width();

        if (elementHeight >= 200 && elementWidth >= 200) {
          targetLoaderType = 2;
        } else if (elementHeight >= 40 && elementWidth >= 40) {
          targetLoaderType = 3;
        } else {
          targetLoaderType = 1;
          $elementSource.css('min-height', '330px');
        }
      } else if (stretchToMain && loaderType === 0) {
        targetLoaderType = 2;
      }

      $spinnerElement = $(`
        <div class='spinner'>
          ${this.htmlLoadingIndicators[targetLoaderType]}
          <div class='spinner-title'></div>
          <div class='spinner-progress'></div>
          <div class='spinner-button'></div>
        </div>
      `);

      if (fixedSpinnerPosition) {
        $($spinnerElement).css({
          position: 'fixed',
        });
      }

      if (stretchToMain) {
        this.stretchToMain($spinnerElement);
      } else {
        $spinnerElement.find('.spinner').css('height', $elementSource.height());
      }

      $elementSource.append($spinnerElement);

      if (blockElementDisplay) {
        $elementSource.css('display', 'block');
      }

      if (blockParentElementDisplay) {
        $elementSource.css('display', 'block');
      }
    }

    spinnerTitle$.subscribe(newTitle => {
      const $el = this.getSpinnnerElement($spinnerElement);
      if ($el) {
        const $spinnerTitle = $($el).find('.spinner-title');

        if (newTitle) {
          const titleHTML = this.generateHTMLTitle(newTitle);
          $spinnerTitle.html(titleHTML);
        } else {
          $spinnerTitle.html(null);
        }

        this.setSpinnerElementTitleHeight($spinnerElement);
        this.setSpinnerElementProgressHeight($spinnerElement);
        this.setSpinnerElementButtonHeight($spinnerElement);
      }
    });

    spinnerProgress$.subscribe(newProgressPercent => {
      const $el = this.getSpinnnerElement($spinnerElement);
      if ($el) {
        const $spinnerProgress = $($el).find('.spinner-progress');

        if (newProgressPercent) {
          const progressHTML = this.generateHTMLProgress(newProgressPercent);
          $spinnerProgress.html(progressHTML as any);
        } else {
          $spinnerProgress.html(null);
        }

        this.setSpinnerElementProgressHeight($spinnerElement);
        this.setSpinnerElementButtonHeight($spinnerElement);
      }
    });

    spinnerButton$.subscribe(newButton => {
      const $el = this.getSpinnnerElement($spinnerElement);
      if ($el) {
        const $spinnerButton = $($el).find('.spinner-button');

        if (newButton) {
          const buttonHTML = this.generateHTMLButton(newButton);
          $spinnerButton.html(buttonHTML as any);
        } else {
          $spinnerButton.html(null);
        }

        this.setSpinnerElementButtonHeight($spinnerElement);
      }
    });

    const actionSet = {
      setTitle: (newTitle: string) => {
        spinnerTitle$.next(newTitle);
      },
      unsetTitle: () => {
        spinnerTitle$.next(null);
      },
      setProgress: (newProgress: MSpinnerProgress) => {
        spinnerProgress$.next(newProgress);
      },
      unsetProgress: () => {
        spinnerProgress$.next(null);
      },
      setButton: (buttonAction: MSpinnerButton) => {
        spinnerButton$.next(buttonAction);
      },
      unsetButton: () => {
        spinnerButton$.next(null);
      },
      dispose: () => {
        this.dispose(element);

        if (targetLoaderType === 1) {
          $elementSource.css('min-height', 'auto');
        }

        spinnerButton$.unsubscribe();
        spinnerTitle$.unsubscribe();
      }
    };

    return actionSet;
  }

  dispose(element: HTMLDocument) {
    if ($(element).hasClass('.spinner')) {
      $(element).remove();
    } else if ($(element).children('.spinner').length) {
      $(element).children('.spinner').remove();
    }
  }

  stretchToMain(element: HTMLDocument) {
    const mainContainerOffset = $('main.main').offset();
    $(element).css({
      position: 'fixed',
      // top: mainContainerOffset.top,
      // left: mainContainerOffset.left,
    });
  }

  private generateHTMLTitle(newTitle: string) {
    return newTitle;
  }

  private generateHTMLProgress(newProgressPercent: MSpinnerProgress) {
    const color = newProgressPercent.color || 'primary';
    const percentage = newProgressPercent.percentage || 0;
    const $el = $(`
    <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-${color} active" style="width: ${percentage}%"></div>
    </div>`);
    return $el;
  }

  private generateHTMLButton(newButton: MSpinnerButton) {
    const $el = $(`<button class='btn btn-${newButton.buttonActionColor}' ${newButton.buttonActionDisabled ? 'disabled' : ''}>${newButton.buttonActionLabel}</button>`);
    $el.click(newButton.buttonActionClick as any);
    return $el;
  }

  private getSpinnnerElement($targetElement) {
    if ($targetElement) {
      const $el = $($targetElement);
      if ($el.length) {
        return $el;
      }
    }

    return false;
  }

  private getSpinnerElementLoaderHeight($targetElement) {
    const $el = this.getSpinnnerElement($targetElement);

    return parseInt(($el as any).find('.spinner-loader').css('height').replace('px', ''));
  }

  private getSpinnerElementTitleHeight($targetElement) {
    const $el = this.getSpinnnerElement($targetElement);

    return parseInt(($el as any).find('.spinner-title').css('height').replace('px', ''));
  }

  private getSpinnerElementProgressHeight($targetElement) {
    const $el = this.getSpinnnerElement($targetElement);

    return parseInt(($el as any).find('.spinner-progress').css('height').replace('px', ''));
  }

  private setSpinnerElementTitleHeight($targetElement) {
    const loaderHeight = this.getSpinnerElementLoaderHeight($targetElement);
    $targetElement.find('.spinner-title').css('padding-top', loaderHeight + 80);
  }

  private setSpinnerElementProgressHeight($targetElement) {
    let targetPaddingTop = 0;

    targetPaddingTop = this.getSpinnerElementTitleHeight($targetElement);

    if (!targetPaddingTop) {
      targetPaddingTop = this.getSpinnerElementLoaderHeight($targetElement);
    }

    $targetElement.find('.spinner-progress').css('padding-top', targetPaddingTop + 100);
  }

  private setSpinnerElementButtonHeight($targetElement) {
    let targetPaddingTop = 0;

    targetPaddingTop = this.getSpinnerElementTitleHeight($targetElement);

    if (!targetPaddingTop) {
      targetPaddingTop = this.getSpinnerElementProgressHeight($targetElement);
    }

    if (!targetPaddingTop) {
      targetPaddingTop = this.getSpinnerElementLoaderHeight($targetElement);
    }

    $targetElement.find('.spinner-button').css('padding-top', targetPaddingTop + 80);
  }
}
