import { Component } from '@angular/core';
import 'moment/locale/id';
import * as moment from 'moment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { BuybackRestService } from '../buyback-rest.service';
import { ExportDataTableService } from '../../../libraries/export/export-data-table.service';
import Swal from 'sweetalert2';
import { AclService } from '../../../libraries/auth/acl.service';
@Component({
  selector: 'app-buyback-list',
  templateUrl: 'buyback-list.component.html',
  styleUrls: ['../buyback.component.scss']
})
export class BuybackListComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'ui.tradein.entity.no',
      field: 'no'
    },
    {
      i18nLabel: 'ui.tradein.entity.ticketId',
      field: 'noTiket',
      sort: true
    },
    {
      i18nLabel: 'ui.tradein.entity.documentId',
      field: 'noDokumen',
      sort: true
    },
    {
      i18nLabel: 'ui.tradein.entity.status',
      field: 'statusBuyback',
      sort: true
    },
    {
      i18nLabel: 'ui.tradein.entity.dealer',
      field: 'dealer.name'
    },
    {
      i18nLabel: 'ui.tradein.entity.appraisalSubmission',
      field: 'appraisalSubmission'
    },
    {
      i18nLabel: 'ui.tradein.entity.buybackSubmission',
      field: 'buybackSubmission'
    },
    {
      i18nLabel: 'ui.tradein.entity.documentChecking',
      field: 'documentChecking'
    },
    {
      i18nLabel: 'ui.tradein.entity.buybackPayment',
      field: 'buybackPayment'
    },
    {
      i18nLabel: 'ui.tradein.entity.unitAcceptance',
      field: 'unitAcceptance'
    },
    {
      i18nLabel: 'ui.tradein.entity.commission',
      field: 'commissionCol'
    },
    {
      i18nLabel: 'ui.tradein.entity.lastUpdated',
      field: 'updatedAt'
    }
  ];
  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(this.tableColumns);
  detailModal: boolean = false;
  detailAddress: any;
  isLoadingExportExcel: boolean = false;
  selectedBuyback: any = {};
  isDoc;
  isModalChangeStatusOpen: boolean = false;
  userData: any;
  ticketNumber: any;

  constructor(
    private _BuybackRest: BuybackRestService,
    private _export: ExportDataTableService,
    private _AclService: AclService
  ) {
    super();
    this.componentId = 'BuybackListComponent';
    this.headerTitle = 'ui.tradein.list.title';
    this.deleteRecordI18nLabel = 'confirm.any.delete.label';
    this.deleteRecordI18nDescription = 'confirm.any.delete.description';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qOption = event.data.qOption;
        qOption['limit'] = qOption['take'];
        delete qOption['take'];
        delete qOption['skip'];
        const qParams = Object.assign( {type: "TRADEIN" }, event.data.qParams, qOption, this.qParams);
        if (qParams.keyword == '') {
          delete qParams['keyword'];
        }
        return this._BuybackRest.findAll(qParams).pipe(
          switchMap(doc => {
            const dataFormatted = doc?.data?.map(d => {
              d.createdAt = moment(d?.createdAt).format('DD MMMM YYYY HH:mm');
              d.updatedAt = moment(d?.updatedAt).format('DD MMMM YYYY HH:mm');
              return d;
            });
            doc.data = dataFormatted;
            this.userData = doc.dealer;
            this.ticketNumber = doc.ticketNumber;
            // this.total = doc.total;
            return of(doc);
            // return of(this.dummyData);
          })
        );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () => this.page.compHookService?.callMultipleHooks(['BuybackListComponent:workflowLoadData'])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  canAccess(pageAction: string) {
    const userAccess: string[] = this._AclService?.exclusivePermissions;
    return userAccess.find(action => action === pageAction);
  }

  closeDetailDialog() {
    this.detailAddress = null;
    this.detailModal = false;
  }

  onDeleteBuyback(id) {
    Swal.fire({
      title: this.comp._translate.instant('confirm.any.delete.label'),
      text: this.comp._translate.instant('confirm.any.delete.description'),
      icon: 'question',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        this.deleteBuyback(id);
      } else {
        return;
      }
    });
  }

  onChangeStatusBuyback(data) {
    this.selectedBuyback = data;
    this.isModalChangeStatusOpen = true;
  }

  closeChangeStatusModal() {
    this.isModalChangeStatusOpen = false;
    this.selectedBuyback = {};
  }

  actionDeleteCustomer(value) {
    Swal.fire({
      title: this.comp._translate.instant(this.deleteRecordI18nLabel),
      text: this.comp._translate.instant(this.deleteRecordI18nDescription),
      icon: 'question',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        this.deleteBuyback(value);
      } else {
        return;
      }
    });
  }

  async deleteBuyback(id) {
    await this._BuybackRest
      .delete(id)
      .toPromise()
      .then(response => {
        if (response) {
          Swal.fire('Delete Buyback successfully!', '', 'success');
          this.callHookDirectly('init');
        }
      })
      .catch(error => {
        this.comp._globalSystemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }
}
