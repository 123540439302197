import { Component } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { FormgenFieldControlBComponent } from '../field-control/formgen-field-control.bcomponent';

@Component({
  selector: 'app-formgen-field-text',
  templateUrl: './formgen-field-text.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class FormgenFieldTextComponent extends FormgenFieldControlBComponent {
  onChange($event) {
    this.callEvent('onChange', $event);
  }
}
