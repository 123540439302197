import { Component, Input } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BuybackListComponent } from "../list/buyback-list.component";
import { CommonService, ExportDataTableService } from "../../../libraries/libraries.module";
import { BuybackRestService } from "../buyback-rest.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-filter-modal',
  templateUrl: 'filter-modal.component.html',
  styleUrls: ['../buyback.component.scss']
})
export class FilterModalComponent {
  constructor(
    private _BuybackRest: BuybackRestService,
    public _buybackListComponent: BuybackListComponent,
    private _export: ExportDataTableService
  ) {
    this.iniFormExportData();
  }

  iniFormExportData() {
    this.formExportData = new FormGroup({
      statusDokumen: new FormControl(''),
      statusPengajuanAppraisal: new FormControl(''),
      statusPengajuanBuyback: new FormControl(''),
      statusDocumentChecking: new FormControl(''),
      statusPembayaranBuyback: new FormControl(''),
      statusPenerimaanUnit: new FormControl(''),
      statusPembayaranKomisi: new FormControl(''),
    });
  }
  
  isLoading: boolean = false;
  formExportData: FormGroup;

  isFilterModalOpen: boolean = false;
  isLoadingExportExcel: boolean = false;

  openFilterModal() {
    this.isFilterModalOpen = true;
  }

  closeFilterModal() {
    this.isFilterModalOpen = false;
  }

  async exportData() {
    this.isLoadingExportExcel = true;
    try {
      const formValues = this.formExportData.value;

      const result = await this._BuybackRest.findAll({ type: "TRADEIN", ...formValues }).toPromise();

      console.log(result);

      // Preprocess the result data to extract dealer name
      const processedData = result.data.map(record => ({
        ...record,
        dealerName: record.dealer ? record.dealer.name : 'Unknown', // Add a fallback in case dealer is null
        dealerBankName: record.dealer.bankName ? record.dealer.bankName : '',
        dealerBankAccount: record.dealer.bankAccount ? record.dealer.bankAccount : '',
        totalAppraisalBeforeTax: record.totalAppraisal ? record.totalAppraisal / 111 * 1000 : ''
      }));

      const mapOption = {
        noTiket: { header: 'No Tiket' },
        noDokumen: { header: 'No Dokumen' },
        statusDokumen: { header: 'Status'},
        dealerName: { header: 'Dealer' },
        dealerBankName: { header: 'Nama Bank Dealer'},
        dealerBankAccount: { header: 'No Rekening Dealer'},
        statusPengajuanAppraisal: { header: 'Pengajuan Appraisal' },
        statusPengajuanBuyback: { header: 'Pengajuan Tradein' },
        statusDocumentChecking: { header: 'Document Checking' },
        statusPenerimaanUnit: { header: 'Penerimaan Unit' },
        statusPembayaranBuyback: { header: 'Pembayaran Tradein' },
        statusPembayaranKomisi: { header: 'Pembayaran Komisi' },
        totalAppraisalBeforeTax: { header: 'Total Appraisal'},
        totalAppraisal: { header: 'Total Appraisal (Sesudah PPN)'},
        createdAt: { header: 'Tanggal Dibuat' },
        updatedAt: { header: 'Terakhir Diupdate' }
      };
      this._export.export({
        extension: 'xlsx',
        fileName: 'Pelanggan',
        records: processedData,
        mapOptions: mapOption,
        templateData: null,
        pdfOptions: null
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoadingExportExcel = false;
    }
  }

  resetFilter() {
    this.formExportData.reset({
      statusDokumen: '',
      statusPengajuanAppraisal: '',
      statusPengajuanBuyback: '',
      statusDocumentChecking: '',
      statusPembayaranBuyback: '',
      statusPenerimaanUnit: '',
      statusPembayaranKomisi: ''
    });
  }  
}