import * as AccountingJS from 'accounting-js';
import { Injectable } from '@angular/core';

@Injectable()
export class AccountingService {
  ac: any = AccountingJS;

  constructor() {
    this.ac.settings.symbol = '';
    this.ac.settings.decimal = ',';
    this.ac.settings.thousand = '.';
    this.ac.settings.precision = 0;
  }

  public roundMoney(m: number, decimal: number = 2): number {
    const factor = Math.pow(10, decimal);
    return Math.round(m * factor) / factor;
  }
}
