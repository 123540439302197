import { Component, ElementRef, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SPKBatteryRentRestService } from '../spk-battery-rent-rest.service';
import Swal from 'sweetalert2';
import { SPKBatteryRentListComponent } from '../list/spk-battery-rent-list.component';
import { CommonService } from '../../../libraries/libraries.module';
import { AclService } from '../../../libraries/auth/acl.service';

@Component({
  selector: 'app-invoice-modal',
  templateUrl: 'invoice-modal.component.html',
  styleUrls: ['../list/spk-battery-rent-list.component.scss']
})
export class InvoiceModalComponent {
  @Input() spk: any;

  constructor(
    private _SPKBatteryRentRest: SPKBatteryRentRestService,
    public _spkBatteryRentListComponent: SPKBatteryRentListComponent,
    private _AclService: AclService,
    private el: ElementRef
  ) {
    this.initFormInvoice();
  }

  isLoading: boolean = false;
  formCreateInvoice: FormGroup;
  previewBase64Image: any;
  isPdfSelected: boolean = false;
  isInvoiceModalOpen: boolean = false;
  loadingCreateInvoice: boolean = false;
  selectedInvoiceType: string = ''; // Can be invoice or performaInvoice  
  selectedFileName: string;
  fileSize: any;
  isEditInvoice: boolean = false;
  invoiceOpened: any = {};
  selectedSPK: any = {};

  initFormInvoice() {
    this.formCreateInvoice = new FormGroup({
      invNumber: new FormControl('', [Validators.required]),
      file: new FormControl('', [Validators.required]),
    })
  }
  

  async openInvoiceModal(data, type, isEditInvoice = false) {
    if (['expired', 'rejected'].includes(data.status) && isEditInvoice) return;

    const invoice = data[type]; // Invoice Type
    const isPdf = invoice?.file?.mimeType === 'application/pdf';
    this.isPdfSelected = isPdf;

    if (!isEditInvoice && invoice) {
      const payload = { storageFileName: invoice?.file?.storageFileName };
      if (isPdf) {
        await this.renderPresignedUrlImage(payload, '#js-invoicepdfpreview');
      } else {
        await this.renderPresignedUrlImage(payload, '#js-invoicepreview');
      }
    } else if (isEditInvoice && invoice) {
      if (isPdf) {
        this.previewBase64Image = 'pdf';
      } else {
        const payload = { storageFileName: invoice?.file?.storageFileName };
        this.previewBase64Image = invoice?.file?.storageFileName;
        await this.renderPresignedUrlImage(payload, '#js-editInvoicePreview');
      }

      this.formCreateInvoice.patchValue({ file: invoice?.file?.storageFileName, invNumber: invoice.invNumber });
    }

      this.selectedSPK = data;
      this.isEditInvoice = isEditInvoice;
      this.selectedInvoiceType = type;
      this.isInvoiceModalOpen = true;
      this.invoiceOpened = invoice;
  }

  async renderPresignedUrlImage(payload: object, elementId: string) {
    await this._SPKBatteryRentRest.getPresignedUrl(payload).subscribe(data => {
      const invoicePreview = this.el.nativeElement.querySelector(elementId);

      if (invoicePreview) {
        invoicePreview.src = data.url;
        invoicePreview.href = data.url;
      }
    }, error => {
      const invoicePreview = this.el.nativeElement.querySelector(elementId);
      if (invoicePreview) {
        invoicePreview.src = '';
      }
    })
  }

  closeInvoiceModal() {
    this.isInvoiceModalOpen = false;
    this.formCreateInvoice.reset();
    this.resetFileImg();
  }

  canAccess(pageAction: string) {
    const userAccess: string[] = this._AclService?.exclusivePermissions;
    return userAccess.find(action => action === pageAction);
  }

  addFile(event) {
    if (event.target.files.length > 0) {
      // const sizeLimit = 1048576;
      // if (event.target.files[0].size > sizeLimit) {
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'Error',
      //     text: this.comp._translate.instant('error.image.maxLimitProduct')
      //   });
      //   this.resetFileImg();
      //   return false;
      // }
      const reader = new FileReader();
      const file = event.target.files[0];
      const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
      const isPdf = event.target.files[0]?.type === 'application/pdf';


      if (!isPdf) {
        reader.onload = () => {
          this.previewBase64Image = reader.result as string
        };
        reader.readAsDataURL(file);
      } else {
        this.previewBase64Image = 'pdf';
      }

      this.formCreateInvoice.patchValue({ file: file });

      this.selectedFileName = file.name;
      this.fileSize = fileSizeMB + 'MB';
    }
  }

  clearFile(event?: Event) {
    event?.preventDefault();
    this.previewBase64Image = null;
    this.selectedFileName = '';
    this.fileSize = null;
    this.formCreateInvoice.patchValue({ file: null });
  }

  resetFileImg() {
    this.previewBase64Image = null;
    this.selectedFileName = '';
    this.fileSize = null;
  }

  async createInvoice() {
    if (!this.formCreateInvoice.valid) {
      CommonService.markAsDirty(this.formCreateInvoice);
    } else {
      const invoiceType = {
        'invoice': 'createInvoice',
        'invoiceDP': 'createInvoiceDP',
        'invoiceSettlement': 'createInvoiceSettlement',
        'performaInvoice': 'createPerformaInvoice'
      }
      const values = this.formCreateInvoice.value;
      const payload = new FormData();

      payload.append('spkId', this.spk._id);
      payload.append('invNumber', values.invNumber);
      payload.append('file', values.file);
      // Set status to request
      payload.append('status', 'request');

      this.loadingCreateInvoice = true;
      const selectedInvoiceService = invoiceType[this.selectedInvoiceType]
      await this._SPKBatteryRentRest[selectedInvoiceService](payload)
        .toPromise()
        .then(response => {
          if (response) {
            Swal.fire(
              'Update Invoice successfully!',
              '',
              'success'
            )

            this._spkBatteryRentListComponent.onGridChange()
            this.closeInvoiceModal()
          }
        })
        .catch(error => {
          console.log(error)
          Swal.fire(
            'Oops..',
            'Something went wrong!',
            'error'
          )
        });
      this.loadingCreateInvoice = false;
      this.clearFile();
    }
  }
}
