import { Component } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { FormgenFieldControlBComponent } from '../field-control/formgen-field-control.bcomponent';

@Component({
  selector: 'app-formgen-field-chips',
  templateUrl: './formgen-field-chips.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class FormgenFieldChipsComponent extends FormgenFieldControlBComponent {
  onAdd($event) {
    this.callEvent('onAdd', $event);
  }

  onRemove($event) {
    this.callEvent('onRemove', $event);
  }
}
